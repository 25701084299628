import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Typography, useTheme } from '@mui/material';

import { Spinner } from '../../..';
import { HoverOverlay, ImageWrapper, RemoveButton, UploadButton } from './styles';
import { UploadIcon } from '../../../../assets/icons';

const ImageUpload = ({
  previousImageUrl,
  specs,
  acceptedTypes,
  maxSizeInBytes,
  onUpload,
  onDelete,
  uploadText,
  replaceText,
  removeText,
  imageAltText,
  updating,
  uploadError,
  clearUploadError,
  disabled,
}) => {
  const theme = useTheme();
  const [error, setError] = useState('');

  const handleUpload = (event) => {
    setError('');
    clearUploadError('updatingAppLogo');
    const { files: [image] } = event.target;
    if (!acceptedTypes.includes(image.type)) {
      setError('Invalid image type. Please convert or try another image.');
    } else if (image.size > maxSizeInBytes) {
      setError('File size too large. Please resize or try another image.');
    } else {
      onUpload(image, previousImageUrl);
    }
  };

  const handleDelete = (event) => {
    event.preventDefault();
    onDelete(previousImageUrl);
  };

  useEffect(() => {
    const fileInput = document.getElementById('image-upload-input');
    if (fileInput) {
      fileInput.addEventListener('change', handleUpload);
      return () => {
        setError('');
        fileInput.removeEventListener('change', handleUpload);
      };
    }
    return null;
  }, []);

  useEffect(() => {
    if (disabled) {
      setError('');
    }
  }, [disabled]);

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
    return () => {
      clearUploadError('updatingAppLogo');
    };
  }, [uploadError]);

  const renderPreviousImageOrUploadButton = () => {
    if (previousImageUrl) {
      return (
        <ImageWrapper>
          <img src={previousImageUrl} alt={imageAltText} />
          <RemoveButton
            id="delete-button"
            type="button"
            removeText={removeText}
            onClick={handleDelete}
          >
            <img src="/assets/icons/white-circle-close.svg" alt="delete" />
          </RemoveButton>
          <HoverOverlay id="replace-text">{replaceText}</HoverOverlay>
        </ImageWrapper>
      );
    }
    return (
      <React.Fragment>
        <div><UploadIcon /></div>
        <Typography variant="bold">{uploadText}</Typography>
      </React.Fragment>
    );
  };

  return (
    <div>
      <label htmlFor="image-upload-input" id="image-upload-input-label">
        <UploadButton disabled={updating || disabled}>
          {updating ? (
            <Spinner
              spinnerColor={theme.palette.text.disabled}
              size="small"
              additionalClassNames="flex"
            />
          ) : (
            renderPreviousImageOrUploadButton()
          )}
          {specs && (
            <ul>
              {specs.map(spec => (
                <li key={spec}>
                  <Typography variant="caption">{spec}</Typography>
                </li>
              ))}
            </ul>
          )}
          <input
            id="image-upload-input"
            type="file"
            accept={acceptedTypes.join(', ')}
            disabled={disabled}
          />
        </UploadButton>
      </label>

      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  onUpload: func.isRequired,
  onDelete: func.isRequired,
  previousImageUrl: string,
  imageAltText: string,
  specs: arrayOf(string).isRequired,
  acceptedTypes: arrayOf(string).isRequired,
  maxSizeInBytes: number,
  uploadText: string,
  replaceText: string,
  removeText: string,
  disabled: bool.isRequired,
  updating: bool.isRequired,
  uploadError: string,
  clearUploadError: func.isRequired,
};

ImageUpload.defaultProps = {
  previousImageUrl: null,
  imageAltText: 'Your image',
  maxSizeInBytes: 100000,
  uploadText: 'Upload image',
  replaceText: 'Replace image',
  removeText: 'Remove image',
  uploadError: '',
};
