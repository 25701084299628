import { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import moment from 'moment-timezone';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { openChat } from '../../../../services/front/front';
import { onboardingChecklistActive, triggerOnboardingChecklist } from '../../../../services/commandAi/commandAi';
import { organizationRole } from '../Members/utils/roles';
import { featureSetName, gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import useDeepEqual from '../../../../hooks/useDeepEqual';
import launchCalendlyModal from '../../../../services/calendly/calendly';
import api from '../../../../services/api/api';

const CommandAi = ({
  actions,
  history,
  liveOnboardingEvents,
  openSupportModal,
  organization,
  selectedApplication,
  selectedOrganization,
  userContext,
  connectConfig,
  activeOrgMembers,
  invitedOrgMembers,
  isFetchingConnectConfig,
}) => {
  const [connectConfigFetched, setConnectConfigFetched] = useState(false);

  const fetchAndHandleVehicleConnections = async (applicationId) => {
    let numOfVehicleConnectionsForApp;
    try {
      const { data } = await api.fetchConnectedVehicles(applicationId, { mode: 'live' });
      numOfVehicleConnectionsForApp = data.paging.count;
    } catch (error) {
      actions.reportError(error);
    }
    window.CommandBar.setUserProperties({
      // if errored, still update with undefined to reset any previous value
      numOfVehicleConnectionsForApp,
    });
  };

  const shiftCommandBarLauncherPosition = () => {
    const launcher = document.querySelector('.commandbar-launcher');
    if (launcher) {
      const slideInRight = document.querySelector('#slide-in-right');
      if (window.location.pathname.includes('/customizations') && slideInRight) {
        launcher.style.transform = `translateX(${-1 * (slideInRight.offsetWidth - 20)}px`;
      } else {
        launcher.style.transform = 'translateX(0)';
      }
    }
  };

  const fetchAndHandleConnectFunnel = async (applicationId) => {
    let numOfRecentUniqueLiveConnectSessionsForApp;

    const funnelGated = !!gatedFeatureData(FEATURES.CONNECT_FUNNEL, organization.featureSetId);

    if (!funnelGated) {
      try {
        const { data } = await api.fetchConnectFunnel(applicationId);
        const preambleRenderedCount = Number(data.find(event => event.stage === 'preamble_rendered').count);
        numOfRecentUniqueLiveConnectSessionsForApp = preambleRenderedCount;
      } catch (error) {
        actions.reportError(error);
      }
    }
    window.CommandBar.setUserProperties({
      // if gated or errored, still update with undefined to reset any previous value
      numOfRecentUniqueLiveConnectSessionsForApp,
    });
  };

  useEffect(() => {
    shiftCommandBarLauncherPosition();

    const commandBar = document.getElementById('commandbar');
    if (commandBar) {
      commandBar.addEventListener('click', shiftCommandBarLauncherPosition);
    }

    return () => {
      if (commandBar) {
        commandBar.removeEventListener('click', shiftCommandBarLauncherPosition);
      }
    };
  }, [window.location.pathname]);

  useEffect(() => {
    let unsubscribeCmdAiHandler;
    // set up router for Command AI
    const routerFunc = newUrl => history.push(newUrl);
    window.CommandBar.addRouter(routerFunc);
    // hand off chat to Front
    window.CommandBar.addCallback('openChat', openChat);
    window.CommandBar.addCallback('emailSupport', openSupportModal);
    if (['free', 'build'].includes(featureSetName(organization.featureSetId))) {
      window.CommandBar.addCallback('requestDemo', () => {
        launchCalendlyModal({ userContext });
      });
    }
    if (organization.organizationRole === organizationRole.FULL_ACCESS) {
      window.CommandBar.addCallback('launchOnboardingChecklist', () => {
        triggerOnboardingChecklist();
      });
    }
    const cmdAiEventHandler = (event, eventData) => {
      reportToSegment(types.TRACK, eventNames.commandAi, {
        eventName: event,
        eventProperties: {
          selectedApplication,
          selectedOrganization,
          ...eventData,
        },
      });
      if (onboardingChecklistActive()) {
        actions.startOnboardingPolling();
      }
    };
    const unsubPromise = window.CommandBar.addEventSubscriber(cmdAiEventHandler);
    unsubPromise.then((unsub) => {
      unsubscribeCmdAiHandler = unsub;
    });

    return () => {
      if (unsubscribeCmdAiHandler) {
        unsubscribeCmdAiHandler();
      }
      window.CommandBar.removeCallback('launchOnboardingChecklist');
      window.CommandBar.removeCallback('requestDemo');
    };
  }, []);

  useEffect(() => {
    const onboardingData = liveOnboardingEvents.reduce((acc, event) => {
      acc[`ob_${event.eventName}`] = event.eventStatus;
      if (!event.eventStatus) {
        acc.ob_complete = false;
      }
      return acc;
    }, {
      ob_complete: true,
    });
    window.CommandBar.addMetadataBatch(onboardingData);
  }, [useDeepEqual(liveOnboardingEvents)]);

  useEffect(() => {
    actions.fetchConnectConfigRequest();
    fetchAndHandleVehicleConnections(selectedApplication);
    fetchAndHandleConnectFunnel(selectedApplication);

    const metaData = {
      dashboardUserId: userContext.dashboardUserId,
      selectedApplication,
      selectedOrganization,
      hasChatSupport: !gatedFeatureData(FEATURES.CHAT_SUPPORT, organization.featureSetId),
    };
    window.CommandBar.addMetadataBatch(metaData);
    window.CommandBar.setUserProperties({
      selectedApplication,
      selectedOrganization,
      featureSetId: organization.featureSetId,
      organizationRole: organization.organizationRole,
      dashboardRole: organization.dashboardRole,
      planName: featureSetName(organization.featureSetId),
      daysSinceOrgCreation: moment().diff(moment(organization.createdAt), 'days'),
      orgCompletedOnboarding: liveOnboardingEvents.every(step => step.eventStatus === true),
    });
  }, [selectedApplication, selectedOrganization]);

  useEffect(() => {
    actions.fetchActiveOrgMembers(selectedOrganization);
    actions.fetchInvitedOrgMembers(selectedOrganization);
  }, [selectedOrganization]);

  useEffect(() => {
    if (!isFetchingConnectConfig) {
      setConnectConfigFetched(true);
    }
  }, [isFetchingConnectConfig]);

  useEffect(() => {
    // This avoids updating with the default state (null) for connectConfig while it's being fetched
    if (connectConfigFetched) {
      window.CommandBar.setUserProperties({
        newBrandOptin: connectConfig ? Boolean(connectConfig.newBrandOptIn) : false,
        customLogo: connectConfig ? Boolean(connectConfig.logoUrl) : false,
      });
    }
  }, [connectConfigFetched, useDeepEqual(connectConfig)]);

  useEffect(() => {
    // using activeOrgMembers as a proxy for fetching complete since every org as at least 1 member
    if (activeOrgMembers.length > 0) {
      window.CommandBar.setUserProperties({
        numOfTeamMembers: activeOrgMembers.length,
        numOfTeamInvites: invitedOrgMembers.length,
      });
    }
  }, [activeOrgMembers.length, invitedOrgMembers.length]);

  useEffect(() => {
    window.CommandBar.setUserProperties({
      email: userContext.email,
      firstName: userContext.firstName,
      lastName: userContext.lastName,
      createdAt: userContext.createdAt,
      daysSinceUserCreation: moment().diff(userContext.createdAt, 'days'),
      emailVerifiedAt: userContext.emailVerifiedAt,
      lastSuccessfulLogin: userContext.lastSuccessfulLoginAt,
      jobTitle: userContext.jobTitle,
      loginProvider: userContext.loginProvider,
      industry: userContext.industry,
      mfaEnabled: Boolean(userContext.mfaFactorId),
      experiment: userContext.experiment,
    });
  }, [useDeepEqual(userContext)]);

  return false;
};

export default CommandAi;

CommandAi.propTypes = {
  actions: shape({
    startOnboardingPolling: func.isRequired,
    fetchConnectConfigRequest: func.isRequired,
  }).isRequired,
  history: object.isRequired,
  liveOnboardingEvents: arrayOf(object).isRequired,
  openSupportModal: func.isRequired,
  organization: shape({
    organizationRole: string.isRequired,
    featureSetId: string.isRequired,
    dashboardRole: string.isRequired,
  }).isRequired,
  selectedApplication: string.isRequired,
  selectedOrganization: string.isRequired,
  userContext: shape({
    dashboardUserId: string.isRequired,
    email: string.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    commandAIHmac: string.isRequired,
    createdAt: string.isRequired,
    emailVerifiedAt: string.isRequired,
    lastSuccessfulLoginAt: string.isRequired,
    jobTitle: string.isRequired,
    loginProvider: string.isRequired,
    industry: string.isRequired,
    mfaFactorId: string,
  }).isRequired,
  activeOrgMembers: arrayOf(object).isRequired,
  invitedOrgMembers: arrayOf(object).isRequired,
  isFetchingConnectConfig: bool.isRequired,
  connectConfig: shape({
    newBrandOptIn: bool,
    brandedHeader: bool,
  }),
  pagePath: string.isRequired,
};
