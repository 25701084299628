const removeEndpointsFromCompatibilityMatrix = (compatibilityMatrix) => {
  const newMatrix = { ...compatibilityMatrix };

  // Remove endpoints array from compatibility matrix
  delete newMatrix.endpoints;

  // Remove nested endpoint array from brandManagementCapabilities
  Object.keys(newMatrix.brandManagementCapabilities).forEach((key) => {
    delete newMatrix.brandManagementCapabilities[key].endpoint;
  });

  return newMatrix;
};

export default removeEndpointsFromCompatibilityMatrix;
