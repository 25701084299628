const staticText = {
  heading: 'Customize Connect',
  description: 'Preview and configure what users will see when they connect their vehicle to your app. Changes will be reflected immediately once published.',
  toggleButton: 'Select all',
  clearAll: 'Clear all',
  filter: 'Filters',
  backButton: 'Back',
  publishAllChanges: 'Publish all changes',
  successMessage: 'Connect customizations updated!',
  appearance: {
    heading: 'Appearance',
    description: 'Configure the application name, logo, and theme your users will see when connecting their vehicle to your application.',
    inputs: {
      appNameInput: {
        displayName: 'Application name',
      },
      themeInput: {
        displayName: 'Display theme',
        options: [
          {
            label: 'Device settings',
            value: 'system',
          },
          {
            label: 'Light theme',
            value: 'light',
          },
          {
            label: 'Dark theme',
            value: 'dark',
          },
        ],
      },
      logoInput: {
        displayName: 'Logo',
        description: 'Upload your logo to display it at the top of the page. Otherwise the default Connect visual will be displayed.',
        requirements: [
          'Supported formats: PNG, JPG, or WEBP',
          'Max file size: 100kb',
        ],
        uploadLogo: 'Upload logo',
        replaceText: 'Replace logo',
        removeText: 'Remove logo',
        imageAltText: 'Your app logo',
        acceptedTypes: ['image/webp', 'image/png', 'image/jpeg'],
        maxSizeInBytes: 100000,
      },
    },
    featureGated: {
      upgrade: 'Light and dark themes are only available to Custom plans. |+|Upgrade now@@/team/billing|+| to customize your theme.',
      contactOwnersToUpgrade: 'Light and dark themes are only available to Custom plans. Contact your team owner to upgrade your plan.',
    },
  },
  brandManagement: {
    description: 'Configure which vehicle brands your users will be able to select from in Smartcar Connect.',
    displayedBrands: 'displayed brands',
    filterByEngineType: 'Filter by engine type',
    heading: 'Brands',
    selectAll: 'Select all',
    disabledIconText: 'There are no vehicles from this brand that match your filters.',
    displayNewlyAddedBrands: 'Display new brands automatically',
    singleBrandSuggestion: {
      heading: 'Did you know?',
      text: 'When selecting only one brand, consider using |+|Brand Select mode@@https://smartcar.com/docs/connect/advanced-config/flows|+| instead to streamline the connection process for your application users.',
      close: 'Got it',
    },
    featureGated: {
      selectBrands: 'Selecting specific brands is only available to Custom plans.',
      upgrade: '|+|Upgrade now@@/team/billing|+| to access these features.',
      contactOwnersToUpgrade: 'Contact your team owner to upgrade your plan.',
    },
  },
  connectPreview: {
    selectBrand: 'Select your brand',
    backToApplication: 'Back to application',
    brandNotPresent: "I don't see my brand...",
    errors: {
      brandIncompatible: {
        header: 'Brand not supported',
        description: 'Unfortunately, the brands supported by this application are not compatible with the country you are located in. Please contact the application owner to learn more.',
        cta: 'Back to application',
      },
      noSelectedMake: {
        description: 'Please select at least one brand from the list to see a preview of Connect.',
      },
    },
    search: 'Search',
    footerText: '{{applicationName}} uses Smartcar to connect to your vehicle. Logos and brand names are for identification purposes only and do not indicate endorsement of or affiliation with Smartcar or {{applicationName}}',
  },
  countries: {
    heading: 'Countries',
    description: 'Configure which countries users will be able to select when launching Smartcar Connect. Country selection impacts which brands are shown on the brand selector screen (e.g. Renault is only available in Europe).',
    featureGated: {
      upgrade: 'Selecting specific countries is only available to Custom plans. |+|Upgrade now@@/team/billing|+| to customize the countries your users can select from.',
      contactOwnersToUpgrade: 'Selecting specific countries is only available to Custom plans. Contact your team owner to upgrade your plan.',
    },
  },
  errors: {
    connectConfig: 'Sorry, we encountered an error fetching your previous configuration settings. Please try again later.',
    updatingConnectConfig: 'Sorry, we encountered an error updating your customizations. Please try again later.',
    updatingAppLogo: 'Sorry, we encountered an error uploading your app logo. Please try again later.',
    fetchingBrandManagement: 'Sorry, there was a problem loading brand information. Please refresh the page or try again later.',
    fetchingCountries: 'Sorry, there was a problem loading country information. Please refresh the page or try again later.',
  },
};

export default staticText;
