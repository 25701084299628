import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SSO from './SSO';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';
import { actions as authActions } from '../../../../../../services/auth/reducers';

function mapStateToProps(state) {
  return {
    ...state.auth,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    createWorkOsOrganizationRequest,
    fetchWorkOsOrganizationRequest,
    fetchWorkOsConnectionRequest,
  } = authActions;


  return {
    actions: bindActionCreators(
      {
        createWorkOsOrganizationRequest,
        fetchWorkOsOrganizationRequest,
        fetchWorkOsConnectionRequest,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO);
