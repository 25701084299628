import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import AddRedirectUriModal from '../AddRedirectUriModal/AddRedirectUriModal';
import { URI_LIMIT } from '../../../../../Configuration/components/RedirectUriList/components/RedirectUriForm/RedirectUriForm';

import { RedirectUriModalButton, RedirectUriMenuButton, RedirectUriMenu } from './styles';
import staticText from '../../../../../../../../localization/Application/connect-playground';

const RedirectUriSelect = ({
  applicationId,
  selectedRedirectUri,
  appRedirectUris,
  handleChange,
  updateApplication,
  disableAddRedirectUri,
}) => {
  const theme = useTheme();
  const { redirectUri: redirectUriText } = staticText.form.basic;

  const [canAddRedirectUri, setCanAddRedirectUri] = useState(
    appRedirectUris.length < URI_LIMIT && !disableAddRedirectUri,
  );

  // We need to track these separately from the passed redirect URIs, otherwise
  // when adding a redirect URI through the form, the MuiSelect will throw an error
  // saying the new value we're trying to set does not match any of the existing options
  const [renderedRedirectUris, setRenderedRedirectUris] = useState(appRedirectUris);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [redirectUriModalOpen, setRedirectUriModalOpen] = useState(false);
  const [redirectUriListWidth, setRedirectUriListWidth] = useState(0);

  const addRedirectUri = (uri) => {
    const updatedUris = _.concat(appRedirectUris, uri);
    updateApplication(applicationId, { redirectUris: updatedUris });

    if (uri) {
      handleChange({ param: 'redirectUri', value: uri });
      setRenderedRedirectUris([...appRedirectUris, uri]);
    }

    reportToSegment(types.TRACK, eventNames.formSubmitted, { label: '[connect playground] redirect uri form', form_content: { redirectUri: uri } });
  };

  const toggleModal = () => {
    reportToSegment(types.TRACK, eventNames[`modal${redirectUriModalOpen ? 'Closed' : 'Opened'}`], {
      label: 'connect url builder - add redirect uri',
      title: redirectUriText.modal.title,
    });
    setRedirectUriModalOpen(!redirectUriModalOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (event) => {
    handleChange({ param: 'redirectUri', value: event.target.textContent });
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCanAddRedirectUri(appRedirectUris.length < URI_LIMIT && !disableAddRedirectUri);
  }, [appRedirectUris]);

  useEffect(() => {
    const uriSelectElement = document.getElementById('redirect-uri-select-button');
    setRedirectUriListWidth(uriSelectElement.offsetWidth - 2);
  }, []);

  return (
    <React.Fragment>
      {redirectUriModalOpen && (
        <AddRedirectUriModal
          clientId={applicationId}
          redirectUris={appRedirectUris}
          toggleModal={toggleModal}
          onSubmit={addRedirectUri}
        />
      )}
      <RedirectUriMenuButton
        id="redirect-uri-select-button"
        aria-haspopup="listbox"
        aria-controls="redirect-uri-select"
        aria-label={redirectUriText.label}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuClick}
        variant="outlined"
      >
        <Typography variant="bold">{selectedRedirectUri || redirectUriText.placeholder}</Typography>
        <ExpandMoreRounded />
      </RedirectUriMenuButton>
      <RedirectUriMenu
        id="redirect-uri-select"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'redirect-uri-select-button',
          role: 'listbox',
        }}
      >
        <MenuItem value="none" sx={{ display: 'none' }}>
          {redirectUriText.placeholder}
        </MenuItem>
        {renderedRedirectUris.map(uri => (
          <MenuItem
            key={uri}
            value={uri}
            sx={{ width: `${redirectUriListWidth}px` }}
            onClick={handleMenuItemClick}
          >
            <span>{uri}</span>
          </MenuItem>
        ))}
        {canAddRedirectUri && (
          <Box sx={{ borderTop: `1px solid ${theme.palette.primary.main}` }}>
            <RedirectUriModalButton
              id="playground-add-redirect-uri"
              onClick={() => {
                handleMenuClose();
                toggleModal();
              }}
              sx={{ width: `${redirectUriListWidth}px` }}
            >
              <AddRoundedIcon fontSize="small" />
              {redirectUriText.add}
            </RedirectUriModalButton>
          </Box>
        )}
      </RedirectUriMenu>

    </React.Fragment>
  );
};

export default RedirectUriSelect;

RedirectUriSelect.propTypes = {
  selectedRedirectUri: string.isRequired,
  appRedirectUris: arrayOf(string).isRequired,
  handleChange: func.isRequired,
  applicationId: string.isRequired,
  updateApplication: func.isRequired,
  disableAddRedirectUri: bool.isRequired,
};
