/* eslint-disable react/forbid-prop-types */
import React from 'react';

import { object, func } from 'prop-types';

import { Box } from '@mui/material';

import { Form } from './styles';
import { Checkbox } from '../../../../../../../../../../components';

const EngineFilterForm = ({
  selectedBrandManagementFilters,
  updateCustomizations,
  engineTypeLabel,
}) => {
  const onChange = (event) => {
    const { name, checked } = event.target;
    const clonedSelectedBrandManagementFilters = [...selectedBrandManagementFilters];

    if (checked) {
      if (!clonedSelectedBrandManagementFilters.includes(name)) {
        clonedSelectedBrandManagementFilters.push(name);
      }
    } else {
      const index = clonedSelectedBrandManagementFilters.indexOf(name);
      if (index !== -1) {
        clonedSelectedBrandManagementFilters.splice(index, 1);
      }
    }

    updateCustomizations({ brandManagementFilters: clonedSelectedBrandManagementFilters });
  };

  return (
    <Form>
      <Box>
        {
          Object.values(engineTypeLabel).map((boxLabel, index) => {
            const isChecked =
              selectedBrandManagementFilters.includes(Object.keys(engineTypeLabel)[index]);

            return (
              <Checkbox
                key={Object.keys(engineTypeLabel)[index]}
                id={Object.keys(engineTypeLabel)[index]}
                inputName={Object.keys(engineTypeLabel)[index]}
                text={Object.keys(engineTypeLabel)[index]}
                labelText={boxLabel}
                checked={isChecked}
                handleChange={onChange}
                wrapperClassName="flex-vertical-center m-b-lg compact"
              />
            );
          })
        }
      </Box>
    </Form>
  );
};

export default EngineFilterForm;


EngineFilterForm.propTypes = {
  selectedBrandManagementFilters: object.isRequired,
  updateCustomizations: func.isRequired,
  engineTypeLabel: object.isRequired,
};
