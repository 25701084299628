import { keyframes } from '@emotion/react';
import theme from '../theme';

export const loadingShimmerKeyframes = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const loadingShimmerStyles = {
  animation: `${loadingShimmerKeyframes} linear 7s infinite`,
  background: `linear-gradient(to right, #EDEBEA 18%, ${theme.palette.grey[75]} 25%, #EDEBEA 36%)`,
  backgroundSize: '1000px 100%',
};

export default loadingShimmerStyles;
