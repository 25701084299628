import React from 'react';
import { bool, func, string } from 'prop-types';
import { Button, Typography } from '@mui/material';

import InlineLinks from '../InlineLinks';
import Spinner from '../Spinner';

import staticText from '../../localization/Components/sendVerificationEmail';

const SendVerificationEmail = (props) => {
  const {
    email,
    error,
    isSendingVerificationEmail,
    openingText,
    sendVerificationEmailRequest,
  } = props;

  const handleSendVerificationEmail = () => {
    sendVerificationEmailRequest();
  };

  return isSendingVerificationEmail
    ? <Spinner size="small" />
    : (
      <React.Fragment>
        <Typography variant="body1">
          <InlineLinks text={staticText.subtitle(openingText, email)} />
        </Typography>
        {error &&
          <Typography color="error" variant="caption">{error}</Typography>
        }
        <Button
          disabled={isSendingVerificationEmail}
          id="send-verification-button"
          variant="contained"
          color="primary"
          type="button"
          size="large"
          sx={{ mt: 2 }}
          onClick={handleSendVerificationEmail}
        >
          {staticText.btnSubmit}
        </Button>
      </React.Fragment>
    );
};

export default SendVerificationEmail;

SendVerificationEmail.propTypes = {
  email: string.isRequired,
  error: string.isRequired,
  isSendingVerificationEmail: bool.isRequired,
  openingText: string.isRequired,
  sendVerificationEmailRequest: func.isRequired,
};
