import React, { useState } from 'react';
import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import _ from 'lodash';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { Checkbox, Feedback } from '../../../../../../../components';
import formFields from '../fields';

const { filterOutVehicleHealthEvents, isVehicleHealthEvent } = formFields;

const Events = ({
  inputValues, children, inputName, handleChange, disabled,
}) => {
  const isVehicleHealth = isVehicleHealthEvent(inputValues[0]);
  const [radioValue, setRadioValue] = useState(isVehicleHealth ? inputValues[0] : null);
  const handleEvChargingChange = (event) => {
    const {
      target: {
        name, checked,
      },
    } = event;

    let values;
    if (!inputValues.length) {
      values = [];
    } else {
      values = [...inputValues];
    }

    values = filterOutVehicleHealthEvents(values);

    if (checked) {
      values.push(name);
      handleChange({ key: inputName, value: values });
    } else {
      _.pull(values, name);
      handleChange({ key: inputName, value: values });
    }
    setRadioValue(null);
  };

  const handleVehicleHealthChange = (event) => {
    const {
      target: {
        value,
      },
    } = event;
    setRadioValue(value);
    handleChange({ key: inputName, value: [value] });
  };

  const fields = [children.evCharging, children.vehicleHealth];

  return (
    <div>
      {fields.map(({
        events, subheading, description, type, name, info,
      }) => (
        <React.Fragment key={subheading}>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Typography sx={{ mb: 1.5, fontWeight: 600, fontSize: '0.875rem' }}>{subheading}</Typography>
          {description && <Box mt={1} mb={1}><span className="label-subtext">{description}</span></Box>}
          {type === 'checkbox' && Object.values(events).map(({ text, name: optionName }) => (
            <Box key={text} mb={1.5} display="flex" alignItems="center" sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}>
              <Checkbox
                wrapperClassName="flex-vertical-center m-r-med"
                inputName={optionName}
                key={text}
                text={text}
                checked={inputValues.includes(optionName)}
                handleChange={handleEvChargingChange}
                // only evCharging types can be updated and only within this subcategory
                disabled={disabled && isVehicleHealth}
              />
            </Box>
          ))}
          {type === 'radio' && (
            <React.Fragment>
              <RadioGroup
                onChange={handleVehicleHealthChange}
                name={name}
                value={radioValue}
                sx={{
                  '& .MuiTypography-root': { fontSize: '0.875rem' },
                  '& .MuiRadio-root': {
                    padding: 0.75,
                    color: '#B6B6B6',
                  },
                }}
              >
                {Object.values(events).map(({ text: optionText, name: optionName }) => (
                  <FormControlLabel
                    key={optionName}
                    value={optionName}
                    control={<Radio disabled={disabled} />}
                    label={optionText}
                  />
                ))}
              </RadioGroup>
              {isVehicleHealth && info && <Feedback additionalClassNames="small-font" type="info" message={info} />}
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Events;

Events.propTypes = {
  children: shape({
    evCharging: shape({
      events: objectOf({
        text: string,
        permission: string,
        name: string,
      }),
      subheading: string,
      description: string,
      type: string,
    }),
    vehicleHealth: shape({
      events: objectOf({
        text: string,
        permission: string,
        name: string,
      }),
      subheading: string,
      description: string,
      type: string,
    }),
  }).isRequired,
  inputName: string.isRequired,
  handleChange: func.isRequired,
  inputValues: arrayOf(string),
  disabled: bool,
};

Events.defaultProps = {
  inputValues: [],
  disabled: false,
};
