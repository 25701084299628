import React, { useState } from 'react';
import { arrayOf, func, object, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Checkbox from '../Checkbox';
import Modal from '../Modal';
import { gatedFeatureData } from '../../services/featureGate';
import FEATURES from '../../services/featureGate/features';
import { Button } from './styles';
import staticText from '../../localization/Application/application';

const SelectAppsModal = ({
  actions: {
    updateLockedApplications,
  },
  orgApps,
  featureSetId,
  history,
  applicationId,
  lockedApplications,
}) => {
  // Checklist functionality
  const [activeApps, setActiveApps] = useState([]);
  const [checklistError, setChecklistError] = useState('');
  const orgAppIds = Object.keys(orgApps);
  const appLimit = gatedFeatureData(FEATURES.APPLICATIONS, featureSetId);
  const isUpgrading = orgAppIds.length - lockedApplications.length < appLimit;
  const isActiveAppAmountValid = activeApps.length === appLimit;

  if (orgAppIds.length <= appLimit) {
    updateLockedApplications([]);
    return null;
  }

  const segmentProperties = {
    label: 'locked app selection modal',
    title: 'select your app',
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // If the number of checked applications is valid, we update locked applications
    if (isActiveAppAmountValid) {
      const inactiveApplications = orgAppIds.filter(app => !activeApps.includes(app));
      // Update locked applications in redis
      try {
        updateLockedApplications(inactiveApplications);
        reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
        // Redirect to a valid application if the user's current application is locked
        const currentAppLocked = !activeApps.includes(applicationId);
        if (currentAppLocked) {
          const reverseApplicationIds = orgAppIds.reverse();
          const newestApplicationId = reverseApplicationIds.find(id => activeApps.includes(id));
          history.push(`/apps/${newestApplicationId}`);
        }
      } catch (err) {
        setChecklistError(err.message);
      }
    } else {
      // Show error to check appropriate amount of applications
      setChecklistError(staticText.selectAppsModal.checklistError);
    }
  };

  // Update state based on applications selected
  const handleResponseChange = (event) => {
    // the checkbox name is the application id
    const {
      target: { name, checked },
    } = event;

    let values;
    if (!activeApps.length) {
      values = [];
    } else {
      values = [...activeApps];
    }

    if (checked && values.length === appLimit) {
      values.push(name);
      _.pullAt(values, 0);
    } else if (checked) {
      values.push(name);
    } else {
      _.pull(values, name);
    }
    setActiveApps([...values]);
  };

  const content = (
    <div className="m-b-xl">
      <div className="m-b-lg">
        {isUpgrading ? staticText.selectAppsModal.selectAppsUpgraded :
          staticText.selectAppsModal.selectAppsDowngraded(appLimit)}
      </div>
      <form onSubmit={e => handleSubmit(e)} >
        {orgAppIds.map((appId) => {
          const application = orgApps[appId];
          return (
            <div key={application.id} className="flex-vertical-center m-b-lg">
              <Checkbox
                wrapperClassName="flex-vertical-center m-r-med"
                inputName={application.id}
                text={application.name}
                handleChange={handleResponseChange}
                checked={activeApps.includes(application.id)}
              />
            </div>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          id="select-apps-submit"
        >
          {staticText.selectAppsModal.save}
        </Button>
      </form>
      { checklistError && <div className="error-message" >{checklistError}</div> }
    </div>
  );

  return (
    <Modal
      title={staticText.selectAppsModal.title}
      content={content}
      toggleModal={() => null}
      hideClose
    />
  );
};

SelectAppsModal.propTypes = {
  orgApps: object.isRequired,
  featureSetId: string.isRequired,
  history: object.isRequired,
  actions: shape({
    updateLockedApplications: func.isRequired,
  }).isRequired,
  lockedApplications: arrayOf(string).isRequired,
  applicationId: string.isRequired,
};


export default withRouter(SelectAppsModal);
