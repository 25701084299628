import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Box } from '@mui/material';

import Modal from '../Modal';
import { Button } from './styles';
import InlineLinks from '../InlineLinks';
import staticText from '../../localization/Application/application';
import { currentTermsOfServiceVersion } from '../../scenes/Application/utils';
import spinningButtonStyles from '../../global-styles/animations/spinningButton';

const TermsOfServiceModal = ({
  inProgress,
  updateUserFormErrors,
  actions: {
    updateUserRequest,
  },
}) => {
  const [formError, setFormError] = useState(null);
  const segmentProperties = {
    label: staticText.termsOfServiceModal.label,
    title: staticText.termsOfServiceModal.title,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(null);
    updateUserRequest({ termsOfServiceVersion: currentTermsOfServiceVersion });
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
  };

  useEffect(() => {
    if (updateUserFormErrors.length) {
      setFormError(updateUserFormErrors);
    }
  }, [inProgress, updateUserFormErrors]);

  const content = (
    <Box mb={2}>
      <form onSubmit={e => handleSubmit(e)}>
        <Box mb={2}>
          <InlineLinks text={staticText.termsOfServiceModal.description} />
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          id="terms-of-service-modal-submit"
          size="large"
          sx={inProgress && spinningButtonStyles}
        >
          {staticText.termsOfServiceModal.submit}
        </Button>
      </form>
      {formError && <div className="error-message">{formError}</div>}
    </Box>
  );

  return (
    <Modal
      title={staticText.termsOfServiceModal.title}
      content={content}
      toggleModal={() => null}
      hideClose
    />
  );
};

TermsOfServiceModal.propTypes = {
  updateUserFormErrors: oneOfType([arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ), string,
  ]),
  inProgress: bool.isRequired,
  actions: shape({
    acceptTermsOfService: func.isRequired,
  }).isRequired,
};

TermsOfServiceModal.defaultProps = {
  updateUserFormErrors: [],
};

export default TermsOfServiceModal;
