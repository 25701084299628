import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import ApplicationHeader from '../ApplicationHeader';
import UpdateOrg from './components/UpdateOrg';
import { Section } from '../../styles';

const staticText = {
  heading: 'Team settings',
};

const OrgSettings = () => {
  const theme = useTheme();

  useEffect(() => {
    reportToSegment(types.PAGE, 'Team Settings');
  }, []);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '750px' }}>
      <ApplicationHeader heading={staticText.heading} />
      <Section>
        <UpdateOrg />
      </Section>
    </Box>
  );
};

export default OrgSettings;
