import { errors } from '../../../../../localization/Application/Logs/logs';
import dateUtils from '../../../../../utils/dateUtils';

/* istanbul ignore next */
const validateFilterValues = (filterValues, logRestrictions) => {
  const validated = {};

  try {
    if (filterValues.start === null || filterValues.end === null) {
      throw new Error('Please select a start and end date');
    }

    const { minDate, maxDate } = logRestrictions;
    if (dateUtils.isBefore(filterValues.start, minDate)) {
      throw new Error(errors.outOfRange('start'));
    }

    if (dateUtils.isAfter(filterValues.end, maxDate)) {
      throw new Error(errors.outOfRange('end'));
    }

    return validated;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export default validateFilterValues;
