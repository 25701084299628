import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { reportToSegment, types } from '@smartcar/morse';
import { Box, useTheme } from '@mui/material';

import { Toast } from '../../../../components';
import ApplicationHeader from '../ApplicationHeader';
import {
  InviteUserModal,
  MembersTable,
  CardSet,
  EditUserModal,
  DeleteUserModal,
  FiltersBar,
} from './components';
import staticText, { modalType } from '../../../../localization/Application/Members/members';
import formatMembers from './utils/formatMembers';
import { status } from '../../../../services/organizations/reducers';
import { applyFilters } from './components/FiltersBar/utils';

const Members = ({
  actions: {
    fetchActiveOrgMembers,
    fetchInvitedOrgMembers,
    fetchDashboardPermissionRoles,
    sendOrgInvites,
    deleteOrgInvite,
    updateOrgMember,
    deleteOrgMember,
    updateDeleteOrgInviteStatus,
    updateDeleteOrgMemberStatus,
    updateUpdateOrgMemberStatus,
    fetchWorkOsConnectionRequest,
  },
  deleteOrgInviteStatus,
  deleteOrgInviteError,
  deleteOrgMemberStatus,
  deleteOrgMemberError,
  updateOrgMemberStatus,
  updateOrgMemberError,
  fetchRolesError,
  isFetching,
  isFetchingRoles,
  members,
  userContext,
  organization,
  dashboardRoles,
  isWorkOsConnectionActive,
}) => {
  const theme = useTheme();

  const orgApps = organization.allApplications;
  const rolesMap = _.keyBy(dashboardRoles, role => role.name);
  const appsMap = _.keyBy(orgApps, app => app.id);

  const defaultFilterValues = {
    roles: _.mapValues(rolesMap, () => true),
    access: _.mapValues(appsMap, () => true),
    search: '',
  };

  const [currentModal, setCurrentModal] = useState(null);
  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  /* istanbul ignore next */
  const getLabel = (filterKey, option) => {
    if (filterKey === 'roles') {
      return rolesMap[option].displayName;
    } else if (filterKey === 'access') {
      return appsMap[option].name;
    }
    return '';
  };

  const formattedMembers = formatMembers(members, orgApps);
  const filteredMembers = applyFilters(formattedMembers, filterValues, defaultFilterValues);

  const closeModal = () => setCurrentModal(null);
  const openModal = (type, { user } = {}) => {
    if (type === modalType.sendInvites) {
      setCurrentModal(
        <InviteUserModal
          activeMembers={members.activeMembers}
          apps={orgApps}
          dashboardPermissionRoles={dashboardRoles}
          fetchRolesError={fetchRolesError}
          sendOrgInvites={sendOrgInvites}
          openModal={openModal}
          closeModal={closeModal}
          featureSetId={organization.featureSetId}
          organizationRole={organization.organizationRole}
        />,
      );
    } else if (type === modalType.editUser) {
      setCurrentModal(
        <EditUserModal
          apps={orgApps}
          dashboardPermissionRoles={dashboardRoles}
          fetchRolesError={fetchRolesError}
          developerEmailVerifiedAt={userContext.emailVerifiedAt}
          updateOrgMember={updateOrgMember}
          closeModal={closeModal}
          user={user}
          featureSetId={organization.featureSetId}
          organizationRole={organization.organizationRole}
        />,
      );
    } else if (type === modalType.deleteUser) {
      setCurrentModal(
        <DeleteUserModal
          developerEmailVerifiedAt={userContext.emailVerifiedAt}
          deleteOrgMember={deleteOrgMember}
          user={user}
          closeModal={closeModal}
        />,
      );
    }
  };

  useEffect(() => {
    // updates default filter values when done fetching roles
    setFilterValues(defaultFilterValues);
  }, [dashboardRoles]);

  useEffect(() => {
    fetchActiveOrgMembers(organization.id);
    fetchInvitedOrgMembers(organization.id);
    fetchDashboardPermissionRoles();
    // fetch sso entconnection to determine if invite can be s
    fetchWorkOsConnectionRequest(organization.id);
    reportToSegment(types.PAGE, 'Members');
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('launch-invite') === 'true' && dashboardRoles.length > 0) {
      openModal(modalType.sendInvites);
      urlParams.delete('launch-invite');
      window.history.replaceState({}, '', `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`);
    }
  }, [dashboardRoles.length]);

  useEffect(() => {
    if (deleteOrgInviteStatus === status.SUCCESS) {
      Toast(staticText.deleteOrgInviteSuccessToast, 'success');
      updateDeleteOrgInviteStatus(status.INACTIVE);
      fetchInvitedOrgMembers(organization.id);
    } else if (deleteOrgInviteStatus === status.FAILURE) {
      Toast(deleteOrgInviteError, 'warn');
      updateDeleteOrgInviteStatus(status.INACTIVE);
    }

    if (deleteOrgMemberStatus === status.SUCCESS) {
      Toast(staticText.deleteOrgMemberSuccessToast, 'success');
      updateDeleteOrgMemberStatus(status.INACTIVE);
      fetchActiveOrgMembers(organization.id);
    } else if (deleteOrgMemberStatus === status.FAILURE) {
      Toast(deleteOrgMemberError, 'warn');
      updateDeleteOrgMemberStatus(status.INACTIVE);
    }

    if (updateOrgMemberStatus === status.SUCCESS) {
      Toast(staticText.updateOrgMemberSuccessToast, 'success');
      updateUpdateOrgMemberStatus(status.INACTIVE);
      fetchActiveOrgMembers(organization.id);
    } else if (updateOrgMemberStatus === status.FAILURE) {
      Toast(updateOrgMemberError, 'warn');
      updateUpdateOrgMemberStatus(status.INACTIVE);
    }
  }, [deleteOrgInviteStatus, deleteOrgMemberStatus, updateOrgMemberStatus]);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '950px' }}>
      <ApplicationHeader
        heading={staticText.heading}
        dashboardPermission="write_team"
        cta={{
          variant: 'contained',
          text: staticText.addMembers,
          onclick: () => openModal(modalType.sendInvites),
          disabled: isFetchingRoles || isWorkOsConnectionActive,
          plusIcon: true,
          disabledTooltip: isWorkOsConnectionActive && staticText.ssoConnectionDisabledTooltip,
        }}
      />
      <CardSet activeMembers={members.activeMembers} />
      <Box display="flex" justifyContent="flex-end">
        <FiltersBar
          getLabel={getLabel}
          defaultFilterValues={defaultFilterValues}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </Box>
      <MembersTable
        sendOrgInvites={sendOrgInvites}
        deleteOrgInvite={deleteOrgInvite}
        isLoading={isFetching}
        rows={filteredMembers}
        openModal={openModal}
        filterValues={filterValues}
        isWorkOsConnectionActive={isWorkOsConnectionActive}
      />
      {currentModal}
    </Box>
  );
};

export default Members;

Members.propTypes = {
  actions: shape({
    fetchActiveOrgMembers: func.isRequired,
    fetchInvitedOrgMembers: func.isRequired,
    fetchDashboardPermissionRoles: func.isRequired,
    sendOrgInvites: func.isRequired,
    deleteOrgInvite: func.isRequired,
    updateOrgMember: func.isRequired,
    deleteOrgMember: func.isRequired,
    updateDeleteOrgInviteStatus: func.isRequired,
    updateDeleteOrgMemberStatus: func.isRequired,
    updateUpdateOrgMemberStatus: func.isRequired,
    fetchWorkOsConnectionRequest: func.isRequired,
  }).isRequired,
  deleteOrgInviteStatus: string.isRequired,
  deleteOrgInviteError: string.isRequired,
  deleteOrgMemberStatus: string.isRequired,
  deleteOrgMemberError: string.isRequired,
  updateOrgMemberStatus: string.isRequired,
  updateOrgMemberError: string.isRequired,
  fetchRolesError: string.isRequired,
  isFetching: bool.isRequired,
  isFetchingRoles: bool.isRequired,
  members: shape({
    activeMembers: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        email: string.isRequired,
      }),
    ),
    invitedMembers: arrayOf(
      shape({
        id: string.isRequired,
        email: string.isRequired,
      }),
    ),
  }).isRequired,
  userContext: shape({
    emailVerifiedAt: string.isRequired,
  }).isRequired,
  organization: shape({
    id: string.isRequired,
    featureSetId: string.isRequired,
    allApplications: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })).isRequired,
  }).isRequired,
  dashboardRoles: arrayOf({
    name: string.isRequired,
    displayName: string.isRequired,
    description: string.isRequired,
  }).isRequired,
  isWorkOsConnectionActive: bool.isRequired,
};

