import React from 'react';
import { bool, func } from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';

import { HoverText, NavItem } from '../../styles';
import { SearchButton, ShortcutKeys } from './styles';
import { capitalizeFirstLetter } from '../../../../utils';

import { generalNav } from '../../../../../../localization/Application/nav';

const SearchBar = ({ openSearch, menuExpanded }) => {
  const { search } = generalNav;

  const isMac = () => {
    // TODO: find alternative to navigator.platform (deprecated)
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  };

  return (
    <React.Fragment>
      <NavItem>
        <SearchButton id="nav-search" onClick={openSearch}>
          <Box display="flex" alignItems="center">
            <img src={search.iconSrc} alt={search.text} />
            {menuExpanded && <Typography variant="body2">{capitalizeFirstLetter(search.text)}</Typography>}
            {!menuExpanded && (
              <HoverText className="hover-text">{capitalizeFirstLetter(search.text)}</HoverText>
            )}
          </Box>
          {menuExpanded && (
            <ShortcutKeys>
              <div className="shortcut-key">
                {isMac() ? (
                  <img src="/assets/icons/nav/command_key.svg" alt="Command" />
                ) : (
                  <span>Ctrl</span>
                )}
              </div>
              <div className="shortcut-key"><span>K</span></div>
            </ShortcutKeys>
          )}
        </SearchButton>
      </NavItem>
      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
    </React.Fragment>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  openSearch: func.isRequired,
  menuExpanded: bool.isRequired,
};
