/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect } from 'react';
import { string, number, func, shape, object, any, bool } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Box, Button, Typography } from '@mui/material';
import { filtersConfig, staticText } from '../../../../../../../../localization/Application/Logs/filtersBar';
import { DateRangePicker, MenuItemWrapper, FiltersForm, Toast, FeatureGateBanner } from '../../../../../../../../components';
import { DateRangeExportModal, ExportButton } from './components';
import { Form, ResetButton } from './styles';
import PermissionGate from '../../../../../../../../components/PermissionGate';
import alphabetizeMakes from '../../../../utils/alphabetizeMakes';
import getFilterStatusAndTitle from '../../../../utils/getFilterStatusAndTitle';

const FiltersBar = ({
  handleResetAll,
  filterValues,
  handleApplyFilters,
  logRestrictions,
  userContext,
  applicationId,
  submitLogExportRequest,
  disableExport,
  canWriteBilling,
}) => {
  const [newFormState, setNewFormState] = useState(filterValues);
  const [modalOpen, setModalOpen] = useState();

  const {
    logDays,
    minDate,
    maxDate,
    hasExportAccess,
    isMaxLogDays,
    maxExportLogDays,
  } = logRestrictions;

  const dateRange = [newFormState.start, newFormState.end];

  const getHandleChange = filterKey => (newValue) => {
    if (filterKey === 'dateRange') {
      setNewFormState({
        ...newFormState,
        start: newValue[0] ? newValue[0] : null,
        end: newValue[1] ? newValue[1] : null,
      });
    } else {
      setNewFormState({
        ...newFormState,
        [filterKey]: newValue,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // apply filter resets page to 0 so we need to reset offset to 0
    handleApplyFilters({ ...newFormState, offset: 0 });

    reportToSegment(
      types.TRACK,
      eventNames.formSubmitted,
      {
        label: '[request logs] filter values',
        form_content: newFormState,
      },
    );
  };

  const handleResetClick = () => {
    setNewFormState(filterValues);
    handleResetAll();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleExportRequestSubmit = () => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'submit',
      text: staticText.export,
    });
    submitLogExportRequest({
      applicationId,
      startDate: moment(dateRange[0]).format('YYYY-MM-DD'),
      endDate: moment(dateRange[1]).format('YYYY-MM-DD'),
      name: `${userContext.firstName} ${userContext.lastName}`,
      email: userContext.email,
      dashboardUserId: userContext.dashboardUserId,
    });
    toggleModal();
    Toast(staticText.exportModal.toast, 'success');
  };
  const canExportMore = hasExportAccess && maxExportLogDays > logDays;
  const logAllowance = staticText.logDaysAllowance(logDays);
  const upgradeMessage = `${logAllowance} ${staticText.featureGated.upgrade}`;
  const ownerUpgradeText = `${logAllowance} ${staticText.featureGated.contactOwnersToUpgrade}`;

  const logRangeMessaging = isMaxLogDays
    ?
    (
      <React.Fragment>
        <Typography fontSize=".875rem">
          {staticText.maxLogDays(logDays)}
        </Typography>
        {canExportMore &&
          <Typography fontSize=".875rem">
            {`For logs greater than ${logDays} days, `}
            <Button
              sx={{ padding: 0, fontSize: '.875rem' }}
              onClick={toggleModal}
            >submit a support request
            </Button>.
          </Typography>
        }
      </React.Fragment>
    )
    :
    (
      <FeatureGateBanner
        canWriteBilling={canWriteBilling}
        upgradeText={upgradeMessage}
        ownerUpgradeText={ownerUpgradeText}
      />
    );

  useEffect(() => {
    // because filterValues can be changed elsewhere (ie. Reset All Button)
    setNewFormState(filterValues);
  }, [filterValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <Box display="flex" gap={1}>
        <DateRangePicker
          label={staticText.conDate}
          onAccept={getHandleChange('dateRange')}
          value={dateRange}
          minDate={minDate}
          maxDate={maxDate}
          customActionBar={logRangeMessaging}
        />
        {modalOpen && (
          <DateRangeExportModal
            toggleModal={toggleModal}
            handleSubmit={handleExportRequestSubmit}
            email={userContext.email}
            applicationId={applicationId}
            logRestrictions={logRestrictions}
          />
        )}
        {
          filtersConfig.map(({
            filterKey, icon, formTitle, id, minWidth, columns,
          }) => {
            const {
              isFilterActive,
              menuItemWrapperTitle,
            } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

            return (
              <MenuItemWrapper
                title={menuItemWrapperTitle}
                isFilterActive={isFilterActive}
                key={filterKey}
                Icon={icon}
                id={id}
                minWidth={minWidth}
                filterKey={filterKey}
                popperPlacement="bottom-start"
              >
                <FiltersForm
                  checkboxOptions={filterKey !== 'makes' ? newFormState[filterKey]
                    : alphabetizeMakes(newFormState[filterKey])}
                  updateCheckboxes={getHandleChange(filterKey)}
                  title={formTitle}
                  columns={columns}
                />
              </MenuItemWrapper>
            );
          })
        }
        <Button
          variant="contained"
          type="submit"
          disabled={_.isEqual(filterValues, newFormState)}
          sx={{ height: '42px' }}
        >
          {staticText.apply}
        </Button>
        <ResetButton
          id="reset-all-button"
          onClick={handleResetClick}
        >
          {staticText.resetAll}
        </ResetButton>
      </Box>
      <PermissionGate dashboardPermission="write_overview">
        <ExportButton logRestrictions={logRestrictions} disabled={disableExport} />
      </PermissionGate>
    </Form>
  );
};


export default FiltersBar;

FiltersBar.propTypes = {
  filterValues: shape({
    makes: object.isRequired,
    statusCodes: object.isRequired,
    start: any.isRequired,
    end: any.isRequired,
    offset: number.isRequired,
    limit: number.isRequired,
  }).isRequired,
  handleResetAll: func.isRequired,
  handleApplyFilters: func.isRequired,
  logRestrictions: shape({
    minDate: string.isRequired,
    maxDate: string.isRequired,
    logDays: number.isRequired,
    hasExportAccess: bool.isRequired,
    isMaxLogDays: bool.isRequired,
    maxExportLogDays: number.isRequired,
  }).isRequired,
  userContext: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
    dashboardUserId: string.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  submitLogExportRequest: func.isRequired,
  disableExport: bool.isRequired,
  canWriteBilling: bool.isRequired,
};
