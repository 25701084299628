import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Box, useTheme, Tabs, Tab, Typography } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import { Feedback, NoResults, Spinner, TabPanel } from '../../../../components';
import {
  DowngradePlanModal,
  LegacyCard,
  BillingInformation,
  PlanComparisonTable,
  VerifyEmailModal,
  PaymentAlert,
  MyPlan,
  UpgradeModal,
  AddOns,
  BusinessTaxModal,
} from './components';
import { SupportModal } from '../TopbarNav/components';
import {
  dateFormatter,
  usageErrorGenerator,
  displayNameFormatter,
} from './utils/textFormatters';
import { toggleModal } from './utils/modalUtils';
import staticText from '../../../../localization/Application/Billing/billing';
import ApplicationHeader from '../ApplicationHeader';
import { launchChat, openChat, shutdownChat, SALES_CHAT_ID, SUPPORT_CHAT_ID } from '../../../../services/front/front';
import { gatedFeatureData, isLegacy, isSelfServe } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import { TabsNav } from '../../../../global-styles/components';

const createBillingError = (errorText, onClick, buttonText) => {
  return (
    <div className="flex-vertical-center">
      <span>{errorText}</span>
      <span className="flex-vertical-center">
        &nbsp;
        {buttonText && onClick && (
          <span>
            <Button
              type="text"
              onClick={onClick}
              classes={{ text: 'mui-error-button' }}
            >
              {buttonText}
            </Button>
          </span>
        )}
      </span>
    </div>
  );
};

const Billing = (props) => {
  const {
    actions: {
      updateBillingInfo,
    },
    selectedApp,
    billingInfo,
    billingInfoError,
    isUpdatingBillingInfo,
    organization: {
      id: organizationId,
      featureSetId,
      rolePermissions,
    },
    session: {
      userContext: {
        email,
        emailVerifiedAt,
        dashboardUserId,
        firstName,
        lastName,
      },
    },
    updateBillingInfoError,
    location,
  } = props;
  const theme = useTheme();

  if (!rolePermissions.includes('read_billing')) {
    return (
      <Box sx={{ maxWidth: theme.width.content }}>
        <ApplicationHeader heading={staticText.heading} />
        <NoResults text={staticText.noPermissionToAccessPage} />
      </Box>
    );
  }
  const [currentModal, setCurrentModal] = useState('');
  const closeModal = toggleModal(setCurrentModal, '');

  useEffect(() => {
    reportToSegment(types.PAGE, 'Billing');

    // Check query params for Stripe Checkout session result
    const handleStripeRedirect = () => {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      const hasProcessedSession = localStorage.getItem('processed_session') === sessionId;

      if (sessionId && !hasProcessedSession) {
        const stripeCheckoutSessionResult = new URLSearchParams(location.search).get('success');

        if (stripeCheckoutSessionResult === 'true') {
          const planName = new URLSearchParams(location.search).get('planName');
          updateBillingInfo(planName, sessionId);

          localStorage.setItem('processed_session', sessionId);
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }
    };

    /* Check if we are handling or have handled the query params to
        prevent sending duplicate requests */
    if (!isUpdatingBillingInfo) {
      handleStripeRedirect();
    }

    // Replace Support chat with Sales chat on Billing page specifically,
    // and shutdown or restart Support chat before navigating to any other screen
    launchChat(SALES_CHAT_ID, {
      applicationId: (selectedApp && selectedApp.id) || '',
      developerId: organizationId,
      dashboardUserId,
      firstName,
      lastName,
      email,
    });

    return () => {
      if (!gatedFeatureData(FEATURES.CHAT_SUPPORT, featureSetId)) {
        launchChat(SUPPORT_CHAT_ID, {
          applicationId: (selectedApp && selectedApp.id) || '',
          developerId: organizationId,
          dashboardUserId,
          firstName,
          lastName,
          email,
        });
      } else {
        shutdownChat();
      }
    };
  }, []);

  useEffect(() => {
    if (!isUpdatingBillingInfo) {
      closeModal();
    }
  }, [isUpdatingBillingInfo]);

  // Hide plan comparison table for all non self-serve customers
  const isCurrentPlanLegacy = isLegacy(featureSetId);
  // const isEnterprisePlan = billingInfo.planName === staticText.enterprisePlanName;
  const isCurrentScalePlan = billingInfo.planName === staticText.scalePlanName
    && !isCurrentPlanLegacy;

  const modalMap = {
    downgradeFree: (
      <DowngradePlanModal
        isLoading={isUpdatingBillingInfo}
        plan={staticText.freePlanName}
        updateBillingInfo={updateBillingInfo}
        toggleModal={closeModal}
        expirationDate={
          billingInfo.billingPeriodEndDate &&
          dateFormatter(billingInfo.billingPeriodEndDate, true)
        }
        currentPlan={billingInfo.planName}
      />
    ),
    downgradeBuild: (
      <DowngradePlanModal
        isLoading={isUpdatingBillingInfo}
        plan={staticText.buildPlanName}
        updateBillingInfo={updateBillingInfo}
        toggleModal={closeModal}
        currentPlan={billingInfo.planName}
      />
    ),
    upgrade: (
      <UpgradeModal
        billingInfo={billingInfo}
        emailVerifiedAt={emailVerifiedAt}
        isCurrentPlanLegacy={isCurrentPlanLegacy}
        organizationId={organizationId}
        setCurrentModal={setCurrentModal}
        toggleModal={closeModal}
      />
    ),
    businessTax: planName => (
      <BusinessTaxModal
        organizationId={organizationId}
        planName={planName}
        toggleModal={closeModal}
      />
    ),
    verifyEmail: <VerifyEmailModal toggleModal={closeModal} />,
    support: <SupportModal toggleModal={closeModal} />,
  };

  const usageError = usageErrorGenerator(
    billingInfo.planName,
    billingInfo.numberOfVehiclesAboveBase,
    billingInfo.billedByVehicleConnections,
  );

  const feedbackContent = [];

  // Business logic below can be removed when there are no more Business (legacy) plans
  const buildOrScaleCanceled = billingInfo.hasCanceledSubscription;
  const scalePlanDowngraded =
    (billingInfo.planName === staticText.scalePlanName ||
      billingInfo.planName === staticText.businessPlanName) &&
    billingInfo.scheduledPlanName;

  if (billingInfo.planName !== staticText.freePlanName && !billingInfo.stripeCustomerId) {
    feedbackContent.push({
      key: 'stripeError',
      message: createBillingError(
        staticText.missingStripeCredentialsError,
        () => setCurrentModal('support'),
        'support.',
      ),
      type: 'alert',
    });
  }
  if (isUpdatingBillingInfo) {
    feedbackContent.push({
      key: 'isUpdatingBillingInfo',
      message: createBillingError(
        staticText.isUpdatingBillingInfo,
      ),
      type: 'alert',
    });
  }
  // Don't show banner for sales-led plans
  if (isSelfServe(featureSetId)) {
    if (buildOrScaleCanceled) {
      feedbackContent.push({
        key: 'buildOrScaleCanceled',
        message: createBillingError(
          staticText.planDowngraded(
            dateFormatter(billingInfo.planEndDate, true),
            displayNameFormatter(billingInfo.planName),
            'Free',
          ),
        ),
      });
    } else if (scalePlanDowngraded) {
      feedbackContent.push({
        key: 'scalePlanDowngraded',
        message: createBillingError(
          staticText.planDowngraded(
            dateFormatter(billingInfo.planEndDate, true),
            displayNameFormatter(billingInfo.planName),
            displayNameFormatter(staticText.buildPlanName),
          ),
        ),
      });
    }
  }
  if (usageError.message) {
    feedbackContent.push({
      key: 'usageError',
      message: createBillingError(
        usageError.message,
        // opens sales chat to upgrade
        openChat,
        staticText.contactUs,
      ),
      type: usageError.type,
    });
  }
  if (updateBillingInfoError) {
    feedbackContent.push({
      key: 'billingInfoError',
      message: createBillingError(
        staticText.updateBillingInfoError,
        () => setCurrentModal('support'),
        'support.',
      ),
    });
  }

  if (billingInfoError) {
    return (
      <Box sx={{ maxWidth: theme.width.content }}>
        <ApplicationHeader heading={staticText.heading} />
        <Feedback
          key={billingInfoError}
          message={
            createBillingError(staticText.billingInfoError,
            () => setCurrentModal('support'),
            'support.')
          }
          type="alert"
          additionalClassNames={['m-t-sm']}
        />
      </Box>
    );
  }

  let tabPanelGroup;
  if (billingInfo.planName === staticText.freePlanName) {
    tabPanelGroup = 'free';
  } else if (billingInfo.planName === staticText.buildPlanName ||
    billingInfo.planName === staticText.businessPlanName) {
    tabPanelGroup = 'build';
  } else if (billingInfo.planName === staticText.scalePlanName && !isCurrentScalePlan) {
    tabPanelGroup = 'scale';
  } else {
    tabPanelGroup = 'salesLed';
  }

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e, newValue) => {
    // segment event
    setCurrentTab(newValue);
  };

  const tabPanelMap = {
    'All plans': (
      <PlanComparisonTable
        baseVehicles={billingInfo.numberOfBaseVehicles}
        emailVerifiedAt={emailVerifiedAt}
        planName={billingInfo.planName}
        setCurrentModal={setCurrentModal}
        isCurrentPlanLegacy={isCurrentPlanLegacy}
      />),
    'My plan': (
      <MyPlan
        billingInfo={billingInfo}
        setCurrentModal={setCurrentModal}
      />),
    'Add-ons': (<AddOns />),
    'Billing information': (
      <BillingInformation
        organizationId={organizationId}
        billingInfo={billingInfo}
      />),
    Overview: (
      <BillingInformation
        organizationId={organizationId}
        billingInfo={billingInfo}
        salesLedPlan
      />),
  };

  return (
    <Box sx={{
      maxWidth: '1352px',
      margin: 'auto',
    }}
    >
      <ApplicationHeader heading={staticText.heading} />

      {Object.keys(billingInfo).length > 0 ? (
        <React.Fragment>
          {currentModal && (
          typeof currentModal === 'object'
            ? modalMap[currentModal.name](...currentModal.params)
            : modalMap[currentModal]
        )}

          <Typography fontSize={18} mb={2}>{staticText.pageDescription}</Typography>

          <TabsNav color="transparent" component="nav" elevation={0}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
            >
              {staticText.tabs[tabPanelGroup].map((tab, i) => (
                <Tab
                  key={tab}
                  label={tab}
                  value={i}
                  disableRipple
                  active="primary"
                />
          ))}
            </Tabs>
          </TabsNav>

          {feedbackContent && feedbackContent.map(({ key, message, type }, index) => (
            <Feedback
              key={key}
              message={message}
              type={type || 'warn'}
              additionalClassNames={[
                    'm-t-sm',
                    index + 1 === feedbackContent.length ? 'm-b-med' : 'm-b-sm',
                  ]}
            />
          ))}
          {isSelfServe(featureSetId) && billingInfo.delinquent && (
            <PaymentAlert
              organizationId={organizationId}
              billingInfo={billingInfo}
            />
         )}
          {isCurrentPlanLegacy && (
            <LegacyCard metronomePlanName={billingInfo.metronomePlanName || ''} />
         )}

          <div>
            {staticText.tabs[tabPanelGroup].map((tab, i) => (
              <TabPanel
                value={currentTab}
                index={i}
                key={`${tab}-panel-content`}
                pageName={tab}
              >
                {tabPanelMap[tab]}
              </TabPanel>
          ))}
          </div>
        </React.Fragment>
        )
        :
        <Spinner size="page" />
      }
    </Box>
  );
};

export default Billing;

Billing.propTypes = {
  actions: shape({
    updateBillingInfo: func.isRequired,
  }).isRequired,
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
  billingInfo: shape({
    planName: string.isRequired,
  }).isRequired,
  billingInfoError: string.isRequired,
  isUpdatingBillingInfo: bool.isRequired,
  organization: shape({
    featureSetId: string.isRequired,
    rolePermissions: arrayOf(string).isRequired,
  }).isRequired,
  session: shape({
    userContext: shape({
      email: string.isRequired,
      emailVerifiedAt: string,
    }).isRequired,
  }).isRequired,
  updateBillingInfoError: string.isRequired,
  location: shape({
    search: string,
  }).isRequired,
};
