import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const messageIndicator = (theme, error = '') => ({
  position: 'absolute',
  content: '""',
  backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
  height: '8px',
  width: '8px',
  borderRadius: '50%',
});

export const Nav = styled('nav', {
  shouldForwardProp: prop => prop !== 'expanded',
})(({ theme, expanded }) => ({
  position: 'sticky',
  top: theme.height.topbar,
  boxSizing: 'border-box',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  transition: 'width 0.25s ease',
  width: theme.width.sidebar.closed,
  cursor: 'pointer',

  ul: {
    listStyle: 'none',
    margin: '0 0 24px 0',
    padding: '0',

    ...(expanded && {
      // so when the nav expands, the contents past the width of the nav during the
      // opening animation don't show
      overflowX: 'hidden',
    }),
  },

  ...(expanded && {
    width: theme.width.sidebar.expanded,
  }),
}));

export const NavHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  cursor: 'default',
  margin: '0 0 6px 16px',
  textTransform: 'uppercase',
}));

export const NavItem = styled('li', {
  shouldForwardProp: prop => prop !== 'current' || prop !== 'newfeature' || prop !== 'error',
})(({
  theme, current, newfeature, error,
}) => ({
  position: 'relative',
  color: theme.palette.grey[600],
  borderRadius: theme.border.radius,
  margin: '0 0 6px 0',
  paddingTop: '2px',

  'a, button': {
    paddingLeft: '14px',
    height: '40px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),

    svg: {
      width: '20px',
      height: '20px',
      stroke: theme.palette.text.primary,
    },

    p: {
      marginLeft: theme.spacing(2),
      marginTop: '3px',
      color: theme.palette.text.primary,
      textAlign: 'left',

      ...(current && {
        fontFamily: theme.typography.bold.fontFamily,
        color: theme.palette.primary.main,
      }),
    },

    '.hover-text': {
      display: 'none',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],

      p: {
        fontFamily: theme.typography.bold.fontFamily,
      },

      '.hover-text': {
        display: 'block',
      },
    },
  },


  '&.icon': {
    width: '48px',

    ...((newfeature || error) && {
      '&:after': {
        ...(messageIndicator(theme, error)),
        top: '4px',
        right: '4px',
      },
    }),
  },

  '&.text': {
    width: '200px',

    p: {
      position: 'relative',

      ...((newfeature || error) && {
        '&:after': {
          ...(messageIndicator(theme, error)),
          top: '-1px',
          right: '-14px',
        },
      }),
    },

  },

  '&.sub-nav': {
    width: '176px',

    a: {
      marginLeft: '16px',
      paddingLeft: '0px',
      width: 'auto',

      p: {
        marginLeft: theme.spacing(1.5),
      },
    },
  },

  ...(current && {
    '> a, button': {
      backgroundColor: theme.palette.grey[100],
    },

    'svg *': {
      stroke: theme.palette.primary.main,
    },
  }),

  '&.MuiButtonBase-root.MuiMenuItem-root': {
    width: 'auto',
    margin: theme.spacing(1, 0),
    padding: '0px',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    a: {
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),

      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      p: {
        margin: '0px',
      },
    },
  },
}));

export const NavToggle = styled('button', {
  shouldForwardProp: prop => prop !== 'menuExpanded',
})(({ theme, menuExpanded }) => ({
  position: 'absolute',
  left: '100%',
  top: 'calc(50vh - 15px - 60px)',
  width: '20px',
  height: '30px',
  padding: 0,
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  border: `1px solid ${theme.palette.grey[200]}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },

  svg: {
    height: '18px',
    width: '18px',
    transition: 'transform 0.5s ease',

    ...(menuExpanded && {
      transform: 'rotate(180deg)',
    }),
  },

}));

export const SubNavWrapper = styled('div')(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.grey[200]}`,
  marginLeft: '22px',
}));

export const HoverText = styled('div', {
  shouldForwardProp: prop => prop !== 'location',
})(({ theme, location }) => ({
  ...theme.typography.caption,
  position: 'absolute',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1),
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow2,
  // defaults to sidebar positioning
  left: '125%',
  top: '2px',

  ...(location === 'topbar' && {
    top: '130%',
    transform: 'translateX(-50%)',
    left: '50%',
  }),
}));
