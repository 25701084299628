import * as Sentry from '@sentry/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router, withRouter } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory as createHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { init } from 'commandbar';

// eslint-disable-next-line import/extensions
import appConfig from 'appConfig';

import AppContainer from './AppContainer';
import rootSaga from './sagas';
import reducer from './reducer';
import stateTransformer from './services/stateTransformer';
import packageJson from '../package.json';
import theme from './global-styles/theme';
import api from './services/api/api';
import { CommandAiProvider } from './providers/CommandAi';

init(process.env.COMMAND_AI_ORG_ID);

const history = createHistory();

// Add middleware to allow us to dispatch actions for route changes
const router = routerMiddleware(history);

const { DSN: sentryDSN } = appConfig.sentry;
const { clientSideID: CLIENT_SIDE_ID } = appConfig.launchDarkly;
const { NODE_ENV: environment } = process.env;

Sentry.init({
  dsn: sentryDSN,
  release: packageJson.version,
  environment,
  integrations: [new Sentry.Integrations.RewriteFrames()],
});

const sentryMiddleware = (store) => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor((event) => {
      const {
        user: { username },
        ...state
      } = stateTransformer(store.getState());

      return {
        ...event,
        extra: {
          ...event.extra,
          'redux:state': state,
        },
        user: {
          ...event.user,
          username,
        },
      };
    });
  });

  return next => (action) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
      data: {
        STRING: action.str,
      },
    });
    return next(action);
  };
};

const sagaMiddleware = createSagaMiddleware();

const resetFetching = createTransform(
  undefined,
  // rehydrates with isFetching true to prevent rendering Application prematurely
  (outboundState) => {
    return {
      ...outboundState,
      isFetching: true,
    };
  },
  { whitelist: ['applications'] },
);

const authBlacklist = createBlacklistFilter('auth', [
  'signUpFormErrors',
  'loginFormErrors',
  'forgotPasswordFormErrors',
  'resetPasswordFormErrors',
  'inProgress',
  'formSuccess',
]);

const appBlacklist = createBlacklistFilter('applications', [
  'initialized',
]);

const onboardingBlacklist = createBlacklistFilter('onboarding', [
  'initialized',
]);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['secret'],
  transforms: [authBlacklist, appBlacklist, onboardingBlacklist, resetFetching],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  composeWithDevToolsDevelopmentOnly(
    applyMiddleware(sagaMiddleware, sentryMiddleware, router),
  ),
);
api.interceptor(store);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const AppWithRouter = withRouter(AppContainer);
const container = document.getElementById('root');
const root = createRoot(container);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID,
  });

  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LDProvider>
          <CommandAiProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                  <AppWithRouter />
                </Router>
              </PersistGate>
            </LocalizationProvider>
          </CommandAiProvider>
        </LDProvider>
      </ThemeProvider>
    </Provider>,
  );
})();

