import { MenuItem, Select, styled } from '@mui/material';

export const Preview = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'stretch',
  position: 'relative',
}));

export const PreviewHeaderWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

export const PreviewHeaderForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const CountrySelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  minWidth: '220px',
  border: 'none',
  '& fieldset, &.MuiOutlinedInput-root.Mui-disabled > fieldset': {
    border: `1px solid ${theme.palette.common.white}`,
  },
  color: theme.palette.primary.main,
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: '14px',
}));

export const CountryOption = styled(MenuItem)(() => ({
  fontSize: '14px',
}));

export const PreviewIframe = styled('div', {
  shouldForwardProp: prop => prop !== 'darkmode',
})(({ darkmode, offset }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '24px',
  ...(offset && {
    marginTop: '80px',
  }),

  '#connect-preview-background': {
    maxWidth: '347px',
    maxHeight: '730px',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: '#F9FDFF',
    borderRadius: '18px',
    ...(darkmode && {
      backgroundColor: '#151D20',
    }),
  },

  '& iframe': {
    width: '347px',
    maxHeight: '730px',
    height: '100%',
    border: 'none',
    borderRadius: '18px',
    boxShadow: '0px 5.4px 9px 5.4px #8E8E8E26',
  },
}));
