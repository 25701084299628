/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Button, Typography } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

import {
  Appearance,
  BrandManagement,
  ConnectPreview,
  Countries,
} from './components';
import { Spinner } from '../../../../components';
import {
  CustomizationsContainer,
  PreviewWrapper,
  CustomizationsSidebar,
  CustomizationsGroupButton,
  BackButton,
  PublishButtonContainer,
} from './styles';
import staticText from '../../../../localization/Application/connect-config';
import checkChangeToCustomizations from './utils/checkChangeToCustomizations';
import { filterMakesByEngineType } from './components/BrandManagement/utils/filterMakesByEngineType';
import previewConfig from './utils/previewConfig';

const ConnectCustomizations = ({
  actions: {
    fetchConnectConfigRequest,
    fetchApplicationMakesRequest,
    fetchSupportedCountriesRequest,
    updateConnectConfigRequest,
    updateApplication,
    fetchBrandManagementCapabilitiesRequest,
    deleteAppLogo,
  },
  appId,
  appName,
  connectConfig,
  uploadedLogoUrl,
  defaultSupportedBrands,
  defaultSupportedCountries,
  fetchingConnectConfigErrors,
  isFetchingConnectConfig,
  brandManagementCapabilities,
  isFetchingDefaultSupportedBrands,
  isFetchingDefaultSupportedCountries,
  isUpdating,
  isFetchingBrandManagementCapabilities,
}) => {
  /**
  * State across all customizations group components lives here at the parent level.
  * Initial values are set to whatever is saved in the app's connect config record.
  *
  * customizations are stored as a user moves between customizations groups, and will get
  * reset to the original config if the user refreshes or navigates away from the
  * ConnectCustomizations page without publishing.
  *
  * Each customizations group component is passed the selections state and update method
  * as props.
  */
  const [customizations, setCustomizations] =
    useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [activeCustomizationsGroup, setActiveCustomizationsGroup] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [disabledBrands, setDisabledBrands] = useState([]);
  const [availableBrands, setAvailableBrands] = useState([]);
  const [suggestionDialogOpen, setSuggestionDialogOpen] = useState(false);

  // Helps keep track of original supported makes. If you change a filter
  // then change it back to it's original state when the connect config
  // loaded, then the selected makes should revert back to the original
  const [originalSupportedMakes, setOriginalSupportedMakes]
    = useState(customizations && customizations.supportedMakes && customizations.supportedMakes);

  const openCustomizationsGroup = (e) => {
    setActiveCustomizationsGroup(e.target.name);
    reportToSegment(types.TRACK, eventNames.tabSelected, {
      text: e.target.textContent,
      label: 'connect panel',
    });
  };

  // This handler is passed to each customizations group component
  // for handling customization state updates.
  // Updated fields should be passed in the format:
  // { [fieldName]: value }
  const updateCustomizations = (updatedFields) => {
    if (updatedFields.brandManagementFilters) {
      const {
        filteredSelectedMakes,
        disabledMakes,
        availableMakesAfterFiltersApplied,
      } = filterMakesByEngineType(
        originalSupportedMakes,
        brandManagementCapabilities,
        updatedFields.brandManagementFilters,
        defaultSupportedBrands,
      );

      updatedFields.supportedMakes = filteredSelectedMakes;

      setDisabledBrands(disabledMakes);
      setAvailableBrands(availableMakesAfterFiltersApplied);
    }

    setCustomizations({ ...customizations, ...updatedFields });
    // Disable publish button when no change to customizations is detected
    const changeDetected = checkChangeToCustomizations(
      customizations,
      updatedFields,
      connectConfig,
      appName,
    );
    setSubmitDisabled(!changeDetected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customizations.appName && appName !== customizations.appName) {
      updateApplication(appId, { name: customizations.appName });
    }
    updateConnectConfigRequest(customizations);
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'Customize Connect',
      form_content: {
        ...customizations,
      },
    });
    setSubmitDisabled(true);
  };

  const renderErrorMessage = message => (
    <Typography variant="caption" color="error">
      {message || staticText.errors.updatingConnectConfig}
    </Typography>
  );

  const renderBackButton = () => (
    <BackButton
      onClick={() => {
        setActiveCustomizationsGroup(null);
      }}
    >
      <ChevronLeft />
      {staticText.backButton}
    </BackButton>
  );

  const isLoading = isFetchingConnectConfig
  || isFetchingDefaultSupportedBrands
  || isFetchingDefaultSupportedCountries
  || isFetchingBrandManagementCapabilities;

  useEffect(() => {
    // Once all data has been fetched, set the true initial state
    if (!isLoading) {
      const {
        filteredSelectedMakes,
        disabledMakes,
        availableMakesAfterFiltersApplied,
      } = filterMakesByEngineType(
        (
          connectConfig &&
          connectConfig.supportedMakes &&
          connectConfig.supportedMakes.length > 0 &&
          connectConfig.supportedMakes
        ) ?
          connectConfig.supportedMakes
          : defaultSupportedBrands.map(supportedBrand => supportedBrand.make),
        brandManagementCapabilities,
        (connectConfig && connectConfig.brandManagementFilters) || [],
        defaultSupportedBrands,
      );

      setCustomizations({
        appName,
        theme: (connectConfig && connectConfig.theme) || 'system',
        logoUrl: (connectConfig && connectConfig.logoUrl) || '',
        supportedMakes: filteredSelectedMakes,
        brandManagementFilters: (connectConfig && connectConfig.brandManagementFilters) || [],
        newBrandOptIn: !((connectConfig && connectConfig.newBrandOptIn === false)),
        supportedCountries:
          (connectConfig &&
            connectConfig.supportedCountries &&
            connectConfig.supportedCountries.length > 0) ?
            connectConfig.supportedCountries : defaultSupportedCountries,
      });

      setDisabledBrands(disabledMakes);
      setIsInitialized(true);
      setAvailableBrands(availableMakesAfterFiltersApplied);
      setOriginalSupportedMakes(filteredSelectedMakes);
    }
  }, [isLoading]);

  useEffect(() => {
    // Any initial data required for connect customizations should be fetched here
    fetchConnectConfigRequest(); // previously saved config if any
    fetchApplicationMakesRequest(); // makes app has access to
    fetchSupportedCountriesRequest(); // countries app has access to
    fetchBrandManagementCapabilitiesRequest(); // brand management capabilities

    reportToSegment(types.PAGE, 'Customizations');

    return () => {
      // delete any uploaded but unpublished logos
      if (uploadedLogoUrl && uploadedLogoUrl !== connectConfig.logoUrl) {
        deleteAppLogo(uploadedLogoUrl);
      }
    };
  }, []);

  useEffect(() => {
    // if commandbar launcher is present, reposition it for customizations sidebar
    // if it is not immediately present (reloading page), wait for it before repositioning
    let commandbarLauncher = document.querySelector('.commandbar-launcher');
    if (commandbarLauncher) {
      commandbarLauncher.style.right = '425px';
    } else {
      setTimeout(() => {
        commandbarLauncher = document.querySelector('.commandbar-launcher');
        if (commandbarLauncher) {
          commandbarLauncher.style.right = '425px';
        }
      }, 600);
    }
    return () => {
      // reset commandbar launcher offset when leaving page
      if (commandbarLauncher) {
        commandbarLauncher.style.right = '45px';
      }
    };
  }, []);

  return (
    <CustomizationsContainer>
      <PreviewWrapper>
        {!isLoading && isInitialized && (
          <ConnectPreview
            customizations={customizations}
            config={previewConfig[activeCustomizationsGroup]}
            defaultSupportedCountries={defaultSupportedCountries}
          />
        )}
      </PreviewWrapper>
      <CustomizationsSidebar>
        {isLoading && !isUpdating && <Spinner />}
        {!isLoading && isInitialized && !activeCustomizationsGroup && (
          <React.Fragment>
            <Typography variant="h1" marginBottom={2}>
              {staticText.heading}
            </Typography>
            <Typography variant="body1" marginBottom={4}>
              {staticText.description}
            </Typography>
            <CustomizationsGroupButton
              name="appearance"
              onClick={openCustomizationsGroup}
            >
              {staticText.appearance.heading}
              <ChevronRight />
            </CustomizationsGroupButton>
            <CustomizationsGroupButton
              name="brands"
              onClick={openCustomizationsGroup}
            >
              {staticText.brandManagement.heading}
              <ChevronRight />
            </CustomizationsGroupButton>
            <CustomizationsGroupButton
              name="countries"
              onClick={openCustomizationsGroup}
            >
              {staticText.countries.heading}
              <ChevronRight />
            </CustomizationsGroupButton>
          </React.Fragment>
        )}

        {activeCustomizationsGroup === 'appearance' && (
          <div id="appearance">
            {renderBackButton()}
            <Appearance
              customizations={customizations}
              updateCustomizations={updateCustomizations}
            />
          </div>
        )}
        {activeCustomizationsGroup === 'brands' && (
          <div id="brands">
            {renderBackButton()}
            <BrandManagement
              disabledBrands={disabledBrands}
              availableBrands={availableBrands}
              customizations={customizations}
              updateCustomizations={updateCustomizations}
              renderErrorMessage={renderErrorMessage}
              suggestionDialogOpen={suggestionDialogOpen}
              setSuggestionDialogOpen={setSuggestionDialogOpen}
            />
          </div>
        )}
        {activeCustomizationsGroup === 'countries' && (
          <div id="countries">
            {renderBackButton()}
            <Countries
              customizations={customizations}
              updateCustomizations={updateCustomizations}
            />
          </div>
        )}

        <PublishButtonContainer>
          {fetchingConnectConfigErrors &&
            fetchingConnectConfigErrors.connectConfig &&
            renderErrorMessage()}
          <Button
            id="publish-customizations"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            {isUpdating ? <Spinner size="button" spinnerColor="#FFF" /> : staticText.publishAllChanges}
          </Button>
        </PublishButtonContainer>
      </CustomizationsSidebar>
    </CustomizationsContainer>
  );
};

export default ConnectCustomizations;

ConnectCustomizations.propTypes = {
  actions: shape({
    fetchConnectConfigRequest: func.isRequired,
    fetchApplicationMakesRequest: func.isRequired,
    fetchSupportedCountriesRequest: func.isRequired,
    updateConnectConfigRequest: func.isRequired,
    updateApplication: func.isRequired,
    deleteAppLogo: func.isRequired,
  }).isRequired,
  appId: string.isRequired,
  appName: string.isRequired,
  connectConfig: shape({
    theme: string,
    logoUrl: string,
    supportedCountries: arrayOf(string),
  }),
  uploadedLogoUrl: string,
  defaultSupportedBrands: arrayOf(
    shape({
      make: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  defaultSupportedCountries: arrayOf(string).isRequired,
  isFetchingConnectConfig: bool.isRequired,
  isFetchingDefaultSupportedBrands: bool.isRequired,
  isFetchingDefaultSupportedCountries: bool.isRequired,
  isUpdating: bool.isRequired,
  isFetchingBrandManagementCapabilities: bool.isRequired,
  updateConnectConfigRequest: func.isRequired,
  fetchingConnectConfigErrors: shape({
    connectConfig: string,
  }),
  brandManagementCapabilities: arrayOf(shape(object)).isRequired,
};

ConnectCustomizations.defaultProps = {
  fetchingConnectConfigErrors: {},
  connectConfig: {},
  uploadedLogoUrl: null,
};
