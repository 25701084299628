import React from 'react';
import { bool, node, func, string } from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, useTheme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Modal = ({
  title,
  content,
  actions,
  toggleModal,
  divider,
  hideClose,
  viewedStorageKey, // optional local storage key to mark modal as viewed
  maxWidth,
}) => {
  const theme = useTheme();

  const closeModal = () => {
    if (viewedStorageKey) {
      localStorage.setItem(viewedStorageKey, 'true');
    }
    toggleModal();
  };

  return (
    <Dialog
      open
      onClose={toggleModal}
      aria-labelledby={title}
      maxWidth={maxWidth}
      fullWidth
    >
      {title && (
        <React.Fragment>
          <DialogTitle id={title} mt={1}>
            {title}
          </DialogTitle>
          {divider && <Divider data-testid="modal-divider" />}
        </React.Fragment>
      )}
      {!hideClose && (
        <IconButton
          data-testid="modal-close"
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: theme.palette.grey[500],
          }}
        >
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      )}
      <DialogContent sx={{
        ...theme.styledScrollbar,
        ...theme.scrollShadows,
      }}
      >
        {content}
      </DialogContent>
      {actions && (
        <DialogActions sx={{ padding: theme.spacing(3) }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;

Modal.propTypes = {
  title: string,
  content: node.isRequired,
  toggleModal: func.isRequired,
  actions: node,
  divider: bool,
  hideClose: bool,
  maxWidth: string,
  viewedStorageKey: string,
};

Modal.defaultProps = {
  title: '',
  actions: null,
  divider: true,
  hideClose: false,
  maxWidth: 'sm',
  viewedStorageKey: null,
};
