import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Select } from '../../styles';
import { SelectIcon, Spinner } from '../../../../../../../../components';
import vinny from '../../../../../../../../services/validators/vinValidator';
import staticText from '../../../../../../../../localization/Application/Simulator/create';

const Input = styled(TextField)(({ theme }) => ({
  width: '236px',
  marginRight: theme.spacing(1),
}));

/* istanbul ignore next */
const VinForm = ({
  allVins,
  applicationId,
  compatibleVehicles,
  disabled,
  isFetchingVinCompatibility,
  fetchVinCompatibility,
  handleVehicleSearchSubmit,
  selectedVehicle,
  setSelectedVehicle,
  selectedRegion,
  showLastSearch,
  vinCompatibility,
  cancelFetching,
  setErrorMessage,
}) => {
  const theme = useTheme();
  const formLabel = 'Simulator VIN Search Form';


  const initialVinFormState = {
    vin: (showLastSearch && selectedVehicle.vin) || '',
    validVin: showLastSearch,
    vinError: '',
    touchedVinInput: false,
    compatible: false,
    make: '',
    model: '',
    year: '',
  };
  const [formState, setFormState] = useState(initialVinFormState);
  const {
    compatible, make, model, year, vin, vinError, validVin, touchedVinInput,
  } = formState;
  const [models, setModels] = useState([]);

  const handleVinSubmit = () => {
    const compatibleVehicle = compatibleVehicles[make][model].find(
      vehicle => vehicle.year === year,
    );
    if (!compatibleVehicle) {
      setSelectedVehicle({});
      return setErrorMessage(staticText.vinForm.errors.incorrectModel);
    }

    const vehicle = {
      vehicleDefinitionId: compatibleVehicle.id,
      make,
      model,
      year: Number(year),
      vin,
    };
    return handleVehicleSearchSubmit(formLabel, vehicle);
  };

  const handleVinCheck = (e) => {
    e.preventDefault();
    setSelectedVehicle({});
    // Display error if VIN matches a vehicle already used within the app
    if (allVins.includes(formState.vin)) {
      return setFormState((currState) => {
        return {
          ...currState,
          vinError: staticText.vinForm.errors.alreadyInUse,
          touchedVinInput: true,
        };
      });
    }
    setFormState((currState) => {
      return { ...currState, touchedVinInput: true };
    });
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: staticText.vinForm.vinButton,
      form_content: formState.vin,
    });
    return fetchVinCompatibility(applicationId, vin);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const isValid = value && vinny.isValid(value);
    if (!isValid && Object.keys(selectedVehicle).length > 0) {
      setSelectedVehicle({});
    }
    setFormState((currState) => {
      return {
        ...currState,
        vin: value,
        validVin: isValid,
        vinError: '',
        compatible: false,
        make: '',
        model: '',
        year: '',
      };
    });
  };

  const handleInputBlur = () => {
    setFormState((currState) => {
      return {
        ...currState,
        touchedVinInput: true,
        vinError: validVin ? '' : staticText.vinForm.errors.invalid,
      };
    });
  };

  const handleSelectChange = (e) => {
    setErrorMessage('');
    setFormState((currState) => {
      return { ...currState, model: e.target.value };
    });
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: '[simulator] VIN form: confirm vehicle\'s model',
    });
  };

  useEffect(() => {
    if (selectedRegion !== 'US') {
      setFormState({ ...initialVinFormState, vin: '' });
    }
  }, [selectedRegion]);

  // Handle vin compatibility results
  useEffect(() => {
    if (selectedRegion === 'US') {
      const {
        compatible: vinCompatible, make: vinMake, year: vinYear,
      } = vinCompatibility;

      if (vinCompatible && vinMake && vinYear) {
        setFormState((currState) => {
          return {
            ...currState, compatible: vinCompatible, make: vinMake, year: vinYear, vin,
          };
        });

        // Vehicle is compatible but we cannot use the model returned from the API as
        // occasionally the model names do not match what we have in our compatibility matrix.
        // Instead we will render a dropdown for the user to confirm the model. The model
        // needs to match what is listed in the matrix for us to later fetch proper capability
        // and latency data.
        setModels(Object.keys(compatibleVehicles[vinMake]));

        reportToSegment(types.TRACK, eventNames.formSubmitted, {
          label: formLabel,
          form_content: {
            vin,
            compatible: true,
            make: vinMake,
          },
        });
      } else if (vinCompatible) {
        // Vehicle is compatible but we did not receive make and/or year from the api
        setFormState((currState) => {
          return { ...currState, vinError: staticText.vinForm.errors.noMMY };
        });
        reportToSegment(types.TRACK, eventNames.formSubmitted, {
          label: formLabel,
          form_content: {
            vin,
            compatible: 'true - but no MMY',
          },
        });
      } else {
        setFormState((currState) => {
          return { ...currState, vinError: staticText.vinForm.errors.incompatible };
        });
        reportToSegment(types.TRACK, eventNames.formSubmitted, {
          label: formLabel,
          form_content: {
            vin,
            compatible: false,
            make: vinMake,
          },
        });
      }
    }
  }, [vinCompatibility]);

  useEffect(() => {
    if (make && model && year) {
      handleVinSubmit();
    }
  }, [model]);

  useEffect(() => {
    if ((vinError && touchedVinInput) || !vinError) {
      setErrorMessage(vinError);
    }
  }, [vinError]);

  useEffect(() => {
    if (!vin) {
      setModels([]);
      setFormState(initialVinFormState);
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      cancelFetching('vinCompatibility');
    };
  }, []);

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        <Input
          id="vin"
          value={vin}
          name="vin"
          variant="outlined"
          label={staticText.vinForm.label}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
      </Box>
      {compatible && models.length > 0 && (
        <Box>
          <FormControl variant="outlined" disabled>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              name="year"
              value={year}
              id="year-select"
              label="Year"
              labelId="year-select-label"
              IconComponent={SelectIcon}
              variant="outlined"
              autoWidth
              MenuProps={{ sx: theme.muiSelectMenuProps }}
            >
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" disabled>
            <InputLabel id="make-select-label">Make</InputLabel>
            <Select
              name="make"
              value={make}
              id="make-select"
              label="Make"
              labelId="make-select-label"
              IconComponent={SelectIcon}
              variant="outlined"
              autoWidth
              MenuProps={{ sx: theme.muiSelectMenuProps }}
            >
              <MenuItem key={make} value={make}>
                {make}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" disabled={!models.length}>
            <InputLabel id="vin-model-select-label">Model</InputLabel>
            <Select
              name="model"
              value={model}
              id="vin-model-select"
              label="Model"
              labelId="vin-model-select-label"
              IconComponent={SelectIcon}
              onChange={handleSelectChange}
              variant="outlined"
              autoFocus
              MenuProps={{ sx: theme.muiSelectMenuProps }}
            >
              {models.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        )}
      {!compatible && (
        <Button
          id="vin-form-search-button"
          variant="contained"
          type="submit"
          size="large"
          disabled={!!vinError || !validVin || selectedRegion !== 'US'}
          onClick={handleVinCheck}
          sx={{ alignSelf: 'flex-start', height: '55px', width: '115px' }}
        >
          {isFetchingVinCompatibility
            ? <Spinner spinnerColor={theme.palette.common.white} size="button" additionalClassNames="simulator-vin-form" />
            : staticText.vinForm.vinButton
          }
        </Button>
        )}
    </Box>
  );
};

export default VinForm;

VinForm.propTypes = {
  allVins: arrayOf(string).isRequired,
  applicationId: string.isRequired,
  compatibleVehicles: object.isRequired,
  selectedRegion: string.isRequired,
  selectedVehicle: shape({
    make: string,
    model: string,
    year: number,
  }),
  setSelectedVehicle: func.isRequired,
  disabled: bool.isRequired,
  isFetchingVinCompatibility: bool.isRequired,
  fetchVinCompatibility: func.isRequired,
  handleVehicleSearchSubmit: func.isRequired,
  showLastSearch: bool.isRequired,
  vinCompatibility: object.isRequired,
  cancelFetching: func.isRequired,
  setErrorMessage: func.isRequired,
};

VinForm.defaultProps = {
  selectedVehicle: {},
};
