import { styled } from '@mui/material/styles';

import loadingShimmerStyles from '../../../global-styles/animations/loadingShimmer';

const LoadingRectangle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#EDEBEA',
  flexGrow: 1,
  borderRadius: theme.spacing(1),
  ...loadingShimmerStyles,
}));

export default LoadingRectangle;
