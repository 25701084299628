import React, { useEffect } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { Spinner } from '../../../../components';

const SSO = ({
  actions: {
    authenticateSsoRequest,
    resetSsoAuthorizationUrl,
  },
  location,
  history,
  loginFormErrors,
  signUpFormErrors,
}) => {
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const authCode = urlParams.get('code');
    const errorDescription = urlParams.get('error_description');

    if (authCode) {
      authenticateSsoRequest(authCode);
    } else if (errorDescription) {
      resetSsoAuthorizationUrl(errorDescription);
      history.replace('/');
    } else {
      history.replace('/');
    }
  }, [location, history]);

  useEffect(() => {
    if (loginFormErrors && loginFormErrors.length > 0) {
      history.push('/');
    }

    if (signUpFormErrors && signUpFormErrors.length > 0) {
      history.push('/');
    }
  }, [loginFormErrors, signUpFormErrors]);

  return <Spinner size="page" />;
};

export default SSO;

SSO.propTypes = {
  actions: shape({
    authenticateSsoRequest: func.isRequired,
    resetSsoAuthorizationUrl: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  history: shape({
    replace: func.isRequired,
  }).isRequired,
  loginFormErrors: arrayOf(string),
  signUpFormErrors: arrayOf(string),
};

SSO.defaultProps = {
  loginFormErrors: [],
  signUpFormErrors: [],
};

