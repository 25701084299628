import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

import { Modal } from '../../../../../../components';
import PlanComparisonTable from '../PlanComparisonTable/PlanComparisonTable';

const UpgradeModal = ({
  billingInfo,
  emailVerifiedAt,
  isCurrentPlanLegacy,
  organizationId,
  setCurrentModal,
  toggleModal,
}) => {
  const segmentProperties = {
    label: 'billing modal',
    title: 'Select a plan',
  };
  reportToSegment(types.TRACK, eventNames.modalOpened, segmentProperties);

  const toggleModalWithTracking = () => {
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
    toggleModal();
  };

  const content = (
    <PlanComparisonTable
      emailVerifiedAt={emailVerifiedAt}
      planName={billingInfo.planName}
      setCurrentModal={setCurrentModal}
      organizationId={organizationId}
      isCurrentPlanLegacy={isCurrentPlanLegacy}
      renderSectionWrapper={false}
    />
  );

  return (
    <Modal
      title="Select a plan"
      content={content}
      toggleModal={toggleModalWithTracking}
      maxWidth="xl"
    />
  );
};

export default UpgradeModal;

UpgradeModal.propTypes = {
  billingInfo: shape({
    planName: string.isRequired,
  }).isRequired,
  emailVerifiedAt: string,
  isCurrentPlanLegacy: bool.isRequired,
  organizationId: string.isRequired,
  setCurrentModal: func.isRequired,
  toggleModal: func.isRequired,
};

UpgradeModal.defaultProps = {
  emailVerifiedAt: null,
};
