/* eslint-disable import/prefer-default-export */
import { Chip as MuiChip, styled } from '@mui/material';


export const Chip = styled(MuiChip)(({ theme }) => ({
  padding: theme.spacing(1.5),
  '.MuiChip-icon': {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  '.MuiChip-label': {
    padding: '0',
    marginLeft: theme.spacing(0.5),
    fontFamily: theme.typography.fontFamily,
  },
  fontSize: 12,
  width: 'fit-content',
  border: theme.border.default,
}));
