import React from 'react';
import { bool, func } from 'prop-types';

import applicationText from '../../../../../../../../localization/Application/application';
import staticText from '../../../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import { NoResults } from '../../../../../../../../components';

const NoResultsFound = ({
  provideLearnMoreCta, resetFilters,
}) => {
  let text;
  let ctaProps;
  if (provideLearnMoreCta) {
    text = applicationText.paintedDoor.noData;
    ctaProps = {
      link: {
        href: applicationText.paintedDoor.learnMoreUrl.logs,
        text: applicationText.paintedDoor.learnMore,
        trackingContext: 'Vehicle Details',
      },
    };
  } else {
    text = staticText.noVehiclesWithFilters;
    ctaProps = {
      button: {
        handleClick: resetFilters,
        text: staticText.resetFilters,
        trackingContext: 'Vehicle Details',
      },
    };
  }

  return (
    <NoResults
      text={text}
      outlined={false}
      {...ctaProps}
    />
  );
};

export default NoResultsFound;

NoResultsFound.propTypes = {
  provideLearnMoreCta: bool.isRequired,
  resetFilters: func.isRequired,
};
