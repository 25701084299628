import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getApplicationsByOrg = (applications, organizations) => {
  const applicationsByOrg = {};
  Object.values(applications).map((app) => {
    const orgId = app.organizationId;
    if (!applicationsByOrg[orgId]) {
      applicationsByOrg[orgId] = {
        id: orgId,
        name: organizations ? _.find(organizations, org => org.id === orgId).name : null,
        applications: {},
      };
    }
    applicationsByOrg[orgId].applications[app.id] = app;
    return applicationsByOrg;
  }, {});

  return applicationsByOrg;
};
