export default {
  modal: {
    label: 'register application',
    title: 'Register new application',
  },

  paintedDoor: {
    noData: 'No vehicle data available for the given time period.',
    noWebhooks: 'No webhooks found for this vehicle.',
    featureInProgress: 'This feature is coming soon! Please check in at a later time.',
    featureToBeDeprecated: 'This feature will soon be deprecated.',
    createWebhook: 'Create a webhook',
    learnMore: 'Learn more',
    upgradeButton: 'Upgrade now',
    planUpgradePath: 'billing',
    upgradeMessage: {
      connectConfig: 'Connect Configuration is a paid feature. To receive access, please upgrade your plan.',
      logs: 'Logs are a paid feature. To receive access, please upgrade your plan.',
      simulator: 'The Vehicle Simulator is a paid feature. To receive access, please upgrade your plan.',
      webhooks: 'Webhooks are a paid feature. To receive access, please upgrade your plan.',
      teams: 'Teams is a paid feature. To receive access, please upgrade your plan.',
    },
    learnMoreUrl: {
      logs: 'https://smartcar.com/docs/getting-started/integration-overview',
    },
  },

  supportModal: {
    title: 'Contact our support team',
    label: 'contact support',
    text: 'Please describe the problem you are experiencing below and our support team will get back to you as quickly as possible.',
    form: {
      subject: {
        title: 'Summary',
        placeholder: 'Summarize the problem in a few words',
        helperText: '60 characters max',
      },
      message: {
        title: 'Description',
        placeholder: 'Describe the problem in more detail',
        helperText: '500 characters max',
      },
      submit: 'Send',
      cancel: 'Cancel',
      inputError: (minNum, maxNum) => `Please enter a string that is at least ${minNum} characters and no more than ${maxNum} characters long.`,
      successMessage: 'Thanks! Our support team will be in touch with you soon.',
      submissionError: 'Sorry, there was an error submitting your message. Please try again later, or send an email directly to support@smartcar.com.',
    },
  },

  upgradeModal: {
    label: 'provide link to billing',
    title: 'Upgrade your plan',
  },

  selectAppsModal: {
    checklistError: 'Please select the appropriate amount of applications.',
    save: 'Save',
    selectAppsDowngraded: appLimit =>
      `Your new plan supports a maximum of ${appLimit} ${appLimit > 1 ? 'applications' : 'application'}.
      Select ${appLimit} ${appLimit > 1 ? 'applications' : 'application'} you'd like to keep access to.`,
    selectAppsUpgraded: 'All other applications will be disabled and won\'t be accessible moving forward',
    title: 'Select applications',
  },

  registerApplication: 'Register new application',

  termsOfServiceModal: {
    label: 'accept latest terms of service',
    title: 'Updated Terms of Service',
    description: "We've updated our |+|Terms of Service@@https://smartcar.com/legal/terms|+|. Please review and accept the latest terms to continue using Smartcar.",
    submit: 'Accept',
  },
};
