import React from 'react';
import { Box, useTheme } from '@mui/material';
import { arrayOf, shape, string } from 'prop-types';

import ApplicationHeader from '../ApplicationHeader';
import staticText from '../../../../localization/Application/OrgSecurity/orgSecurity';
import { SSO } from './components';
import { NoResults } from '../../../../components';

const OrgSecurity = ({
  organization: {
    rolePermissions,
  },
}) => {
  const theme = useTheme();
  if (!rolePermissions.includes('write_company')) {
    return (
      <Box sx={{ maxWidth: theme.width.content }}>
        <ApplicationHeader heading={staticText.heading} />
        <NoResults text={staticText.noPermissionToAccessPage} />
      </Box>
    );
  }
  return (
    <Box sx={{
      maxWidth: '750px',
      margin: 'auto',
    }}
    >
      <ApplicationHeader heading={staticText.heading} description={staticText.pageDescription} />
      <SSO />
    </Box>
  );
};

export default OrgSecurity;

OrgSecurity.propTypes = {
  organization: shape({
    rolePermissions: arrayOf(string),
  }).isRequired,
};
