const FEATURES = {
  SCHEDULED_WEBHOOKS: 'scheduledWebhooks',
  EVENT_WEBHOOKS: 'eventWebhooks',
  LOG_DAYS: 'logDays',
  EXPORT_LOG_DAYS: 'exportLogDays',
  LOG_EXPORT: 'logExport',
  APPLICATIONS: 'applications',
  CHAT_SUPPORT: 'chatSupport',
  CONNECT_CONFIG: 'connectConfig',
  CONNECT_CONFIG_THEME: 'connectConfigTheme',
  CONNECT_CONFIG_ENGINE_FILTER: 'connectConfigEngineFilter',
  CONNECT_CONFIG_SELECT_BRANDS: 'connectConfigSelectBrands',
  CONNECT_CONFIG_SELECT_COUNTRIES: 'connectConfigSelectCountries',
  CONNECT_PLAYGROUND_ADVANCED_SETTINGS: 'connectPlaygroundAdvancedSettings',
  TEAMS: 'teams',
  TEAMS_ROLES: 'teamsRoles',
  CONNECT_INSIGHTS_DAYS: 'connectInsightsDays',
  CONNECT_FUNNEL: 'connectFunnel',
  BRAND_SELECT: 'brandSelect',
  SINGLE_SELECT: 'singleSelect',
  OVERVIEW_DAYS: 'overviewDays',
};

export default FEATURES;

