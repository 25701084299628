import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Applications from './Applications';
import { actions as applicationsActions } from '../../../../services/applications/reducers';
import { getOrgApps } from '../../utils';

function mapStateToProps(state) {
  const { applications, lockedApplications } = state.applications;
  const { selectedOrganization } = state.organizations;
  const orgApps = getOrgApps(applications, selectedOrganization);

  return {
    applications: orgApps,
    lockedApplications: lockedApplications[selectedOrganization],
  };
}

const mapDispatchToProps = (dispatch) => {
  const {
    selectApplication,
  } = applicationsActions;
  return {
    actions: bindActionCreators({
      selectApplication,
    },
    dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
