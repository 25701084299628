import React from 'react';
import { arrayOf, bool, func, object, oneOfType, shape, string } from 'prop-types';
import { TextField, useTheme } from '@mui/material';

import Dropdown from '../Dropdown';
import Frequency from '../Frequency';
import ResponseData from '../ResponseData';
import CardSelect from '../CardSelect';
import Events from '../Events';

const FormField = (props) => {
  const {
    children,
    handleChange,
    handleBlur,
    inputName,
    inputType,
    inputValues,
    inputPlaceholder,
    errorMessage,
    disabled,
  } = props;
  const theme = useTheme();
  const handleInputChange = (event) => {
    const {
      target: {
        name, value,
      },
    } = event;

    handleChange({
      key: name,
      value,
    });
  };

  switch (inputType) {
    case 'permissions':
      return (
        <ResponseData
          inputValues={inputValues || []}
          inputName={inputName}
          handleChange={handleChange}
        >
          {children}
        </ResponseData>
      );
    case 'events':
      return (
        <Events
          inputValues={inputValues || []}
          inputName={inputName}
          handleChange={handleChange}
          disabled={disabled}
        >
          {children}
        </Events>
      );
    case 'frequency':
      return (
        <Frequency
          inputValues={inputValues}
          inputName={inputName}
          handleChange={handleChange}
          disabled={disabled}
        >
          {children}
        </Frequency>
      );
    case 'dropdown':
      return (
        <Dropdown
          inputName={inputName}
          inputValue={inputValues}
          handleChange={handleChange}
          disabled={disabled}
        >
          {children}
        </Dropdown>
      );
    case 'cardSelect':
      return (
        <CardSelect
          inputName={inputName}
          inputValue={inputValues}
          handleChange={handleInputChange}
          disabled={disabled}
        >
          {children}
        </CardSelect>
      );
    case 'text':
      return (
        <React.Fragment>
          <TextField
            variant="outlined"
            error={Boolean(errorMessage)}
            name={inputName}
            onChange={handleInputChange}
            onBlur={() => handleBlur(inputName)}
            value={inputValues || ''}
            placeholder={inputPlaceholder || ''}
            hiddenLabel
            fullWidth
            sx={{
              fieldset: {
                border: `1px solid ${theme.palette.grey[200]}`,
              },
            }}
          />
          {errorMessage && <div className="error-message m-b-lg">{errorMessage}</div>}
        </React.Fragment>
      );
    default:
      return (
        <div>
          <input
            className={`input${errorMessage ? ' error-input' : ''}`}
            name={inputName}
            onChange={handleInputChange}
            onBlur={() => handleBlur(inputName)}
            type={inputType}
            value={inputValues || ''}
            placeholder={inputPlaceholder || ''}
            autoComplete="off"
          />
          {errorMessage && <div className="error-message m-b-lg">{errorMessage}</div>}
        </div>
      );
  }
};

export default FormField;

FormField.propTypes = {
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  children: arrayOf(shape({
    name: string,
    text: string,
    type: string,
    path: string,
    children: arrayOf(shape({
      name: string,
      text: string,
    })),
  })),
  inputName: string,
  inputType: string,
  inputValues: oneOfType([string, object, arrayOf(string)]),
  inputPlaceholder: string,
  errorMessage: string.isRequired,
  disabled: bool,
};

FormField.defaultProps = {
  children: [],
  inputName: '',
  inputType: '',
  inputValues: '',
  inputPlaceholder: '',
  disabled: false,
};
