import moment from 'moment-timezone';

const util = {};

util.commonChartOptions = {
  slotProps: {
    legend: {
      hidden: true,
    },
  },
  dateTickFormatter: timeInterval => (date, context) => {
    if (context.location === 'tick') {
      if (timeInterval === 1) {
        return moment.utc(date).format('h A');
      }
      return moment.utc(date).format('MMM D');
    } else if (timeInterval === 1) {
      return moment.utc(date).format('MMM D, YYYY h A');
    }
    return moment.utc(date).format('MMM D, YYYY');
  },
};

util.isTooltipDataValid = (tooltipData) => {
  if (
    !tooltipData
    || !tooltipData.identifier
    || !tooltipData.identifier.x
    || !tooltipData.identifier.y
  ) {
    return false;
  }
  return true;
};

export default util;
