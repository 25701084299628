import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import _ from 'lodash';
import { Box, Tooltip, useTheme } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { Checkbox, Chip } from '../../../../../../../components/';

const ResponseData = ({
  inputValues, children, inputName, handleChange,
}) => {
  const handleResponseChange = (event) => {
    const {
      target: {
        name, checked,
      },
    } = event;

    let values;
    if (!inputValues.length) {
      values = [];
    } else {
      values = [...inputValues];
    }

    if (checked) {
      values.push(name);
      handleChange({ key: inputName, value: values });
    } else {
      _.pull(values, name);
      handleChange({ key: inputName, value: values });
    }
  };

  const theme = useTheme();

  return (
    <div>
      {children.map(({
        text, path, name, tooltip, deprecated,
      }) => {
        if (deprecated && !inputValues.includes(name)) return null;
        return (
          <Box key={text} mb={3} display="flex" alignItems="center">
            <Checkbox
              wrapperClassName="flex-vertical-center m-r-med"
              inputName={name}
              key={text}
              text={text}
              checked={inputValues.includes(name)}
              handleChange={handleResponseChange}
              disabled={deprecated}
            />
            {path && <Chip text={path} />}
            {tooltip &&
              <Tooltip title={tooltip} arrow placement="top" sx={{ marginLeft: theme.spacing(0.5) }}>
                <InfoOutlined
                  htmlColor={theme.palette.text.disabled}
                  fontSize="inherit"
                />
              </Tooltip>
            }
          </Box>
        );
      })}
    </div>
  );
};

export default ResponseData;

ResponseData.propTypes = {
  children: arrayOf(shape({
    text: string,
    path: string,
  })).isRequired,
  inputName: string.isRequired,
  handleChange: func.isRequired,
  inputValues: arrayOf(string),
};

ResponseData.defaultProps = {
  inputValues: [],
};
