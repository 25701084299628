

const staticText = {
  heading: 'Overview',
  charts: {
    apiRequestsOverTimeChart: {
      title: 'API requests over time',
      linkOut: 'logs',
    },
    connectedVehiclesOverTimeChart: {
      title: 'Connected vehicles over time',
      linkOut: 'vehicles',
    },
    top5MakesByConnectedVehiclesChart: {
      title: 'Top 5 makes',
      linkOut: 'vehicles',
    },
  },
  connectFunnel: {
    title: 'Conversion rate',
    linkOut: 'connect/insights',
  },
  widgets: {
    connectedVehicles: {
      title: 'Connected vehicles',
      // tooltip: '',
      label: 'Total connections',
    },
    activeVehiclesWoWChange: {
      title: 'Active vehicles',
      // tooltip: '',
    },
    newConnectionsWoWChange: {
      title: 'Net new connections',
      // tooltip: '',
    },
  },
  freePlanLimitReachedBanner: {
    title: 'You’ve reached your plan’s vehicle limit. Upgrade to unlock higher limits, data insights, and advanced features.',
    upgradeFeatures: [
      {
        icon: 'car',
        label: 'Access up to 100 vehicles',
      },
      {
        icon: 'calendar',
        label: '7 days of log data',
      },
      {
        icon: 'arrowUp',
        label: 'Advanced insights',
      },
    ],
    upgradeButton: {
      text: 'Upgrade',
      path: '/team/billing',
    },
  },
};

export default staticText;
