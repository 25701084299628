const staticText = {
  heading: 'Team security',
  pageDescription: "Set up and manage your team's security in the Smartcar Dashboard.",
  noPermissionToAccessPage: "You don't have the permissions to access the team security page. Please contact a team owner.",
  urlGenerationError: 'An error occurred while generating the admin portal URL. Please try again.',
  sso: {
    title: 'Single sign-on (SSO)',
    description: 'Require team members to authenticate to the Smartcar dashboard with an identity provider. If enabled, all users with your email domain(s) will be required to sign in via SSO.',
    state: {
      enabled: { // sso is enabled and active
        chip: 'Enabled',
        chipVariant: 'success',
        cta: 'Disable SSO',
        ctaVariant: 'outlined',
      },
      pendingDomainValidation: { // org domain is not yet verified/validated
        chip: 'Pending',
        chipVariant: 'primary',
        cta: 'Continue setup',
        ctaVariant: 'contained',
        feedback: 'SSO pending - click "Continue setup" to enter additional information.',
        feedbackType: 'info',
      },
      pendingSsoSetup: { // sso is enabled, org domain is verified, but sso needs to be set up
        chip: 'Pending',
        chipVariant: 'primary',
        cta: 'Continue setup',
        ctaVariant: 'contained',
        feedback: 'SSO pending - click "Continue setup" to enter additional information.',
        feedbackType: 'info',
      },
      unavailable: { // org does not have the 'sso' add-on
        chip: 'Disabled',
        chipVariant: 'disabled',
        cta: 'Contact us',
        ctaVariant: 'contained',
        feedback: 'This is a custom plan feature - |+|function@@contact us|+| to enable SSO.',
        feedbackType: 'alert',
      },
      disabled: { // org has the 'sso' add-on, but it has not yet been configured or is enabled
        chip: 'Disabled',
        chipVariant: 'disabled',
        cta: 'Enable SSO',
        ctaVariant: 'contained',
      },
    },
  },
};

export default staticText;
