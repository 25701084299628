import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../../services/api/api';
import { actions, types, selectors } from './reducers';
import { actions as errorActions } from '../../../../services/errorReporter/reducers';

export function* fetchConnectFunnel(action) {
  try {
    const { meta: applicationId } = action;
    const { data } = yield call(
      api.fetchConnectFunnel,
      applicationId,
    );

    yield put(actions.fetchConnectFunnelSuccess(data));
  } catch (error) {
    yield put(actions.fetchConnectFunnelFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchApiRequestsOverTime(action) {
  try {
    const filter = yield select(selectors.getApiRequestsOverTimeFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchApiRequestsOverTime,
      applicationId,
      filter.days,
    );

    yield put(actions.fetchApiRequestsOverTimeSuccess(data));

    if (data.length > 0) {
      yield put(actions.setApiRequestCount(data[data.length - 1].success));
    }
  } catch (error) {
    yield put(actions.fetchApiRequestsOverTimeFailure(error));
    yield put(actions.setApiRequestCount(0));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchConnectedVehiclesOverTime(action) {
  try {
    const filter = yield select(selectors.getConnectedVehiclesOverTimeFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchConnectedVehiclesOverTime,
      applicationId,
      filter.days,
    );

    yield put(actions.fetchConnectedVehiclesOverTimeSuccess(data));
  } catch (error) {
    yield put(actions.fetchConnectedVehiclesOverTimeFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchTop5MakesByConnectedVehicles(action) {
  try {
    const filter = yield select(selectors.getTop5MakesByConnectedVehiclesFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchTop5MakesByConnectedVehicles,
      applicationId,
      filter.days,
    );

    yield put(actions.fetchTop5MakesByConnectedVehiclesSuccess(data));
  } catch (error) {
    yield put(actions.fetchTop5MakesByConnectedVehiclesFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchWidgets(action) {
  try {
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchWidgets,
      applicationId,
    );

    yield put(actions.fetchWidgetsSuccess(data));
  } catch (error) {
    yield put(actions.fetchWidgetsFailure(error));
    yield put(errorActions.reportError(error));
  }
}


export default function* rootSaga() {
  yield takeLatest(types.FETCH_CONNECT_FUNNEL, fetchConnectFunnel);
  yield takeLatest(types.FETCH_API_REQUESTS_OVER_TIME, fetchApiRequestsOverTime);
  yield takeLatest(types.FETCH_CONNECTED_VEHICLES_OVER_TIME, fetchConnectedVehiclesOverTime);
  yield takeLatest(
    types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES,
    fetchTop5MakesByConnectedVehicles,
  );
  yield takeLatest(types.FETCH_WIDGETS, fetchWidgets);
}
