import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, MenuItem, Select, TextField } from '@mui/material';

import FormFieldLabel from '../FormFieldLabel';
import { SelectIcon, FeatureGateBanner } from '../../../../../../components';
import { Switch } from '../../../../../../components/Form/components';
import staticText from '../../../../../../localization/Application/connect-playground';

const AdvancedSettings = ({
  urlValues,
  applicationMakes,
  handleChange,
  canWriteBilling,
  advancedIsGated,
  supportedCountries,
}) => {
  const advancedText = staticText.form.advanced;

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    if (name === 'singleSelect') {
      handleChange({ param: name, value: event.target.checked });
      return;
    }
    handleChange({ param: name, value });
  };

  return (
    <Box display="flex" flexDirection="column" maxWidth="500px">
      <FormFieldLabel
        label={advancedText.state.label}
        description={advancedText.state.description}
      >
        <TextField
          name="state"
          defaultValue={urlValues.state}
          placeholder={advancedText.state.placeholder}
          onChange={handleInputChange}
          inputProps={{
            'aria-label': advancedText.state.label,
          }}
        />
      </FormFieldLabel>
      <FormFieldLabel
        label={advancedText.user.label}
        description={advancedText.user.description}
      >
        <TextField
          name="user"
          defaultValue={urlValues.user}
          placeholder={advancedText.user.placeholder}
          onChange={handleInputChange}
          inputProps={{
            'aria-label': advancedText.user.label,
          }}
        />
      </FormFieldLabel>
      {advancedIsGated && (
        <FeatureGateBanner
          canWriteBilling={canWriteBilling}
          upgradeText={advancedText.featureGated.upgrade}
          ownerUpgradeText={advancedText.featureGated.contactOwnersToUpgrade}
        />)}
      <FormFieldLabel
        label={advancedText.make.label}
        description={advancedText.make.description}
        disabled={advancedIsGated}
        tooltip={advancedText.featureGated.tooltip}
      >
        <Select
          id="make-select"
          name="make"
          value={urlValues.make || 'none'}
          onChange={handleInputChange}
          IconComponent={SelectIcon}
          disabled={advancedIsGated}
          inputProps={{
            'aria-label': advancedText.make.label,
          }}
          MenuProps={{ sx: theme => theme.muiSelectMenuProps }}
        >
          <MenuItem value="none">{advancedText.make.placeholder}</MenuItem>
          {applicationMakes.map(({ make, displayName }) => {
            return (
              <MenuItem key={make} value={make}>
                {displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormFieldLabel>
      <FormFieldLabel
        label={advancedText.country.label}
        description={advancedText.country.description}
        disabled={advancedIsGated}
        tooltip={advancedText.featureGated.tooltip}
      >
        <Select
          id="country-select"
          name="country"
          value={urlValues.country || 'none'}
          onChange={handleInputChange}
          IconComponent={SelectIcon}
          disabled={advancedIsGated}
          inputProps={{
            'aria-label': advancedText.country.label,
          }}
          MenuProps={{ sx: theme => theme.muiSelectMenuProps }}
        >
          <MenuItem value="none">{advancedText.country.placeholder}</MenuItem>
          {supportedCountries.map((country) => {
            const { displayName } = country;
            return (
              <MenuItem key={country} value={country}>
                {displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormFieldLabel>
      <FormFieldLabel
        label={advancedText.singleSelect.label}
        description={advancedText.singleSelect.description}
        disabled={advancedIsGated}
        tooltip={advancedText.featureGated.tooltip}
      >
        <Switch
          name="singleSelect"
          label={advancedText.singleSelect.toggleLabel}
          checked={urlValues.singleSelect}
          onChange={handleInputChange}
          disabled={advancedIsGated}
        />
      </FormFieldLabel>
    </Box >
  );
};

export default AdvancedSettings;

AdvancedSettings.propTypes = {
  urlValues: shape({
    clientId: string.isRequired,
    state: string,
    user: string,
    make: string,
    singleSelect: bool,
  }).isRequired,
  applicationMakes: arrayOf(shape({
    make: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  handleChange: func.isRequired,
  canWriteBilling: bool.isRequired,
  advancedIsGated: bool.isRequired,
  supportedCountries: arrayOf(string).isRequired,
};
