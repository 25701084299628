import { Chip, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  textOverflow: 'ellipsis',
  maxWidth: '160px',
}));

export const StyledSelect = styled(Select)(() => ({
  '.MuiSelect-select#app-select': {
    // override and allow wrap
    whiteSpace: 'normal',
  },
}));

export const MenuProps = {
  disableScrollLock: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      minWidth: '200px',
      maxWidth: '552px',
      maxHeight: '500px',
      overflow: 'auto',
    },
  },
};
