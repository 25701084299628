
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../services/auth/reducers';
import TermsOfServiceModal from './TermsOfServiceModal';

function mapStateToProps(state) {
  return {
    ...state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  const { updateUserRequest } = actions;

  return {
    actions: bindActionCreators({ updateUserRequest }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceModal);
