import React, { useState } from 'react';
import { objectOf, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { AppSelectorList } from './components';
import { Modal, RegisterApplication, UpsellModal } from '../../../../../../components';
import { Selector, AppName, AppNameButton, SelectorArrow, AppListContainer, AppLogoWrapper } from './styles';
import { gatedFeatureData } from '../../../../../../services/featureGate';
import FEATURES from '../../../../../../services/featureGate/features';
import application from '../../../../../../localization/Application/application';
import useToggleMenu from '../../useToggleMenu';

const AppSelector = ({
  organization,
  applicationId,
  applicationName,
  applicationLogoUrl,
  orgApps,
  pathname,
}) => {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const {
    menuOpen,
    closeMenu,
    toggleMenu,
    menuRef,
  } = useToggleMenu({
    label: 'app selector',
    text: applicationName,
  });

  const maxAppCountReached = Object.keys(orgApps).length >=
    gatedFeatureData(FEATURES.APPLICATIONS, organization.featureSetId);

  const toggleModal = (withTracking = true) => {
    const segmentReport = maxAppCountReached ? application.upgradeModal : application.modal;
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], segmentReport);
    }

    if (menuOpen) {
      closeMenu(false);
    }
    setModalOpen(!modalOpen);
  };

  const modalConfig = {
    title: application.modal.title,
    content: (
      <RegisterApplication
        toggleModal={toggleModal}
        applicationNames={Object.values(orgApps).map(app => app.name)}
      />
    ),
  };

  const getAppLogo = (appId) => {
    const app = orgApps[appId];
    return (
      <AppLogoWrapper hasLogo={Boolean(app.logoUrl)}>
        {app.logoUrl ?
          <img src={app.logoUrl} alt={app.name} /> :
          <span>{app.name[0].toUpperCase()}</span>}
      </AppLogoWrapper>
    );
  };

  return Object.keys(orgApps).length > 1 ? (
    <div>
      {modalOpen && !maxAppCountReached &&
        <Modal
          {...modalConfig}
          toggleModal={toggleModal}
        />
      }
      {modalOpen && maxAppCountReached &&
        <UpsellModal
          toggleModal={toggleModal}
          feature={FEATURES.APPLICATIONS}
        />
      }
      <Selector
        ref={menuRef}
        onClick={e => e.stopPropagation()}
        open={menuOpen}
      >
        <AppNameButton id="app-selector-button" onClick={toggleMenu}>
          <Box display="flex" alignItems="center">
            <Box flexBasis={1}>{getAppLogo(applicationId)}</Box>
            <Typography variant="bold">{applicationName}</Typography>
          </Box>
          <SelectorArrow listOpen={menuOpen}>
            <ExpandMoreRoundedIcon htmlColor={theme.palette.primary.main} />
          </SelectorArrow>
        </AppNameButton>
        {menuOpen &&
          <AppListContainer>
            <AppSelectorList
              pathname={pathname}
              applications={orgApps}
              applicationName={applicationName}
              applicationLogoUrl={applicationLogoUrl}
              applicationId={applicationId}
              toggleModal={toggleModal}
              closeList={closeMenu}
              organizationId={organization.id}
              getAppLogo={getAppLogo}
              showHeader
              dashboardRole={organization.dashboardRole}
            />
          </AppListContainer>
        }
      </Selector>
    </div>
  ) : (
    <AppName>
      <Box display="flex" alignItems="center">
        {getAppLogo(applicationId)}
        <Typography variant="bold">{applicationName}</Typography>
      </Box>
    </AppName>
  );
};

export default AppSelector;

AppSelector.propTypes = {
  applicationId: string.isRequired,
  applicationName: string.isRequired,
  applicationLogoUrl: string,
  orgApps: objectOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  organization: shape({
    id: string.isRequired,
    featureSetId: string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pathname: string.isRequired,
};

AppSelector.defaultProps = {
  applicationLogoUrl: '',
};
