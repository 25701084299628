import { styled, Button as MuiButton } from '@mui/material';

export const Wrapper = styled('div')({
  maxWidth: '460px',
  margin: '0 auto',
});

export const FormFields = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& > *': {
    marginBottom: `${theme.spacing(2)} !important`,
  },
}));

export const FieldSet = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Image = styled('img')(({ theme }) => ({
  width: '288px',
  margin: theme.spacing(6, 0),
}));

export const Content = styled('div')(({ theme }) => ({
  borderRadius: theme.border.radius2,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(6),
}));

export const Container = styled('div')(({ theme }) => ({
  maxWidth: '1000px',
  margin: `${theme.spacing(6)} auto`,
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  gap: theme.spacing(3, 4),
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const Button = styled(MuiButton)(() => ({
  width: '300px',
  height: '50px',
}));
