import { connect } from 'react-redux';

import Countries from './Countries';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
  };
}

export default connect(mapStateToProps)(Countries);
