import moment from 'moment-timezone';

import { gatedFeatureData } from '../../services/featureGate';

export const unallowedBrandLogos = ['Audi', 'Volkswagen'];

export const getOrgApps = (applications, organizationId) => {
  return Object.keys(applications).reduce((acc, appId) => {
    if (applications[appId].organizationId === organizationId) {
      acc[appId] = applications[appId];
    }
    return acc;
  }, {});
};

export const capitalizeFirstLetter = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

const DEFAULT_LOG_RANGE = 2;

export const getLogRestrictions = (featureSetId, logType) => {
  const logDays = Number(gatedFeatureData(logType, featureSetId)) || 1;
  const maxDate = moment.utc().startOf('day').toISOString();
  const minDate = moment.utc(maxDate).subtract(logDays, 'days').toISOString();
  let defaultStartDate = moment.utc(maxDate).subtract(DEFAULT_LOG_RANGE, 'days').toISOString();

  if (moment(defaultStartDate).isBefore(minDate)) {
    defaultStartDate = minDate;
  }

  const hasExportAccess = !gatedFeatureData('logExport', featureSetId);

  return {
    logDays,
    maxDate,
    minDate,
    defaultStartDate,
    hasExportAccess,
  };
};

export const alphabetize = (a, b) => {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA === lowerB) return 0;
  return lowerA < lowerB ? -1 : 1;
};


// Sets most recent orgId/AppId in local storage for a given dashboardUserId
// This will be used to load the most recent org/app in the dashboard
export const updateLocalStorageWithMostRecentApp = ({
  selectedOrganization,
  applicationId,
  userContext,
}) => {
  const existingRecentApp = localStorage.getItem('recentApp');

  const recentApp = {
    ...(existingRecentApp && JSON.parse(existingRecentApp)),
    [userContext.dashboardUserId]: {
      orgId: selectedOrganization,
      appId: applicationId,
    },
  };
  localStorage.setItem('recentApp', JSON.stringify(recentApp));
};
