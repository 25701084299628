import { styled } from '@mui/material/styles';
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow as MuiTableRow,
  Typography,
} from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';

import staticText from '../../../../../../localization/Application/Billing/planComparisonTable';

export const BodyCell = styled(MuiTableCell, {
  shouldForwardProp: prop => prop !== 'highlighted' && prop !== 'lastRow',
})(({ theme, highlighted, lastRow }) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: 500,
  borderBottom: 'none',
  lineHeight: '100%',
  padding: theme.spacing(3, 0),
  alignContent: 'center',
  position: 'relative',

  '&:first-child': {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  '&:nth-child(3)': {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
  '&:not(:first-child)': {
    padding: theme.spacing(3),
  },

  ...(highlighted && {
    zIndex: 1,
    borderLeft: `1px solid ${theme.palette.primary.main} !important`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#00819D0A',

    ...(lastRow && {
      borderBottom: `1px solid ${theme.palette.primary.main} !important`,
      borderRadius: '0 0 8px 8px',
    }),
  }),
}));

export const Chip = styled('div', {
  shouldForwardProp: prop => prop !== 'chip',
})(({ theme, chip }) => ({
  borderRadius: '4px',
  fontSize: '0.75rem',
  padding: theme.spacing(0.25, 1.25),
  alignContent: 'center',
  lineHeight: 'normal',
  width: 'fit-content',

  ...(chip === staticText.chips.current && {
    border: `1px solid ${theme.palette.text.disabled}`,
  }),
  ...(chip === staticText.chips.recommended && {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  }),
}));

export const HeaderCell = styled(MuiTableCell, {
  shouldForwardProp: prop => prop !== 'highlighted',
})(({ theme, highlighted }) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: 500,
  color: theme.palette.text.primary,
  borderBottom: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'space-between',

  '&:first-child': {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  '&:nth-child(3)': {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
  '&:not(:first-child)': {
    padding: theme.spacing(2),
    marginTop: '16px',
  },
  ...(highlighted && {
    zIndex: 1,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main} !important`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#00819D0A',
    borderRadius: '8px 8px 0 0',
  }),
}));


export const HeaderCellTitleLine = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  [theme.breakpoints.down('xl')]: {
    display: 'inline-block',
  },
}));

export const HeaderCellPriceLine = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  [theme.breakpoints.down('xl')]: {
    display: 'inline-block',
  },
}));

export const HeaderCellAddendum = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: 500,
  [theme.breakpoints.down('iPadPro')]: {
    fontSize: '12px',
  },
}));

export const TableWrapper = styled(TableContainer)(() => ({
  border: 'none',
  boxShadow: 'none',
}));

export const Table = styled(MuiTable)(() => ({
  borderBottom: 'none',
}));

export const TableRow = styled(MuiTableRow, {
  shouldForwardProp: prop => prop !== 'lastRow',
})(({ theme, lastRow }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  '@media (max-width: 1410px)': {
    gridColumnGap: theme.spacing(0),
  },
  '@media (min-width: 1411px)': {
    gridColumnGap: theme.spacing(2),
  },

  // Pseudo-border in place of a border to avoid breaking the column border
  position: 'relative',
  ...(!lastRow && {
    '::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '1px',
      backgroundColor: theme.palette.grey[200],
    },
  }),
}));

export const TableText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: 500,
}));

// Icons
export const CheckCircleIcon = styled(CheckCircle)(({ theme }) => ({
  width: 20,
  fill: theme.palette.primary.main,
}));

export const XCircleIcon = styled(Cancel)(({ theme }) => ({
  width: 20,
  fill: theme.palette.grey[500],
}));
