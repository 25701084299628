import { Typography, styled } from '@mui/material';

export const AppsList = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  border: theme.border.default,
}));

export const Headers = styled('div')(({ theme }) => ({
  display: 'flex',

  '& > *': {
    width: '40%',
    padding: theme.spacing(2, 4),
    margin: '0',
  },

  '& > *:first-of-type': {
    paddingLeft: theme.spacing(4),
  },

  '& > *:last-of-type': {
    width: '20%',
  },
}));

export const Rows = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingLeft: '0',
  margin: '0',
  width: '100%',

  '& li': {
    borderTop: `1px solid ${theme.palette.grey[100]}`,

    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      flexGrow: 1,
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export const Row = styled('li')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const App = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > *': {
    width: '40%',
    padding: theme.spacing(2, 4),
    margin: '0',
  },

  '& > *:last-of-type': {
    width: '20%',
  },
}));

export const AppName = styled(Typography)(() => ({
  maxWidth: '300px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));

export const LockedApp = styled('button')(({ theme }) => ({
  color: theme.palette.text.disabled,
  border: 'none',
  padding: '0',
  margin: '0',
  backgroundColor: 'transparent',
  width: '100%',
  fontSize: 'inherit',
  cursor: 'pointer',
  textAlign: 'left',
}));
