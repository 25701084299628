/* istanbul ignore next */
const getFilterStatusAndTitle = (filter, key, title) => {
  const keys = Object.keys(filter[key]);
  const numSelected = keys.reduce((acc, text) => {
    let currentTotal = acc;
    if (filter[key][text]) {
      currentTotal += 1;
    }
    return currentTotal;
  }, 0);

  const isFilterActive = keys.length !== numSelected && numSelected !== 0;

  return {
    isFilterActive,
    menuItemWrapperTitle: `${isFilterActive ? `(${numSelected}) ` : ''}${title}`,
    alternativeItemWrapperTitle: `${isFilterActive ? `${numSelected} selected ` : title}`,
  };
};

export default getFilterStatusAndTitle;
