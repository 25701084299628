import React from 'react';
import { arrayOf, shape, number, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { LineChart, useAxisTooltip } from '@mui/x-charts-pro';
import { useTheme } from '@mui/material/styles';


import text from '../../../../../../localization/Application/Overview/charts';
import star from '../../../../../../assets/images/free.png';

import { EmptyStateContainer, EmptyStateImage } from '../../../../../../global-styles/components';
import { ColorBox, FormattedTypography, TooltipWrapper } from '../styles';

import chartUtils from '../util/chartUtils';

const staticText = text.connectedVehiclesOverTimeChart;

const getCustomTooltipComponent = (dataset, theme) => () => {
  const tooltipData = useAxisTooltip();

  if (!chartUtils.isTooltipDataValid(tooltipData)) {
    return null;
  }

  return (
    <TooltipWrapper>
      <FormattedTypography
        fontFamily={theme.typography.bold.fontFamily}
        marginBottom={0.5}
      >
        {staticText.yAxisLabel}
      </FormattedTypography>
      <FormattedTypography marginBottom={2}>{tooltipData.axisFormattedValue}</FormattedTypography>
      <Box display="flex" alignItems="center">
        <ColorBox color="green" />
        <FormattedTypography
          flexGrow={1}
          fontFamily={theme.typography.bold.fontFamily}
        >
          {tooltipData.seriesItems[0].formattedLabel}
        </FormattedTypography>
        <FormattedTypography>{tooltipData.seriesItems[0].formattedValue}</FormattedTypography>
      </Box>
    </TooltipWrapper>
  );
};

const ConnectedVehiclesOverTimeChart = ({ dataset, timeInterval }) => {
  const theme = useTheme();

  const emptyState = (
    <EmptyStateContainer paperBackground condensed>
      <EmptyStateImage
        src={star}
        alt="illustration of a shooting star"
        sx={{ mb: theme.spacing(3) }}
      />
      <Typography variant="subtitle2" sx={{ mb: theme.spacing(1), color: theme.palette.text.primary }}>
        {staticText.emptyStateTitle}
      </Typography>
      <Typography sx={{ fontSize: theme.spacing(1.5), mb: theme.spacing(3) }}>
        {staticText.emptyStateSubtitle}
      </Typography>
    </EmptyStateContainer>
  );

  if (!dataset.length) {
    return emptyState;
  }

  return (
    <LineChart
      dataset={dataset}
      xAxis={[{
        scaleType: 'point',
        dataKey: 'day',
        label: staticText.xAxisLabel(timeInterval),
        valueFormatter: chartUtils.commonChartOptions.dateTickFormatter(timeInterval),
        // todo: only add this if screen width is less than a certain size
        ...(timeInterval === 1 ? { tickInterval: (val, i) => i % 2 === 0 } : {}),
      }]}
      yAxis={[{ tickNumber: 5, label: staticText.yAxisLabel }]}
      series={[{
        dataKey: 'connections',
        area: true,
        showMark: false,
        color: theme.palette.charts.green.stroke,
        label: staticText.yAxisLabel,
      }]}
      axisHighlight={{ x: 'none' }}
      grid={{ horizontal: true }}
      slotProps={chartUtils.commonChartOptions.slotProps}
      slots={{
        axisContent: getCustomTooltipComponent(dataset, theme),
      }}
      margin={{
        right: 16, left: 88, top: 24,
      }}
      height={330}
      sx={{
        '& .MuiAreaElement-root': {
          opacity: 0.2,
        },
      }}
    />
  );
};

export default ConnectedVehiclesOverTimeChart;

ConnectedVehiclesOverTimeChart.propTypes = {
  dataset: arrayOf(shape({
    day: string,
    connections: number,
  })).isRequired,
  timeInterval: number.isRequired,
};
