import { Box, IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Nav = styled('nav', {
  shouldForwardProp: prop => prop !== 'expanded',
})(({ theme, expanded }) => ({
  backgroundColor: theme.palette.grey[150],
  borderRight: theme.border.default,
  height: '100vh',
  zIndex: theme.zIndex.appBar + 1,
  position: 'sticky',
  top: 0,
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'width .25s ease, height 0.1s ease',
  width: theme.width.sidebar.collapsed,

  ul: {
    listStyle: 'none',
    margin: '0',
    padding: '0',

    ...(expanded && {
      // so when the sidebar expands, the contents past the width of the sidebar during the
      // opening animation don't show
      overflowX: 'hidden',
    }),
  },

  ...(expanded ? {
    width: theme.width.sidebar.expanded,
  } : {
    '.smartcar-icon': {
      display: 'block',
    },
    '.expand-icon': {
      display: 'none',
    },
    '&:hover': {
      '.smartcar-icon': {
        display: 'none',
      },
      '.expand-icon': {
        display: 'block',
      },
    },
  }),
}));

export const NavHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),

  img: {
    height: '24px',
  },
}));

export const NavItem = styled('li', {
  shouldForwardProp: prop => prop !== 'error',
})(({
  theme, error,
}) => ({
  position: 'relative',
  marginBottom: theme.spacing(0.5),

  'a, button': {
    padding: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: theme.border.radius,

    p: {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1),
      marginTop: '3px',
    },

    'img, svg': {
      pointerEvents: 'none',
    },

    '.hover-text': {
      display: 'none',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[300],

      '.hover-text': {
        display: 'block',
      },
    },
  },

  ...(error && {
    p: {
      position: 'relative',

      '&:after': {
        position: 'absolute',
        content: '""',
        backgroundColor: theme.palette.error.main,
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        top: '-1px',
        right: '-14px',
      },
    },
  }),

  '&.back-link': {
    p: {
      fontFamily: theme.typography.bold.fontFamily,
      color: theme.palette.primary.main,
    },
  },

  '&.current': {
    '> a, button': {
      backgroundColor: theme.palette.grey[300],

      p: {
        fontFamily: theme.typography.bold.fontFamily,
      },
    },
  },

  '&.MuiButtonBase-root.MuiMenuItem-root': {
    width: 'auto',
    margin: theme.spacing(1, 0),
    padding: '0px',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    a: {
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),

      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      p: {
        margin: '0px',
      },
    },
  },
}));

export const SidebarToggleButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.border.radius,

  '.hover-text': {
    display: 'none',
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[300],

    '.hover-text': {
      display: 'block',
    },
  },
}));


// button to open the sub nav list when the sidebar is expanded
export const ExpandedMenuSubNavButton = styled('button', {
  shouldForwardProp: prop => prop !== 'expanded',
})(({ expanded }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  svg: {
    transition: 'transform 0.25s ease',

    ...(expanded && {
      transform: 'rotate(180deg)',
    }),
  },
}));

// sub nav list when the sidebar is expanded
export const ExpandedMenuSubNav = styled(Box)(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.grey[300]}`,
  marginLeft: '19px',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),

  li: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    p: {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

// sub nav popover when the sidebar is not expanded
export const CollapsedMenuSubNav = styled(Menu)(({ theme }) => ({
  '.MuiPopover-paper': {
    marginLeft: '4px',
    padding: theme.spacing(2),
  },
  '.MuiList-root': {
    width: '140px',
    padding: '0',

    'span.MuiTypography-root': {
      display: 'inline-block',
      marginBottom: theme.spacing(1),
      fontFamily: theme.typography.bold.fontFamily,
    },

    li: {
      '&.current a': {
        backgroundColor: theme.palette.grey[150],
      },

      'a:hover': {
        backgroundColor: theme.palette.grey[150],
      },
    },
  },
}));

export const HoverText = styled('div')(({ theme }) => ({
  ...theme.typography.caption,
  whiteSpace: 'nowrap',
  zIndex: theme.zIndex.tooltip,
  position: 'absolute',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  padding: theme.spacing(1),
  borderRadius: theme.border.radius,
  left: 'calc(100% + 4px)',
  top: '4px',
}));
