import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Overview from './Overview';
import { actions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';

function mapStateToProps(state) {
  return {
    ...state.overview,
    showOnboarding: state.onboarding.showOnboarding,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchApiRequestsOverTime,
    fetchConnectedVehiclesOverTime,
    fetchTop5MakesByConnectedVehicles,
    fetchWidgets,
    setFilter,
  } = actions;
  const { startOnboardingPolling } = onboardingActions;

  return {
    actions: bindActionCreators(
      {
        fetchApiRequestsOverTime,
        fetchConnectedVehiclesOverTime,
        fetchTop5MakesByConnectedVehicles,
        fetchWidgets,
        setFilter,
        startOnboardingPolling,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
