import { styled } from '@mui/material';

export const PercentBar = styled('div')(({ percentage, previousStagePercent, theme }) => ({
  padding: 0,
  position: 'relative',
  backgroundColor: theme.palette.charts.blue.background,
  alignSelf: 'end',
  height: `calc(200px * ${previousStagePercent})`,
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: `calc(${percentage} * 200px)`,
    backgroundColor: theme.palette.charts.blue.fill,
    transition: 'height 0.5s ease-in-out',
    borderRadius: percentage === previousStagePercent ? theme.spacing(1) : '0px 0px 8px 8px',
    borderTop: `1px solid ${theme.palette.charts.blue.stroke}`,
  },
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.charts.blue.stroke}`,
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  fontSize: theme.spacing(1.5),
}));

export const Overlay = styled('div')(({ theme }) => ({
  gridRow: 1,
  gridColumn: 1,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
}));
