import React, { useState } from 'react';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material';

import { PermissionGate, SelectIcon } from '../../../../../../../../components';
import { Drawer } from '..';
import { EndpointSignalGroup } from './components';
import { endpointTypes } from '../../../../utils/endpointDisplayMap';
import tripOptions from '../../utils/tripOptions';
import useShowLatencyData from '../../../../utils/useShowLatencyData';
import staticText from '../../../../../../../../localization/Application/Simulator/simulator';
import { Wrapper, LatencyDrawerButton, Select, Tooltip, TripDataContainer, TripDataColumn } from './styles';

const TripData = ({
  selectedVehicle,
  tripData,
  latency,
  handleLatencyChange,
  unitSystem,
  setUnitSystem,
}) => {
  const theme = useTheme();
  const { unitSystems, tripLatencies } = tripOptions;
  const vehicleCapabilities = selectedVehicle.endpointsWithLatency.map(endpoint => endpoint.path);

  const showLatencyData = useShowLatencyData(selectedVehicle);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleUnitSystemChange = (e) => {
    setUnitSystem(e.target.value);
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[simulator] change display unit system: ${e.target.value}`,
    });
  };

  const showEndpoint = (endpoint) => {
    return tripData.endpoints[endpoint] && vehicleCapabilities.includes(endpoint);
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
        <Typography mb={1} variant="h2" sx={{ color: theme.palette.common.black }}>{staticText.endpointsHeading}</Typography>

        <PermissionGate dashboardPermission="write_simulator">
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <FormControl variant="outlined">
                <InputLabel id="trip-unit-system-select-label" shrink="true">{staticText.tripOptions.unitSystemDropdown.label}</InputLabel>
                <Select
                  name="unitSystem"
                  value={unitSystem}
                  id="trip-unit-system-select"
                  label={staticText.tripOptions.unitSystemDropdown.label}
                  labelId="trip-unit-system-select-label"
                  IconComponent={SelectIcon}
                  onChange={handleUnitSystemChange}
                  variant="outlined"
                  MenuProps={{ sx: theme.muiSelectMenuProps }}
                >
                  {unitSystems.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Tooltip
                title={staticText.tripOptions.unitSystemDropdown.tooltip}
                arrow
                placement="top"
              >
                <InfoOutlined
                  htmlColor={theme.palette.text.disabled}
                  fontSize="inherit"
                />
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={1}>
              <FormControl variant="outlined">
                <InputLabel id="trip-latency-select-label" shrink="true">{staticText.tripOptions.responseLatencyDropdown.label}</InputLabel>
                <Select
                  name="tripLatency"
                  value={latency}
                  id="trip-latency-select"
                  label={staticText.tripOptions.responseLatencyDropdown.label}
                  labelId="trip-latency-select-label"
                  IconComponent={SelectIcon}
                  onChange={handleLatencyChange}
                  variant="outlined"
                  MenuProps={{ sx: theme.muiSelectMenuProps }}
                >
                  {tripLatencies.map(({ name, code }) => (
                    <MenuItem key={code} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip
                title={staticText.tripOptions.responseLatencyDropdown.tooltip}
                arrow
                placement="top"
              >
                <InfoOutlined
                  htmlColor={theme.palette.text.disabled}
                  fontSize="inherit"
                />
              </Tooltip>
            </Box>
          </Box>
        </PermissionGate>
      </Box>
      {showLatencyData && (
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <LatencyDrawerButton
            id="latency-drawer-btn"
            onClick={() => setDrawerOpen(true)}
          >
            <Typography variant="caption" color="primary">{staticText.latencyDrawerButton}</Typography>
          </LatencyDrawerButton>
          <Drawer
            type="latencies"
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            additionalProps={{ selectedVehicle }}
          />
        </Box>
      )}
      <TripDataContainer>
        {/* First column: renders battery or fuel/engine, or if not available, odometer */}
        <TripDataColumn>
          {(!showEndpoint(endpointTypes.BATTERY) && !showEndpoint(endpointTypes.FUEL))
            && showEndpoint(endpointTypes.ODOMETER) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.ODOMETER}
              endpointSignals={tripData.endpoints[endpointTypes.ODOMETER]}
              unitSystem={unitSystem}
            />
          ) }
          {showEndpoint(endpointTypes.BATTERY) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.BATTERY}
              endpointSignals={tripData.endpoints[endpointTypes.BATTERY]}
              unitSystem={unitSystem}
            />
          )}
          {showEndpoint(endpointTypes.BATTERY_CAPACITY) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.BATTERY_CAPACITY}
              endpointSignals={tripData.endpoints[endpointTypes.BATTERY_CAPACITY]}
              unitSystem={unitSystem}
            />
          )}
          {showEndpoint(endpointTypes.CHARGE) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.CHARGE}
              endpointSignals={tripData.endpoints[endpointTypes.CHARGE]}
              unitSystem={unitSystem}
            />
          )}
          {showEndpoint(endpointTypes.FUEL) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.FUEL}
              endpointSignals={tripData.endpoints[endpointTypes.FUEL]}
              unitSystem={unitSystem}
            />
          )}
          {showEndpoint(endpointTypes.ENGINE_OIL) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.ENGINE_OIL}
              endpointSignals={tripData.endpoints[endpointTypes.ENGINE_OIL]}
              unitSystem={unitSystem}
            />
          )}
        </TripDataColumn>
        {/* Second column: show odometer here if fuel/battery are shown in first column */}
        <TripDataColumn>
          {showEndpoint(endpointTypes.CHARGE_LIMIT) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.CHARGE_LIMIT}
              endpointSignals={tripData.endpoints[endpointTypes.CHARGE_LIMIT]}
              unitSystem={unitSystem}
            />
          )}
          {(showEndpoint(endpointTypes.BATTERY) || showEndpoint(endpointTypes.FUEL))
            && showEndpoint(endpointTypes.ODOMETER) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.ODOMETER}
              endpointSignals={tripData.endpoints[endpointTypes.ODOMETER]}
              unitSystem={unitSystem}
            />
          )}
          {/* moves location to third column if tires are unavailable */}
          {showEndpoint(endpointTypes.TIRES) && showEndpoint(endpointTypes.LOCATION) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.LOCATION}
              endpointSignals={tripData.endpoints[endpointTypes.LOCATION]}
              unitSystem={unitSystem}
            />
          )}
        </TripDataColumn>
        {/* Third column: renders tires or location (or nothing) */}
        <TripDataColumn>
          {showEndpoint(endpointTypes.TIRES) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.TIRES}
              endpointSignals={tripData.endpoints[endpointTypes.TIRES]}
              unitSystem={unitSystem}
            />
          )}
          {!showEndpoint(endpointTypes.TIRES) && showEndpoint(endpointTypes.LOCATION) && (
            <EndpointSignalGroup
              endpoint={endpointTypes.LOCATION}
              endpointSignals={tripData.endpoints[endpointTypes.LOCATION]}
              unitSystem={unitSystem}
            />
          )}
        </TripDataColumn>
      </TripDataContainer>
    </Wrapper>
  );
};

export default TripData;

TripData.propTypes = {
  selectedVehicle: shape({
    endpointsWithLatency: arrayOf(shape({
      path: string.isRequired,
      latencyLowerBound: number.isRequired,
      latencyUpperBound: number.isRequired,
      method: string.isRequired,
    })).isRequired,
  }).isRequired,
  tripData: shape({
    stage: number.isRequired,
    vehicleState: string.isRequired,
    endpoints: object.isRequired,
  }).isRequired,
  latency: string.isRequired,
  handleLatencyChange: func.isRequired,
  unitSystem: string.isRequired,
  setUnitSystem: func.isRequired,
};
