import { call, put, select, race, take, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import { isAfter } from 'date-fns';
import { reportToSegment, types as segmentTypes } from '@smartcar/morse';

import api from '../api/api';
import { types, actions, selectors } from './reducers';
import { selectors as authSelectors } from '../auth/reducers';
import { actions as errorActions } from '../errorReporter/reducers';
import { fetchApplications } from '../applications/sagas';
import { actions as orgActions, selectors as orgSelectors } from '../organizations/reducers';
import { getDeveloper } from '../auth/sagas';

import { developerOnboardingLaunch } from '../../localization/Application/DeprecatedOverview/overview';
import { dashboardRole } from '../../scenes/Application/components/Members/utils/roles';
import gatedFeatures from '../featureGate/gatedFeatures';
import staticText from '../../localization/Setup/account';
import { onboardingChecklistActive } from '../commandAi/commandAi';

// Default orgs for an user are stored in local storage on their browser.
// If there is no local storage, then default to the following:
// 1. Orgs they own
// 2. Orgs with apps
export const getDefaultOrg = (dashboardUserId, organizations) => {
  const ownerOrganization = organizations.find(org => org.dashboardRole === dashboardRole.OWNER);
  const orgWithApps = organizations.find(org => org.appAccess.length > 0);

  const mostRecentApp = JSON.parse(localStorage.getItem('recentApp'));

  const mostRecentOrg =
    mostRecentApp &&
    mostRecentApp[dashboardUserId] &&
    mostRecentApp[dashboardUserId].orgId &&
    organizations.find(org => org.id === mostRecentApp[dashboardUserId].orgId);

  // if user has a recent org, use that as the default as long as they haven't
  // had access removed. If so, then switch to orgs they own or orgs with apps
  if (Boolean(mostRecentOrg) && Object.keys(mostRecentApp).includes(dashboardUserId)) {
    return mostRecentOrg;
  } else if (ownerOrganization && ownerOrganization.appAccess.length > 0) {
    return ownerOrganization;
  } else if (orgWithApps) {
    return orgWithApps;
  }

  return ownerOrganization || organizations[0];
};

export function* createOrganization(action) {
  try {
    const organizationName = action.payload;
    const { dashboardUserId, industry } = yield select(authSelectors.getUserContext);

    const { data } = yield call(api.createOrganization, organizationName, dashboardUserId);
    const { organizationId, featureSetId } = data;
    const orgPlan = gatedFeatures.plans.find(
      plan => plan.featureSetId === featureSetId,
    );

    yield call(reportToSegment, segmentTypes.GROUP, organizationId, {
      name: organizationName,
      industry,
      plan: orgPlan.planName,
    });

    yield put(orgActions.selectOrganization(organizationId));
    yield put(actions.createOrganizationSuccess());
    yield put(push('/'));
  } catch (error) {
    yield put(actions.createOrganizationFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchOnboardingDetails() {
  try {
    const { data } = yield call(api.fetchOnboardingDetails);
    yield put(actions.fetchOnboardingDetailsSuccess(data));
  } catch (error) {
    yield put(actions.fetchOnboardingDetailsFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* initializeOnboarding() {
  try {
    const { error } = yield call(fetchApplications);
    // need to propagate error here to stop the saga
    if (error) throw error;

    const organizations = Object.values(yield select(orgSelectors.getOrganizations));
    const isNewSignUp = yield select(authSelectors.getIsNewSignUp);
    const {
      createdAt: developerCreatedAt, firstName, company,
    } = yield select(authSelectors.getUserContext);

    if (organizations.length === 0 && !isNewSignUp) {
      yield put(push('/setup/account'));
    } else if (organizations.length === 0 && isNewSignUp) {
      // automatically create team for new sign up
      // and restart initializeOnboarding
      const orgName = company.company || staticText.defaultTeamName(firstName);
      const action = { payload: orgName };
      yield call(createOrganization, action);
      yield put(push('/setup/team-invite'));
    } else {
      let organizationId = yield select(orgSelectors.getSelectedOrganization);
      const validOrganization = yield select(orgSelectors.getCurrentOrganization);
      const dashboardUserId = yield select(authSelectors.getDashboardUserId);

      if (!organizationId || !validOrganization) {
        const defaultOrg = getDefaultOrg(dashboardUserId, organizations);
        organizationId = defaultOrg.id;
        yield put(orgActions.selectOrganization(organizationId));
      }

      yield call(api.selectOrganization, organizationId);
      yield call(fetchOnboardingDetails);
      yield call(reportToSegment, segmentTypes.GROUP, organizationId);

      const { liveModeEvents } = yield select(selectors.getOnboardingEvents);

      const postLaunchDeveloper = isAfter(
        new Date(developerCreatedAt),
        new Date(developerOnboardingLaunch),
      );

      // determine if onboarding checklist needs to be shown
      const showOnboarding = liveModeEvents.some(step => step.eventStatus === false)
      && postLaunchDeveloper;

      // set flag to show onboarding
      yield put(actions.setShowOnboarding(showOnboarding));

      yield put(push('/apps'));

      yield put(actions.initializeOnboardingSuccess());
    }
  } catch (error) {
    yield put(actions.initializeOnboardingFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* updateUserInfo(action) {
  try {
    const { data } = yield call(api.updateUser, action.payload);
    yield call(getDeveloper);
    yield put(push('/'));
    yield put(actions.updateUserInfoSuccess(data));
  } catch (error) {
    yield put(actions.updateUserInfoFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* updateOnboardingEvent(action) {
  try {
    const { data } = yield call(api.updateOnboardingEvent, action.payload);
    yield put(actions.updateOnboardingEventSuccess(data));
  } catch (error) {
    yield put(actions.updateOnboardingEventFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* pollOnboardingDetails() {
  while (true) {
    try {
      const { data } = yield call(api.fetchOnboardingDetails);
      yield put(actions.fetchOnboardingDetailsSuccess(data));
      const checklistActive = yield call(onboardingChecklistActive);
      if (!checklistActive) {
        yield put(actions.stopOnboardingPolling());
      }
      yield call(delay, 5000);
    } catch (error) {
      yield put(actions.stopOnboardingPolling(error));
      yield put(errorActions.reportError(error));
    }
  }
}

export function* pollWatcher() {
  while (true) {
    yield take(types.START_ONBOARDING_POLLING);
    yield race([call(pollOnboardingDetails), take(types.STOP_ONBOARDING_POLLING)]);
  }
}

export default function* rootSaga() {
  yield takeEvery(types.CREATE_ORGANIZATION_REQUEST, createOrganization);
  yield takeEvery(types.FETCH_ONBOARDING_DETAILS, fetchOnboardingDetails);
  yield takeEvery(types.INITIALIZE_ONBOARDING, initializeOnboarding);
  yield takeEvery(types.UPDATE_USER_INFO, updateUserInfo);
  yield takeEvery(types.UPDATE_ONBOARDING_EVENT, updateOnboardingEvent);
}
