import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { Box } from '@mui/material';

import api from '../../../../../../services/api/api';
import staticText from '../../../../../../localization/Application/Billing/billingInformation';
import InfoCard from '../InfoCard/InfoCard';
import dateUtils from '../../../../../../utils/dateUtils';
import { getCurrencySymbol } from '../../utils/textFormatters';

const formatAddress = (addr) => {
  if (!addr) return '-';
  const parts = [addr.line1, addr.line2, addr.city, `${addr.state} ${addr.postal_code}`, addr.country];
  return parts.filter(Boolean).join(', ');
};

const formatPaymentMethod = (paymentMethod) => {
  if (!paymentMethod) return staticText.noPaymentMethodOnFile;

  const { type } = paymentMethod;

  switch (type) {
    case 'card': {
      const {
        brand = '', last4 = '****', expMonth = '--', expYear = '--',
      } = paymentMethod;
      const capitalizedBrand = brand.charAt(0).toUpperCase() + brand.slice(1);
      return staticText.formatPaymentMethodCard(capitalizedBrand, last4, expMonth, expYear);
    }

    case 'link': {
      const { email = 'unknown' } = paymentMethod;
      return staticText.formatPaymentMethodLink(email);
    }

    case 'us_bank_account': {
      const { last4 = '****' } = paymentMethod;
      return staticText.formatPaymentMethodBank(last4);
    }

    default:
      return '-';
  }
};

const BillingInformation = ({ organizationId, billingInfo, salesLedPlan }) => {
  const { stripeCustomerId, billingDetails = {}, invoices = [] } = billingInfo;
  const [isUpdateBillingLoading, setIsUpdateBillingLoading] = useState(false);
  const [isViewInvoicesLoading, setIsViewInvoicesLoading] = useState(false);
  const { address, paymentMethod } = billingDetails;
  const getStripePortalURL = async (action) => {
    if (action === 'updateBilling') {
      setIsUpdateBillingLoading(true);
    } else if (action === 'viewInvoices') {
      setIsViewInvoicesLoading(true);
    }

    const URL = (await api.fetchStripeUrl(organizationId, 'portal', stripeCustomerId));
    window.location.href = URL;
  };

  const billingInfoContent = [
    {
      key: salesLedPlan ?
        staticText.billingInformation.keys.billingName :
        staticText.billingInformation.keys.name,
      value: billingDetails.name || '-',
    },
    {
      key: staticText.billingInformation.keys.email,
      value: billingDetails.email || '-',
    },
    {
      key: staticText.billingInformation.keys.address,
      value: formatAddress(address),
    },
    {
      key: staticText.billingInformation.keys.phoneNumber,
      value: billingDetails.phone || '-',
    },
    {
      key: staticText.billingInformation.keys.taxId,
      value: billingDetails.taxId || '-',
    },
    {
      key: staticText.billingInformation.keys.paymentMethod,
      value: formatPaymentMethod(paymentMethod),
    },
    {
      key: staticText.billingInformation.keys.nextPaymentDue,
      value: billingInfo.billingPeriodEndDate ? dateUtils.timestampToDate(billingInfo.billingPeriodEndDate, 'MMM D, YYYY') : '-',
    },
  ];

  if (salesLedPlan) {
    billingInfoContent.push(
      {
        key: staticText.billingInformation.keys.contractStartDate,
        value: billingInfo.planStartDate ? dateUtils.timestampToDate(billingInfo.planStartDate, 'MMM D, YYYY') : '-',
      },
      {
        key: staticText.billingInformation.keys.contractRenewalDate,
        value: billingInfo.scheduledPlanStartDate ? dateUtils.timestampToDate(billingInfo.scheduledPlanStartDate, 'MMM D, YYYY') : '-',
      },
    );
  }

  const invoiceHistoryContent = invoices.map(invoice => ({
    key: dateUtils.timestampToDate(invoice.timestamp, 'MMM D, YYYY'),
    value: `${getCurrencySymbol(invoice.currency)}${(invoice.total / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
  }));

  return (
    <Box display="flex" gap={4} borderRadius={1}>
      <InfoCard
        content={billingInfoContent}
        ctaIconEnabled
        ctaTextContent={staticText.billingInformation.ctaButtonText}
        ctaOnClick={() => getStripePortalURL('updateBilling')}
        description={staticText.billingInformation.description}
        header={staticText.billingInformation.header}
        isButtonLoading={isUpdateBillingLoading}
      />
      <InfoCard
        content={invoiceHistoryContent}
        ctaIconEnabled
        ctaTextContent={staticText.invoiceHistory.ctaButtonText}
        ctaOnClick={() => getStripePortalURL('viewInvoices')}
        description={staticText.invoiceHistory.description}
        header={staticText.invoiceHistory.header}
        isButtonLoading={isViewInvoicesLoading}
      />
    </Box>
  );
};

export default BillingInformation;

BillingInformation.propTypes = {
  organizationId: string.isRequired,
  billingInfo: shape({
    stripeCustomerId: string,
    billingDetails: shape({
      address: shape({
        city: string,
        country: string,
        line1: string,
        line2: string,
        postal_code: string,
        state: string,
      }),
      email: string,
      phone: string,
      taxId: string,
      taxType: string,
      name: string,
    }),
    invoices: arrayOf(shape({
      startTimestamp: string,
      endTimestamp: string,
      total: number,
    })),
  }).isRequired,
  salesLedPlan: bool,
};

BillingInformation.defaultProps = {
  salesLedPlan: false,
};
