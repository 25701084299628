import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { TextField, Typography } from '@mui/material';

import { ImageUpload } from '../../../../../../components/Form/useForm';
import staticText from '../../../../../../localization/Application/connect-config';
import {
  CustomizationGroupDescription,
  CustomizationGroupHeading,
  CustomizationOption,
  CustomizationOptionLabel,
} from '../../styles';
import { ThemeOption } from './styles';
import { ContrastIcon, DarkModeIcon, LightModeIcon } from '../../../../../../assets/icons';

const iconMap = {
  light: <LightModeIcon />,
  dark: <DarkModeIcon />,
  system: <ContrastIcon />,
};

const Appearance = ({
  actions: {
    updateAppLogoRequest,
    clearError,
  },
  customizations: {
    appName,
    theme,
    logoUrl,
  },
  uploadedLogoUrl,
  updateCustomizations,
  fetchingConnectConfigErrors,
  isUpdatingAppLogo,
}) => {
  const handleChange = (key, value) => {
    updateCustomizations({ [key]: value });
  };

  const removeLogo = () => {
    updateCustomizations({ logoUrl: '' });
  };

  // The logo is submitted to the backend as soon as the image is uploaded.
  // But instead of updating the customizations data immediately when the input changes,
  // we need to wait until we've received the logo's URL back from server,
  // then we can update `customizations.logoUrl` manually.
  useEffect(() => {
    if (uploadedLogoUrl) {
      updateCustomizations({ logoUrl: uploadedLogoUrl });
    }
  }, [uploadedLogoUrl]);

  const { appNameInput, themeInput, logoInput } = staticText.appearance.inputs;

  return (
    <div>
      <CustomizationGroupHeading variant="h2">
        {staticText.appearance.heading}
      </CustomizationGroupHeading>
      <CustomizationGroupDescription>
        {staticText.appearance.description}
      </CustomizationGroupDescription>
      <CustomizationOption>
        <CustomizationOptionLabel id="logo-label">{logoInput.displayName}</CustomizationOptionLabel>
        <Typography marginBottom={1}>{logoInput.description}</Typography>
        <ImageUpload
          previousImageUrl={logoUrl}
          specs={logoInput.requirements}
          acceptedTypes={logoInput.acceptedTypes}
          onUpload={updateAppLogoRequest}
          onDelete={removeLogo}
          uploadText={logoInput.uploadLogo}
          replaceText={logoInput.replaceText}
          removeText={logoInput.removeText}
          imageAltText={logoInput.imageAltText}
          updating={isUpdatingAppLogo}
          uploadError={fetchingConnectConfigErrors.updatingAppLogo}
          clearUploadError={clearError}
          disabled={false}
        />
      </CustomizationOption>
      <CustomizationOption>
        <CustomizationOptionLabel id="app-name-label">{appNameInput.displayName}</CustomizationOptionLabel>
        <TextField
          aria-labelledby="app-name-label"
          id="appName"
          name="appName"
          data-testid="app-name"
          defaultValue={appName}
          onChange={e => handleChange('appName', e.target.value)}
        />
      </CustomizationOption>
      <CustomizationOption>
        <CustomizationOptionLabel id="theme-label">{themeInput.displayName}</CustomizationOptionLabel>
        {themeInput.options.map(option => (
          <ThemeOption
            key={option.value}
            variant="outlined"
            onClick={() => handleChange('theme', option.value)}
            selected={option.value === theme}
            className={option.value === theme ? 'selected' : ''}
          >
            {iconMap[option.value]}
            {option.label}
          </ThemeOption>
        ))}
      </CustomizationOption>
    </div>
  );
};

export default Appearance;

Appearance.propTypes = {
  actions: shape({
    updateAppLogoRequest: func,
    clearError: func,
  }).isRequired,
  customizations: shape({
    appName: string.isRequired,
    theme: string.isRequired,
    logoUrl: string,
  }).isRequired,
  uploadedLogoUrl: string,
  updateCustomizations: func.isRequired,
  isUpdatingAppLogo: bool.isRequired,
  fetchingConnectConfigErrors: shape({
    updatingAppLogo: string,
  }).isRequired,
};

Appearance.defaultProps = {
  uploadedLogoUrl: null,
};
