import React from 'react';
import { func, shape, string } from 'prop-types';

import { GoogleOAuth } from '..';
import { SSO } from './components';


const PublicCallback = ({
  location,
  history,
}) => {
  const urlParams = new URLSearchParams(location.search);

  // Handle different callback types based on query parameters
  // The WorkOS SSO auth callback param is labeled 'state'
  const callbackType = urlParams.get('type') || urlParams.get('state');

  if (callbackType === 'sso_auth') {
    return <SSO location={location} history={history} />;
  }

  // Default, render the GoogleOAuth component (original /callback path behavior)
  return <GoogleOAuth location={location} history={history} />;
};

export default PublicCallback;

PublicCallback.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
  history: shape({
    replace: func.isRequired,
  }).isRequired,
};
