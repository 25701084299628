import React from 'react';
import { GlobalStyles } from '@mui/material';

const Styles = () => {
  return (<GlobalStyles styles={theme => ({
    '.commandbar-launcher': {
      transition: 'transform 0.25s ease',
      zIndex: `${theme.zIndex.commandbar.launcher} !important`,
    },
    '#commandbar-nudge-container': {
      zIndex: `${theme.zIndex.commandbar.nudges} !important`,
    },
    '.commandbar-checklist': {
      zIndex: `${theme.zIndex.commandbar.checklist} !important`,
    },
    '#helphub-modal': {
      zIndex: `${theme.zIndex.commandbar.helphub} !important`,
    },
    // temporary fix for the commandbar style bug
    '#commandbar-nudge-container, #commandbar-nudges-banner-inline-container-top, .commandbar-checklist': {
      p: {
        marginTop: 0,
      },
    },
  })}
  />);
};

export default Styles;
