import { ChevronDown } from 'react-feather';
import { SelectIcon } from '../../../components';

export const selectFilters = [
  {
    filterKey: 'mode',
    id: 'vehicles-filter-mode',
    icon: SelectIcon,
    label: 'Mode',
    minWidth: '130px',
  },
  {
    filterKey: 'make',
    id: 'vehicles-filter-make',
    icon: SelectIcon,
    label: 'Make',
    minWidth: '150px',
  },
  {
    filterKey: 'model',
    id: 'vehicles-filter-model',
    icon: SelectIcon,
    label: 'Model',
    minWidth: '150px',
  },
  {
    filterKey: 'year',
    id: 'vehicles-filter-year',
    icon: SelectIcon,
    label: 'Year',
    minWidth: '90px',
  },
];

export const modeOptions = [
  {
    value: 'live',
    displayName: 'Live',
  },
  {
    value: 'simulated',
    displayName: 'Simulated',
  },
];

export const vehicleDetailsFilters = [
  {
    filterKey: 'errorTypes',
    formTitle: 'Error Types',
    icon: ChevronDown,
    id: 'filter-error-types',
    columns: 2,
    minWidth: '170px',
  },
  {
    filterKey: 'requestMethods',
    formTitle: 'Request Methods',
    icon: ChevronDown,
    id: 'filter-request-methods',
    columns: 3,
    minWidth: '204px',
  },
];

export const webhookEventFilters = [
  {
    filterKey: 'statusCodes',
    formTitle: 'Response status',
    formLabel: 'All types',
    icon: ChevronDown,
    id: 'filter-status-codes',
    minWidth: '170px',
  },
  {
    filterKey: 'webhookTypes',
    formTitle: 'Webhook Type',
    formLabel: 'All types',
    icon: ChevronDown,
    id: 'filter-webhook-types',
    minWidth: '170px',
  },
];

export const staticText = {
  apply: 'Apply',
  conDate: 'Connection date',
  dateRange: 'Date range',
  resetAll: 'Reset all',
  reset: 'Reset',
  search: 'Search',
  vehicleId: 'Vehicle ID',
  logDaysAllowance: logDays => `Your current plan provides access to the most recent ${logDays} day${logDays > 1 ? 's' : ''} of log data.`,
  maxLogDays: logDays => `Logs can be viewed up to the past ${logDays} days.`,
  featureGated: {
    upgrade: '|+|Upgrade@@/team/billing|+| to access more.',
    contactOwnersToUpgrade: 'To access more, contact your team owner to upgrade your plan.',
  },
};
