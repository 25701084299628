/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material/styles';

export const Section = styled('div')(({ theme, minHeight }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  border: theme.border.default,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  minHeight,
}));

export const TableContainer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  marginTop: theme.spacing(4.5),
  paddingTop: theme.spacing(2.25),
}));
