import { styled } from '@mui/material';

export const MainWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'sidebarNavExpanded',
})(({ theme, sidebarNavExpanded }) => ({
  ...theme.styledScrollbar,

  backgroundColor: theme.palette.common.white,
  width: `calc(100vw - ${sidebarNavExpanded ?
    theme.width.sidebar.expanded :
    theme.width.sidebar.collapsed
  })`,
  transition: 'width .25s ease',
  flexGrow: 1,
  overflow: 'auto',
}));

export const Main = styled('main')(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

export const Section = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  border: theme.border.default,
  padding: theme.spacing(3, 5),
  marginBottom: theme.spacing(3),
}));
