import { Button, styled } from '@mui/material';

export const Selector = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  borderRadius: theme.border.radius,
  position: 'relative',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },

  ...(open && {
    boxShadow: theme.border.boxShadow,
    borderRadius: theme.border.radius,
  }),
}));

export const AppName = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  maxWidth: '250px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',

  span: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

export const AppNameButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0, 1, 1),
  color: theme.palette.text.primary,
  width: '100%',
  maxWidth: '250px',
  height: '48px',

  span: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    marginRight: theme.spacing(1),
    maxWidth: '168px',
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    textDecoration: 'none',
  },
}));

export const SelectorArrow = styled('div', {
  shouldForwardProp: prop => prop !== 'listOpen',
})(({ listOpen }) => ({
  lineHeight: '0',

  svg: {
    transition: 'transform .25s ease',

    ...(listOpen && {
      transform: 'rotate(180deg)',
    }),
  },
}));

export const AppListContainer = styled('div')(() => ({
  position: 'absolute',
  left: '-1px',
  top: 'calc(100% + 5px)',
  minWidth: '300px',
}));

export const AppLogoWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'hasLogo',
})(({ theme, hasLogo }) => ({
  height: '30px',
  width: '30px',
  minWidth: '30px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[100]}`,
  fontWeight: 'bold',

  ...(!hasLogo && {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
  }),

  img: {
    width: '100%',
  },

  span: {
    marginRight: '0',
    marginBottom: '-2px',
  },
}));
