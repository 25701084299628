const staticText = {
  overviewHeader: 'Vehicle Information',
  tableHeader: 'Request Logs',
  disconnectVehicle: 'Disconnect vehicle',
  overview: {
    overviewHeader: 'Overview',
    requestsHeader: 'Requests',
    fieldTitles: {
      userId: 'User ID',
      vehicleId: 'Vehicle ID',
      connectedAt: 'First connection date',
      billableRequests: 'Billable Requests',
      allocatedRequests: 'Allocated Requests',
    },
  },
  vehicleDetailTabs: [
    'Overview',
    'Webhooks',
  ],
  unavailable: 'Unavailable',
  billingEndOfMonth: 'Your cycle will reset at the end of the month.',
  billingEndOfCycle: 'Your cycle will reset at the end your billing cycle.',
  billingPeriodEndDate: date => `Your cycle will reset on ${date}.`,
  webhooks: {
    logs: {
      header: 'Webhook request log',
    },
  },
};

export default staticText;
