import React, { useState } from 'react';
import { string, func, object, node, bool } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button } from '@mui/material';
import spinningButtonStyles from '../../../../global-styles/animations/spinningButton';
import InputField from '../../../InputField';

const staticText = {
  title: 'confirmation',
  button: 'Confirm',
  account: {
    input: 'Email',
    errorMessage: 'Email address does not match.',
    placeholder: 'Email',
  },
  application: {
    input: 'Application Name',
    errorMessage: 'Application name does not match.',
    placeholder: 'e.g. Radiator Springs Fuel Delivery',
  },
  organization: {
    input: 'Organization Name',
    errorMessage: 'Organization name does not match.',
    placeholder: 'e.g. Radiator Springs Fuel Delivery',
  },
  webhook: {
    input: 'Webhook Name',
    errorMessage: 'Webhook name does not match.',
    placeholder: 'Enter webhook name',
  },
  billingDowngrade: {
    input: 'Email',
    errorMessage: 'Email address does not match.',
    placeholder: email => `${email}`,
  },
};

export const defaultToggleModal = () => {};

const ValidateName = ({
  nameToMatch,
  nameType,
  title,
  toggleModal,
  submitAction,
  description,
  isLoading,
  additionalFields,
  segmentEventInfo,
}) => {
  const [formState, setFormState] = useState({
    isValid: false,
    attemptedSubmit: false,
    value: '',
  });

  const handleChange = ({ value: val }) => {
    let value = val;
    // if a curly apostrophe is used instead of the standard ASCII character, catch it and change
    // it to the ASCII apostrophe so it will pass validation. This fixes a (mobile Safari) bug.
    const apostrophe = value.match(/\u2019/);
    const apostropheIndex = apostrophe ? apostrophe.index : -1;
    if (apostropheIndex > -1) {
      value = `${value.substring(0, apostropheIndex)}'${value.substring(
        apostropheIndex + 1,
        value.length,
      )}`;
    }
    if (value === nameToMatch) {
      setFormState(prev => ({ ...prev, isValid: true, value }));
    } else {
      setFormState(prev => ({ ...prev, isValid: false, value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, value } = formState;
    if (isValid) {
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: `validate name - ${title}`,
        form_content: { value, ...segmentEventInfo },
      });
      submitAction();
      toggleModal(false);
    } else {
      reportToSegment(types.TRACK, eventNames.formFailed, {
        label: `validate name - ${title}`,
        form_content: {
          value,
          errorMessage: staticText[nameType].errorMessage,
        },
      });
      setFormState(prev => ({ ...prev, attemptedSubmit: true }));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const showErrorMessage = formState.attemptedSubmit && !formState.isValid;

  return (
    <div>
      <Box mb={2}>{description}</Box>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <InputField
            inputName="validate-name-input"
            inputValues={formState.value}
            handleChange={handleChange}
            InputProps={{ onKeyPress: handleKeyPress }}
            nonLabelPlaceholder={nameToMatch}
            helperText={nameType === 'billingDowngrade' ? staticText[nameType].placeholder(nameToMatch) : staticText[nameType].placeholder}
            errorMessage={showErrorMessage && staticText[nameType].errorMessage}
            autoFocus
          />
          {additionalFields}
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              id="validate-name-submit"
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              onClick={handleSubmit}
              sx={isLoading && spinningButtonStyles}
              disabled={isLoading}
            >
              {staticText.button}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default ValidateName;

ValidateName.propTypes = {
  nameToMatch: string.isRequired,
  nameType: string,
  title: string.isRequired,
  toggleModal: func,
  submitAction: func.isRequired,
  description: node.isRequired,
  isLoading: bool,
  additionalFields: node,
  segmentEventInfo: object,
};

ValidateName.defaultProps = {
  nameType: 'application',
  isLoading: false,
  toggleModal: defaultToggleModal,
  additionalFields: null,
  segmentEventInfo: null,
};
