export const filterMakesByEngineType = (
  selectedMakes,
  compatibilityMatrix,
  brandManagementFilters,
  availableMakes,
) => {
  const availableMakesAfterFiltersApplied = [];
  const disabledMakes = [];


  if (brandManagementFilters.length === 0) {
    return {
      disabledMakes: [],
      availableMakesAfterFiltersApplied: availableMakes.map(make => make.make),
      filteredSelectedMakes: selectedMakes,
    };
  }

  if (brandManagementFilters.length > 0) {
    availableMakes.forEach((make) => {
      // example: make is { make: 'FORD', displayName: 'Ford' }
      const brand = make.make;
      if (compatibilityMatrix[brand] && compatibilityMatrix[brand].engineType) {
        const engineTypes = compatibilityMatrix[brand].engineType;
        const filtersFound = engineTypes.some(type => brandManagementFilters.includes(type));

        if (filtersFound) {
          availableMakesAfterFiltersApplied.push(brand);
        } else {
          disabledMakes.push(brand);
        }
      }
    });
  }

  const filteredSelectedMakes =
  selectedMakes.filter(make => availableMakesAfterFiltersApplied.includes(make));

  return {
    availableMakesAfterFiltersApplied,
    disabledMakes,
    filteredSelectedMakes,
  };
};

export default {
  filterMakesByEngineType,
};
