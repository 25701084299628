const staticText = {
  heading: 'Connect Playground',
  description: 'Generate a Connect URL to start connecting live or simulated vehicles to your application. |+|Learn more@@https://smartcar.com/docs/connect/redirect-to-connect|+|',
  subheading: 'Generate a Connect URL',
  buttons: {
    back: 'Back',
    next: 'Next',
    launch: 'Launch Connect',
    copy: 'Copy link',
    copySuccess: 'Copied!',
  },
  viewer: {
    heading: 'Generated Connect URL',
  },
  stepperSections: [
    'Basic settings',
    'Permissions',
    'Advanced settings',
  ],
  form: {
    basic: {
      clientId: {
        label: 'Client ID',
        description: "Your application's unique identifier.",
      },
      redirectUri: {
        label: 'Redirect URI (required)',
        description: 'The user will be redirected to this URI after authorizing their vehicle through Connect.',
        placeholder: 'Select a URI',
        add: 'Add URI',
        modal: {
          title: 'Add a new redirect URI',
          description: 'After a user completes the Connect flow and authorizes your application to access their vehicle, they will be redirected to this URI. You can also add or modify redirect URIs on the Configuration page.',
          placeholder: 'https://example.com',
          button: 'Add',
        },
      },
      mode: {
        label: 'Mode',
        options: [
          {
            text: 'Live',
            value: 'live',
            description: 'Connect real vehicles to your application.',
          },
          {
            text: 'Simulated',
            value: 'simulated',
            description: 'Connect simulated vehicles to test your application.',
          },
        ],
      },
    },
    permissions: {
      description: '|*|bold@@At least one permission|*| must be included in your Connect flow.',
      tableHeaders: [
        'Permission',
        'Description',
      ],
      permissions: {
        control_charge: 'Control a vehicle’s charge state',
        control_climate: 'Set the status and settings of the vehicle’s climate control system',
        control_navigation: 'Send commands to the vehicle’s navigation system',
        control_pin: 'Modify a PIN and enable the PIN to Drive feature for the vehicle',
        control_security: 'Lock or unlock the vehicle',
        control_trunk: 'Open a vehicle’s trunk or frunk',
        read_alerts: 'Read alerts triggered by the vehicle',
        read_battery: "Read an EV's high voltage battery data",
        read_charge: 'Read charging data',
        read_charge_events: 'Receive notifications for events associate with charging',
        read_charge_locations: 'Access previous charging locations and their associated charging configurations',
        read_charge_records: 'Read charge records and associated billing information',
        read_climate: 'Read the status and settings of the vehicle’s climate control system',
        read_compass: 'Read the compass direction the vehicle is facing',
        read_diagnostics: "Read the current health status of vehicle's systems",
        read_engine_oil: 'Read vehicle engine oil health',
        read_extended_vehicle_info: 'Read vehicle configuration information from a vehicle',
        read_fuel: 'Read fuel tank level',
        read_location: "Access the vehicle's location",
        read_odometer: 'Retrieve total distance traveled',
        read_security: 'Read the lock status of doors, windows, charging port, etc.',
        read_service_history: 'Read service records tracked by the vehicle’s dealer or manually added by the vehicle owner',
        read_speedometer: 'Read a vehicle’s speed',
        read_thermometer: 'Read temperatures from inside and outside the vehicle',
        read_tires: 'Read a vehicle’s tire status',
        read_user_profile: "Read the information associated with a user's connected services account profile such as their email and phone number",
        read_vehicle_info: 'Know make, model, and year',
        read_vin: 'Read the vehicle identification number (VIN)',
      },
      selected: 'Selected',
    },
    advanced: {
      description: 'The following fields are |*|bold@@optional|*|. You may launch Connect without configuring these fields.',
      state: {
        label: 'State',
        description: 'An optional value included as a query parameter in the redirect URI back to your application.',
        placeholder: 'Enter state',
      },
      user: {
        label: 'User',
        description: 'Specify a unique identifier for the vehicle owner to track and aggregate analytics across Connect sessions for each vehicle owner on Dashboard. \nNote: Use the state parameter in order to identify the user at your callback URI when receiving an authorization or error code after the user exits the Connect flow.',
        placeholder: 'Enter user',
      },
      make: {
        label: 'Make',
        description: 'Allows users to bypass the Brand Selector screen to go directly to the selected make’s login page.',
        placeholder: 'None',
      },
      country: {
        label: 'Country',
        description: 'Determines what brands are listed on the Brand Selector screen in Connect, e.g. Renault is available in Europe, but not the US. \nIf no country is passed or set as default on Dashboard, Smartcar will use the device’s IP to set an appropriate country.',
        placeholder: 'None',
      },
      singleSelect: {
        label: 'Vehicle single select',
        description: 'If enabled, users are only allowed to select a single vehicle from the list of vehicle(s) on the Grant screen.',
        toggleLabel: 'Enabled',
      },
      featureGated: {
        upgrade: 'The following configurations are only available for Custom plans. |+|Upgrade now@@/team/billing|+| to configure these fields.',
        contactOwnersToUpgrade: 'The following configurations are only available for Custom plans. Contact your team owner to upgrade your plan.',
        tooltip: 'This configuration is only available for enterprise plans. Upgrade now to configure these fields.',
      },
    },
  },
};

export default staticText;
