import { Box, Icon, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

import formFields from '../fields';
import { Chip } from '../../../../../../../components';
import { TYPES } from '../../../utils/webhookTypes';
import { formatFrequency } from './utils';

const {
  unitTypes, permissionOptions, stateChangeTypes, getEventDisplayName,
} = formFields;

const renderValue = (name, value) => {
  switch (name) {
    case 'type':
      return <Typography>{TYPES[value].text}</Typography>;
    case 'frequency':
      return <Typography>{formatFrequency(value)}</Typography>;
    case 'events':
      return Object.values(value)
        .map(event => <Typography key={event}>{getEventDisplayName(event)}</Typography>);
    case 'units':
      return <Typography>{unitTypes[value].text}</Typography>;
    case 'permissions':
      return permissionOptions
        .filter(option => value.includes(option.name))
        .map(({ text, path }) => (
          <Box display="flex" alignItems="center" key={text} sx={{ ':not(:last-of-type)': { marginBottom: 1 } }}>
            <Typography mr={2}>{text}</Typography>
            <Chip text={path} />
          </Box>
        ));
    case 'stateChange':
      return <Typography>{stateChangeTypes[value].text}</Typography>;
    default:
      return <Typography>{typeof value === 'string' ? value : JSON.stringify(value)} </Typography>;
  }
};

const Review = ({ fields }) => {
  const theme = useTheme();

  return fields.map(({
    name, label, altLabel, value, labelTooltip,
  }) => (
    <div key={name}>
      <Box display="flex" alignItems="flex-end" mt={3} mb={1}>
        <span className="bold">{altLabel || label}</span>
        {labelTooltip && labelTooltip.showFieldCondition && labelTooltip.showFieldCondition.includes('review') && (
          <Tooltip
            arrow
            placement="top"
            title={labelTooltip.text}
            PopperProps={{ sx: { zIndex: 2147483647 } }}
          >
            <Icon
              sx={{
                marginLeft: 1,
                marginBottom: 0.25,
                fontSize: '1.25rem',
                color: theme.palette.text.disabled,
              }}
            >
              <labelTooltip.Icon fontSize="inherit" />
            </Icon>
          </Tooltip>
        )}
      </Box>
      {renderValue(name, value)}
    </div>
  ));
};

export default Review;
