const applicationNav = [
  {
    text: 'overview',
    iconSrc: '/assets/icons/nav/equalizer.svg',
  },
  {
    text: 'connect',
    iconSrc: '/assets/icons/nav/tablet.svg',
    subNav: [
      {
        text: 'insights',
        path: 'connect/insights',
      },
      {
        text: 'customize',
        path: 'connect/customizations',
      },
      {
        text: 'playground',
        path: 'connect/playground',
      },
    ],
  },
  {
    text: 'simulator',
    iconSrc: '/assets/icons/nav/play-circle.svg',
  },
  {
    text: 'vehicles',
    iconSrc: '/assets/icons/nav/vehicle.svg',
  },
  {
    text: 'logs',
    iconSrc: '/assets/icons/nav/list.svg',
  },
  {
    text: 'webhooks',
    iconSrc: '/assets/icons/nav/hook.svg',
  },
  {
    text: 'configuration',
    iconSrc: '/assets/icons/nav/key.svg',
  },
];

const settingsNav = [
  {
    text: 'team',
    iconSrc: '/assets/icons/nav/business.svg',
    subNav: [
      {
        text: 'general',
        path: 'team/settings',
      },
      {
        text: 'applications',
        path: 'team/applications',
      },
      {
        text: 'members',
        path: 'team/members',
      },
      {
        text: 'billing',
        path: 'team/billing',
      },
      {
        text: 'security',
        path: 'team/security',
      },
    ],
  },
  {
    text: 'personal',
    iconSrc: '/assets/icons/nav/user.svg',
    subNav: [
      {
        text: 'profile',
        path: 'user/profile',
      },
      {
        text: 'security',
        path: 'user/security',
      },
    ],
  },

];

const generalNav = {
  search: {
    text: 'search',
    iconSrc: '/assets/icons/nav/smart_search.svg',
    action: 'openSearch',
  },
  settings: {
    text: 'settings',
    path: '/team/settings',
    iconSrc: '/assets/icons/nav/settings.svg',
  },
  documentation: {
    text: 'documentation',
    path: 'https://smartcar.com/docs/getting-started/introduction',
    iconSrc: '/assets/icons/nav/book.svg',
  },
  help: {
    text: 'help',
    iconSrc: '/assets/icons/nav/help.svg',
    action: 'openHelp',
  },
};

const userMenu = {
  userProfile: {
    linkText: 'Profile',
    link: '/user/profile',
    iconSrc: '/assets/icons/nav/user.svg',
  },
  security: {
    linkText: 'Security',
    link: '/user/security',
    iconSrc: '/assets/icons/nav/lock.svg',
  },
  cookieSettings: {
    buttonText: 'Cookie settings',
    iconSrc: '/assets/icons/nav/cookie.svg',
  },
  logout: {
    buttonText: 'Log out',
    iconSrc: '/assets/icons/nav/logout.svg',
  },
  privacy: {
    linkText: 'Privacy policy',
    link: 'https://smartcar.com/privacy',
  },
  terms: {
    linkText: 'Terms of service',
    link: 'https://smartcar.com/terms',
  },
};

const staticText = {
  collapse: 'Collapse',
  expand: 'Expand',
  settings: 'Settings',
  back: 'Back to Dashboard',
};

export { userMenu, applicationNav, settingsNav, generalNav, staticText };

