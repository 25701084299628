import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import chartErrorImage from '../../../../../../assets/graphics/chart-error.png';
import staticText from '../../../../../../localization/Application/DeprecatedOverview/chartError';

const Container = styled('div')({
  textAlign: 'center',
});

const ErrorImage = styled('img')({
  width: '44px',
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.light,
  marginTop: theme.spacing(3),
}));

const ChartError = () => {
  return (
    <Container>
      <ErrorImage src={chartErrorImage} alt={staticText.errorAlt} />
      <ErrorText variant="body1">{staticText.errorText}</ErrorText>
    </Container>
  );
};

export default ChartError;
