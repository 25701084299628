import { styled } from '@mui/material';

export const Menu = styled('ul')(({ theme }) => ({
  position: 'absolute',
  listStyle: 'none',
  right: theme.spacing(3),
  padding: theme.spacing(1),
  marginTop: '4px',
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow2,
  backgroundColor: theme.palette.common.white,
  width: '200px',
}));

export const MenuItem = styled('li')(({ theme }) => ({
  position: 'relative',

  'a, button': {
    display: 'flex',
    fontFamily: theme.typography.bold.fontFamily,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '12px 14px',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    borderRadius: theme.border.radius,

    p: {
      marginBottom: '-4px',
      paddingLeft: theme.spacing(1),
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      textDecoration: 'none',
    },
  },

  button: {
    width: '100%',
    transition: 'none',
  },
}));

export const AccountButton = styled('div', {
  shouldForwardProp: prop => prop !== 'menuOpen',
})(({ theme, menuOpen }) => ({
  marginLeft: theme.spacing(0),
  borderRadius: theme.border.radius,

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },

  button: {
    border: 'none',
    backgroundColor: 'transparent',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',

    img: {
      height: '32px',
      width: '32px',
      marginRight: theme.spacing(1),
    },

    svg: {
      transition: 'transform .25s ease',
      marginRight: '-4px',

      ...(menuOpen && {
        transform: 'rotate(180deg)',
      }),
    },
  },
}));
