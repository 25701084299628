import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Button, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { AppSelectorButton } from '../AppSelector/styles';
import { Menu, MenuItem } from '../../styles';

const OrgSelector = ({
  organizations,
  selectedOrg,
  selectOrganization,
  history,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const { name } = organizations.find(org => org.id === selectedOrg);

  const handleMenuClick = (event) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: menuOpen ? 'close menu' : 'open menu',
      text: `[org selector] ${name}`,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleOrgClick = (id) => {
    selectOrganization(id);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[switch org: ${id}]`,
    });
    history.push('/');
    setAnchorEl(null);
  };

  if (organizations.length === 1) {
    return (
      <Typography variant="bold" color="text.primary" ml={1.5}>{name}</Typography>
    );
  }

  return (
    <div>
      <AppSelectorButton
        id="org-selector-button"
        aria-controls={menuOpen ? 'org-selector' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        open={menuOpen}
        onClick={handleMenuClick}
      >
        <Typography variant="bold">{name}</Typography>
        <ExpandMoreRoundedIcon />
      </AppSelectorButton>
      <Menu
        id="org-selector"
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'org-selector-button',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: '500px',
              width: '300px',
            },
          },
        }}
      >{organizations.map(org => (
        <MenuItem key={org.id} selected={org.id === selectedOrg}>
          <Button onClick={() => handleOrgClick(org.id)}>
            <Typography variant="body2">{org.name}</Typography>
          </Button>
        </MenuItem>
      ))}
      </Menu>
    </div>
  );
};

export default withRouter(OrgSelector);

OrgSelector.propTypes = {
  organizations: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  selectedOrg: string.isRequired,
  selectOrganization: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
