import React, { useEffect, useState } from 'react';
import { number, shape, string } from 'prop-types';

import { PercentBar } from '../../styles';

const FunnelBar = ({ event, delay = 100 }) => {
  const [percentage, setPercentage] = useState(0);
  const {
    percent, previousStagePercent,
  } = event;
  useEffect(() => {
    setTimeout(() => {
      setPercentage(percent);
    }, delay);
  }, [percent]);

  return (
    <PercentBar
      previousStagePercent={previousStagePercent}
      percentage={percentage}
      data-testid={`percent-bar-${event.order}`}
    />
  );
};

export default FunnelBar;

FunnelBar.propTypes = {
  event: shape({
    order: number.isRequired,
    percent: string.isRequired,
    previousStagePercent: string.isRequired,
  }).isRequired,
  delay: number.isRequired,
};
