import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectCustomizations from './ConnectCustomizations';
import { actions } from './reducers';
import { actions as applicationsActions } from '../../../../services/applications/reducers';

function mapStateToProps(state) {
  const { name } =
    state.applications.applications[state.applications.selectedApplication];

  return {
    ...state.connectCustomizations,
    appId: state.applications.selectedApplication,
    appName: name,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchSupportedCountriesRequest,
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    updateConnectConfigRequest,
    fetchBrandManagementCapabilitiesRequest,
    deleteAppLogo,
  } = actions;
  const { updateApplication } = applicationsActions;

  return {
    actions: bindActionCreators(
      {
        fetchSupportedCountriesRequest,
        fetchApplicationMakesRequest,
        fetchConnectConfigRequest,
        updateConnectConfigRequest,
        updateApplication,
        fetchBrandManagementCapabilitiesRequest,
        deleteAppLogo,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCustomizations);
