import React from 'react';

import { bool, string } from 'prop-types';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import { useTheme } from '@mui/material';

import { Chip } from './styles';

const DeltaChip = ({
  delta,
  invertColors,
  text,
}) => {
  const theme = useTheme();

  let icon;
  let color;
  switch (delta) {
    case 'up':
      icon = <ArrowUpwardRoundedIcon />;
      color = invertColors ? theme.palette.error.main : theme.palette.success.main;
      break;
    case 'down':
      icon = <ArrowDownwardRoundedIcon />;
      color = invertColors ? theme.palette.success.main : theme.palette.error.main;
      break;
    default:
      icon = <HorizontalRuleRoundedIcon />;
      // eslint-disable-next-line prefer-destructuring
      color = theme.palette.grey[600];
  }


  return (
    <Chip
      sx={{
        '.MuiChip-icon': {
          color,
        },
      }}
      icon={icon}
      label={text}
      variant="outlined"
    />
  );
};

export default DeltaChip;

DeltaChip.propTypes = {
  delta: string,
  invertColors: bool,
  text: string.isRequired,
};

DeltaChip.defaultProps = {
  delta: 'none',
  invertColors: false,
};
