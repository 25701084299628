import React from 'react';
import { arrayOf, func, object, shape, string } from 'prop-types';
import { Toolbar } from '@mui/material';

import { AppSelector, OrgSelector, UserMenu } from './components';
import { AppBar } from './styles';

const TopbarNav = ({
  actions: {
    logoutRequest,
    selectOrganization,
  },
  organizations,
  organization,
  applications,
  selectedApplication,
  lockedApplications,
  pathname,
  userContext,
  view,
}) => {
  const application = applications[selectedApplication];
  const showAppSelector = view === 'apps';

  return (
    <AppBar aria-label="primary">
      <Toolbar>
        {showAppSelector ? (
          <AppSelector
            pathname={pathname}
            applicationId={application.id}
            applications={applications}
            organizations={organizations}
            lockedApplicationsByOrg={lockedApplications}
          />
        ) : (
          <OrgSelector
            organizations={organizations}
            selectedOrg={organization.id}
            selectOrganization={selectOrganization}
          />
        )}
        <UserMenu
          userContext={userContext}
          logoutRequest={logoutRequest}
        />
      </Toolbar>
    </AppBar>
  );
};

export default TopbarNav;

TopbarNav.propTypes = {
  actions: shape({
    logoutRequest: func.isRequired,
    selectOrganization: func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: arrayOf(object).isRequired,
  organization: shape({
    id: string.isRequired,
  }).isRequired,
  selectedApplication: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  applications: object.isRequired,
  lockedApplications: shape({
    [string]: arrayOf(string),
  }).isRequired,
  pathname: string.isRequired,
  userContext: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
  view: string.isRequired,
};
