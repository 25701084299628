import { ChevronDown } from 'react-feather';

export const filtersConfig = [
  {
    filterKey: 'makes',
    icon: ChevronDown,
    formTitle: 'Makes',
    id: 'logs-filter-makes',
    columns: 6,
  },
  {
    filterKey: 'statusCodes',
    icon: ChevronDown,
    formTitle: 'Status codes',
    id: 'logs-filter-status-codes',
    minWidth: '181px',
  },
];

export const staticText = {
  done: 'Done',
  resetAll: 'Reset',
  conDate: 'Date range',
  export: 'Export',
  exportStarted: 'Your export has started.',
  makesClearAll: 'Clear all',
  apply: 'Apply',
  datePlaceholder: 'Date range',
  selectAllCheckboxes: 'Select all',
  clearAllCheckboxes: 'Clear all',
  logDaysAllowance: logDays => `Your current plan provides access to the most recent ${logDays} day${logDays > 1 ? 's' : ''} of log data.`,
  maxLogDays: logDays => `Logs can be viewed up to the past ${logDays} days.`,
  featureGated: {
    upgrade: '|+|Upgrade@@/team/billing|+| to access more.',
    contactOwnersToUpgrade: 'To access more, contact your team owner to upgrade your plan.',
  },
  exportModal: {
    label: 'Request log export',
    title: 'Logs export request',
    description: 'Select the date range of logs requested. Logs may be requested up to the past year.',
    calendarPlaceholder: 'Select a date range',
    confirmation: (dateRange, email) => `You are requesting log data for the time range |*|bold@@${dateRange}|*|. \nOur support team will email the logs to |*|bold@@${email}|*| once ready.`,
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    submit: 'Submit',
    toast: 'Logs export request submitted. Our support team will email you the data as soon as possible.',
    supportRequestEmail: {
      subject: email => `Logs export request (${email})`,
      body: (dateRange, email, appId) => `Request for logs export: \nDate range: ${dateRange} \nEmail: ${email} \nApp ID: ${appId}`,
    },
  },
  upgradeModal: {
    label: 'Upgrade to export request logs',
    title: 'Upgrade your plan',
  },
};
