import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../../../services/applications/reducers';
import { actions, types, selectors, initialState } from './reducers';
import api from '../../../../../../services/api/api';

import { TYPES } from '../../../Webhooks/utils/webhookTypes';
import { Toast } from '../../../../../../components';
import { errors, messages } from '../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import formatVehicleLogs from '../../utils/formatVehicleLogs';

export function* fetchWebhooksInfoRequest(action) {
  try {
    const { payload: vehicleId } = action;

    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(api.fetchWebhooksInfo, applicationId, vehicleId);

    data.rows = data.rows.map((webhook) => {
      return {
        ...webhook,
        type: TYPES[webhook.type] ? TYPES[webhook.type].text : webhook.type,
      };
    });

    const formattedLogs = {
      webhooksInfo: {
        rows: data.rows,
        columns: data.cols,
      },
    };

    yield put(actions.fetchWebhooksInfoSuccess(formattedLogs));
  } catch (error) {
    yield put(actions.fetchWebhooksInfoFailure(error));
  }
}

export function* unsubscribeVehicleFromWebhook(action) {
  try {
    const { vehicleId, webhookId } = action.payload;

    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    yield call(api.unsubscribeVehicleFromWebhook, applicationId, vehicleId, webhookId);
    yield call(Toast, messages.unsubscribeVehicleSuccess, 'success');
    yield put(actions.unsubscribeVehicleFromWebhookSuccess());
    yield put(actions.fetchWebhooksInfoRequest(vehicleId));
  } catch (error) {
    yield put(actions.unsubscribeVehicleFromWebhookFailure(error));
    yield call(Toast, messages.unsubscribeVehicleFailure, 'warn');
  }
}

export function* fetchVehicleInfo(action) {
  const { payload: vehicleId } = action;
  const applicationId = yield select(applicationSelectors.getSelectedApplication);
  // currently only available to 'live' vehicles, server will default to live mode
  const filterValues = { vehicle_id: vehicleId };

  try {
    const { data } = yield call(
      api.fetchConnectedVehicles,
      applicationId,
      filterValues,
    );
    const vehicleData = data.rows.find(row => row.vehicleId === vehicleId);
    if (!vehicleData) {
      throw new Error(errors.vehicleNotFound);
    }
    const { vehicle, ...info } = vehicleData;
    const vehicleInfo = { ...vehicle, ...info };
    yield put(actions.fetchVehicleInfoSuccess(vehicleInfo));
  } catch (error) {
    yield put(actions.fetchVehicleInfoFailure(error));
  }
}

export function* fetchVehicleLogs(action) {
  try {
    const {
      meta: vehicleId,
      payload: { filterValues, newPaginationModel },
    } = action;
    const storedRowCount = yield select(selectors.getRowCount);
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchVehicleLogs,
      applicationId,
      vehicleId,
      filterValues,
    );
    const updatedData = {
      ...data,
      totalCount: data.totalCount || storedRowCount,
    };
    const formattedLogs = formatVehicleLogs(updatedData, filterValues.offset);

    yield put(actions.fetchVehicleLogsSuccess(formattedLogs));
    yield put(actions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchVehicleLogsFailure(error));
  }
}

export function* fetchVehicleOverview(action) {
  try {
    const { payload: vehicleId } = action;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const applications = yield select(applicationSelectors.getApplications);
    const { organizationId } = applications[applicationId];
    const { data } = yield call(
      api.fetchVehicleOverview,
      applicationId,
      vehicleId,
      organizationId,
    );

    yield put(actions.fetchVehicleOverviewSuccess(data));
  } catch (error) {
    yield put(actions.fetchVehicleOverviewFailure(error));
  }
}

export function* fetchWebhookEvents(action) {
  try {
    const {
      meta: vehicleId,
      payload: { filterValues, newPaginationModel },
    } = action;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchWebhookEventsForVehicle,
      applicationId,
      vehicleId,
      filterValues,
    );
    const results = {
      rows: data.rows,
      columns: data.cols,
      rowCount: data.paging.count,
    };

    const pageZero = newPaginationModel.page === 0;
    // Reset cursorMap whenever we fetch page 0
    if (pageZero) {
      yield put(
        actions.updateWebhookEventsCursorMap(initialState.webhookEvents.pageToNextCursorMap),
      );
    }

    yield put(actions.fetchWebhookEventsSuccess(results));

    const cursorMap = yield select(selectors.getWebhookEventCursorMap);
    yield put(actions.updateWebhookEventsCursorMap({
      ...cursorMap,
      [newPaginationModel.page]: data.paging.cursor,
    }));
    yield put(actions.updateWebhookEventsPaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchWebhookEventsFailure(error));
  }
}


export default function* rootSaga() {
  yield takeLatest(types.FETCH_WEBHOOKS_INFO_REQUEST, fetchWebhooksInfoRequest);
  yield takeLatest(types.UNSUBSCRIBE_VEHICLE_FROM_WEBHOOK_REQUEST, unsubscribeVehicleFromWebhook);
  yield takeLatest(types.FETCH_VEHICLE_INFO_REQUEST, fetchVehicleInfo);
  yield takeLatest(types.FETCH_VEHICLE_LOGS_REQUEST, fetchVehicleLogs);
  yield takeLatest(types.FETCH_VEHICLE_OVERVIEW_REQUEST, fetchVehicleOverview);
  yield takeLatest(types.FETCH_WEBHOOK_EVENTS_REQUEST, fetchWebhookEvents);
}
