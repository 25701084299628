import {
  AppBar as MuiAppBar,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  styled,
} from '@mui/material';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar, // default mui zIndex for AppBar (1100)
  height: theme.height.topbar,
  backgroundColor: theme.palette.common.white,
  borderBottom: theme.border.default,
  boxShadow: 'none',

  '& .MuiToolbar-root': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
  },
}));

// applies to all menus across topbar and sidebar navs
export const Menu = styled(MuiMenu)(({ theme }) => ({
  marginTop: '4px',

  '& .MuiMenu-list': {
    boxSizing: 'border-box',
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  '&#app-selector': {
    '& .MuiMenu-list': {
      padding: theme.spacing(3),
      paddingRight: `${theme.spacing(3)} !important`, // important needed to offset scrollbar
    },
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),

  '& a, & button': {
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    fontFamily: theme.typography.body2.fontFamily,
    transition: 'none',
    boxSizing: 'border-box',

    '& .icon': {
      width: '18px',
      height: '18px',
      marginRight: theme.spacing(1),
      marginTop: '-2px',
    },

    '& span, & p': {
      fontFamily: 'inherit',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      '&:hover': {
        textDecoration: 'none',
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[150],
      textDecoration: 'none',
    },

    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },

  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.grey[150],

    '& p': {
      fontFamily: theme.typography.bold.fontFamily,
    },
  },
}));

