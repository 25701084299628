const staticText = {
  heading: 'Customize Connect',
  description: 'Preview and configure what users will see when they connect their vehicle to your app. Changes will be reflected immediately once published.',
  toggleButton: 'Select all',
  clearAll: 'Clear all',
  filter: 'Filters',
  backButton: 'Back',
  publishAllChanges: 'Publish all changes',
  successMessage: 'Connect customizations updated!',
  featureGated: {
    description: 'Upgrade to customize your customer experience & more',
    contactOwnersToUpgrade: 'Contact a team owner to upgrade to customize your customer experience & more',
    buttonText: 'Upgrade to publish changes',
  },
  gateFeatureByPlan: {
    freePlan: {
      description: 'Filtering by engine type is available for Build and Custom plans. Selecting specific brands is only available to Custom plans.',
    },
    buildPlan: {
      description: 'Selecting specific brands is only available to Custom plans.',
    },
    link: '|+|Upgrade now@@/team/billing|+| to access these features.',
  },
  appearance: {
    heading: 'Appearance',
    description: 'Configure the application name, logo, and theme your users will see when connecting their vehicle to your application.',
    inputs: {
      appNameInput: {
        displayName: 'Application name',
      },
      themeInput: {
        displayName: 'Display theme',
        options: [
          {
            label: 'Device settings',
            value: 'system',
          },
          {
            label: 'Light theme',
            value: 'light',
          },
          {
            label: 'Dark theme',
            value: 'dark',
          },
        ],
      },
      logoInput: {
        displayName: 'Logo',
        description: 'Upload your logo to display it at the top of the page. Otherwise the default Connect visual will be displayed.',
        requirements: [
          'Supported formats: PNG, JPG, or WEBP',
          'Max file size: 100kb',
        ],
        uploadLogo: 'Upload logo',
        replaceText: 'Replace logo',
        removeText: 'Remove logo',
        imageAltText: 'Your app logo',
        acceptedTypes: ['image/webp', 'image/png', 'image/jpeg'],
        maxSizeInBytes: 100000,
      },
    },
  },
  brandManagement: {
    description: 'Configure which vehicle brands your users will be able to select from in Smartcar Connect.',
    displayedBrands: 'displayed brands',
    displayedOrder: 'displayed order',
    filterByEngineType: 'Filter by engine type',
    newlyAddedBrands: 'display new brands automatically',
    heading: 'Brands',
    selectAll: 'Select all',
    disabledIconText: 'There are no vehicles from this brand that match your filters.',
    apply: 'Apply',
    addRule: 'Add rule',
    displayedOrderRadio: {
      popularity: {
        label: 'Popularity (recommended)',
        tooltip: 'We recommend using rankings by popularity if you are building applications around auto insurance, roadside assistance, car sharing, and fleet management.',
      },
      alphabetical: {
        label: 'Alphabetical',
        tooltip: 'Alphabetical rankings may negatively impact your app’s conversion rates with Smartcar Connect.',
      },
    },
    displayNewlyAddedBrands: 'Display new brands automatically',
    singleBrandSuggestion: {
      heading: 'Dig you know?',
      text: 'When selecting only one brand, consider using |+|Brand Select mode@@https://smartcar.com/docs/connect/advanced-config/flows|+| instead to streamline the connection process for your application users.',
      close: 'Got it',
    },
  },
  connectPreview: {
    selectBrand: 'Select your brand',
    backToApplication: 'Back to application',
    brandNotPresent: "I don't see my brand...",
    errors: {
      brandIncompatible: {
        header: 'Brand not supported',
        description: 'Unfortunately, the brands supported by this application are not compatible with the country you are located in. Please contact the application owner to learn more.',
        cta: 'Back to application',
      },
      noSelectedMake: {
        description: 'Please select at least one brand from the list to see a preview of Connect.',
      },
    },
    search: 'Search',
    footerText: '{{applicationName}} uses Smartcar to connect to your vehicle. Logos and brand names are for identification purposes only and do not indicate endorsement of or affiliation with Smartcar or {{applicationName}}',
  },
  countries: {
    heading: 'Countries',
    // eslint-disable-next-line max-len
    // description: 'Select the countries where users can establish vehicle connections when launching the Connect flow. |*|bold@@Please note|*| that while connections can be made from these countries, Smartcar support or vehicle issue resolution in some locations may not be covered*. Changes will be applied immediately after publishing.',
    description: 'Configure which countries users will be able to select when launching Smartcar Connect. Country selection impacts which brands are shown on the brand selector screen (e.g. Renault is only available in Europe).',
    subDescription: 'The following countries are officially supported by Smartcar:',
    footer: '* Smartcar operates in the United States, Canada, and the following European countries: Austria, Belgium, Bulgaria, Croatia, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Switzerland, and United Kingdom.',
  },
  errors: {
    applicationMakes: 'Sorry, we encountered an error fetching the list of brands. Please try again later.',
    connectConfig: 'Sorry, we encountered an error fetching your previous configuration settings. Please try again later.',
    makesByCountry: 'Sorry, we encountered an error fetching the list of brands for the selected country. Please try again later',
    updatingConnectConfig: 'Sorry, we encountered an error updating your configuration settings. Please try again later.',
    updatingAppLogo: 'Sorry, we encountered an error uploading your app logo. Please try again later.',
    brandManagementCapabilities: 'Sorry, we encountered an error fetching the brand management capabilities. Please try again later.',
  },
};

export default staticText;
