import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColorBox = styled('div', {
  shouldForwardProp: prop => prop !== 'color',
})(({ theme, color }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  backgroundColor: theme.palette.charts[color].fill,
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.charts[color].stroke}`,
  borderRadius: theme.border.radius,
}));

export const FormattedTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSizes.xs,
  color: theme.palette.grey[600],
}));

export const TooltipWrapper = styled(Paper)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  padding: theme.spacing(2),
  minWidth: '200px',
  fontSize: theme.typography.fontSizes.xs,
}));
