const staticText = {
  billingInformation:
    {
      header: 'Billing information',
      description: 'Your information as it appears in the Smartcar billing portal',
      ctaButtonText: 'Update',
      keys: {
        name: 'Name',
        email: 'Email',
        address: 'Address',
        phoneNumber: 'Phone number',
        taxId: 'Tax ID',
        paymentMethod: 'Payment method',
        nextPaymentDue: 'Next payment due',

        // Sales led rows/additional info
        billingName: 'Billing name',
        contractStartDate: 'Contract start date',
        contractRenewalDate: 'Contract renewal date',
      },
    },
  invoiceHistory: {
    header: 'Invoice history',
    description: 'Your most recent invoices.',
    ctaButtonText: 'View all',
  },
  noPaymentMethodOnFile: 'No payment method on file',
  formatPaymentMethodCard: (capitalizedBrand, last4, expMonth, expYear) => {
    return `${capitalizedBrand} ending in ${last4} · Expires ${expMonth.toString().padStart(2, '0')}/${expYear}`;
  },
  formatPaymentMethodLink: (email) => {
    return `Link account - ${email}`;
  },
  formatPaymentMethodBank: (last4) => {
    return `Account ending in ${last4}`;
  },
};

export default staticText;
