import dateUtils from '../../../../../../../utils/dateUtils';

const formatFilterValues = (filterValues) => {
  const processCheckboxes = (checkboxes) => {
    const options = Object.keys(checkboxes);
    const selectedOptions = options.filter(option => checkboxes[option] === true);
    if (selectedOptions.length === 0 || selectedOptions.length === options.length) {
      return undefined;
    }
    return selectedOptions;
  };

  const formatter = {
    start: date => dateUtils.getStartOfDayUtc(date).toISOString(),
    end: date => dateUtils.getEndOfDayUtc(date).toISOString(),
    errorTypes: processCheckboxes,
    requestMethods: processCheckboxes,
    webhookTypes: processCheckboxes,
    statusCodes: (checkboxes) => {
      const processed = processCheckboxes(checkboxes);
      return processed ? processed.map(Number) : undefined;
    },
  };

  const formattedFilters = {};
  Object.entries(filterValues).forEach(([key, value]) => {
    const format = formatter[key];
    formattedFilters[key] = format ? format(value) : value;
  });

  return formattedFilters;
};

export default formatFilterValues;
