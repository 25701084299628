import React from 'react';

import SelectIcon from '../../../../../../../../../../components/SelectIcon';
import { BevEngineIcon, HevEngineIcon, IceEngineIcon, PhevEngineIcon } from '../../../../../../../../../../assets/icons/engineType';

export const iconMap = {
  ICE: <IceEngineIcon />,
  PHEV: <PhevEngineIcon />,
  BEV: <BevEngineIcon />,
  HEV: <HevEngineIcon />,
};

export const engineTypeLabel = {
  ICE: <span className="engine-type-option"><IceEngineIcon /> Internal combustion engine (ICE)</span>,
  PHEV: <span className="engine-type-option"><PhevEngineIcon /> Plug-in hybrid vehicle (PHEV)</span>,
  BEV: <span className="engine-type-option"><BevEngineIcon /> Battery electric vehicle (BEV)</span>,
  HEV: <span className="engine-type-option"><HevEngineIcon /> Hybrid electric vehicle (HEV)</span>,
};

export const engineFilters = [
  {
    filterKey: 'engineType',
    icon: SelectIcon,
    id: 'brand-filter-engineTypes',
    minWidth: '354px',
    marginTop: '8px',
    marginBottom: '8px',
    height: '56px',
  },
];

export const getEngineTypeFilterStatusAndTitle = (options, selectedOptions) => {
  let menuItemWrapperTitle = [];
  let isFilterActive;

  if (selectedOptions.length === 0) {
    menuItemWrapperTitle = 'All Types';
    isFilterActive = false;
  }

  if (selectedOptions.length > 0) {
    options.forEach((option) => {
      if (selectedOptions.includes(option.type)) {
        const label = <React.Fragment>{iconMap[option.type]} {option.type}</React.Fragment>;
        menuItemWrapperTitle.push(label);
      }
    });

    if (menuItemWrapperTitle.length === 0) {
      menuItemWrapperTitle = 'All Types';
      isFilterActive = false;
    } else {
      // todo add a coma behind every selection
      menuItemWrapperTitle = menuItemWrapperTitle.map((label, index) => (
        <React.Fragment key={label}>
          {label}
          {index < menuItemWrapperTitle.length - 1 && ', '}
        </React.Fragment>
      ));

      isFilterActive = true;
    }
  }


  return {
    isFilterActive,
    menuItemWrapperTitle,
  };
};

export default {
  getEngineTypeFilterStatusAndTitle,
  iconMap,
  engineTypeLabel,
  engineFilters,
};
