import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { reportToSegment, types } from '@smartcar/morse';
import { Box } from '@mui/material';
import {
  Footer,
  Header,
} from '../../components';
import {
  Login,
  SignUp,
  ForgotPassword,
  ResetPassword,
} from './components';
import theme from '../../global-styles/theme';

export default class Authentication extends React.Component {
  componentDidMount() {
    reportToSegment(types.PAGE, 'Authentication');
  }

  render() {
    return (
      <Box sx={{ backgroundColor: theme.palette.grey[100], minHeight: '100vh' }}>
        <Header />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Redirect to="/login" />
        </Switch>
        <Footer />
      </Box>
    );
  }
}
