import { arrayOf, bool, number, shape, string } from 'prop-types';
import React from 'react';
import { Typography, Box } from '@mui/material';

import { ConnectInsightsFunnel } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/ConnectInsights/connectInsights';

const Statistics = ({
  funnel,
  funnelGated,
  isFetching,
  showLoading,
}) => {
  return (
    <Box my={3}>
      <Typography variant="h2" id="heading-conversion-rate" width="fit-content">
        {staticText.funnel.heading}
      </Typography>
      <Typography mt={1} mb={3}>
        {staticText.funnel.description}
      </Typography>
      <ConnectInsightsFunnel
        funnel={funnel}
        funnelGated={funnelGated}
        isFetching={isFetching}
        showLoading={showLoading}
      />
    </Box>
  );
};

export default Statistics;

Statistics.propTypes = {
  funnelGated: bool.isRequired,
  funnel: arrayOf(shape({
    count: string.isRequired,
    stage: string.isRequired,
    order: number.isRequired,
    displayName: string.isRequired,
    percent: number.isRequired,
  })).isRequired,
  isFetching: bool.isRequired,
  showLoading: bool.isRequired,
};
