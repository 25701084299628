import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Toast } from '../../../../components';
import staticText from '../../../../localization/Authentication/userSecurity';
import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';
import MfaSettings from './components/MfaSettings';

const UserSecurity = ({
  actions: {
    forgotPasswordRequest,
    resetErrors,
  },
  forgotPasswordSuccess,
  inProgress,
  session: {
    userContext,
  },
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const userObj = {
    firstName: userContext.firstName,
    lastName: userContext.lastName,
    username: userContext.email,
  };

  useEffect(() => {
    resetErrors();
    reportToSegment(types.PAGE, 'User Security');
  }, []);

  useEffect(() => {
    if (forgotPasswordSuccess && formSubmitted) {
      Toast(`An email has been sent to ${userContext.email}`);
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
  }, [inProgress]);

  const handleResetPassword = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    forgotPasswordRequest(userObj);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Reset password' });
  };

  return (
    <Box sx={{ maxWidth: '700px', minWidth: '650px' }}>
      <ApplicationHeader heading={staticText.title} />
      <Section>
        <Typography variant="h3" sx={{ mb: 3 }}>{staticText.resetPassword.title}</Typography>
        <Button
          id="btnResetPassword"
          variant="text"
          color="primary"
          type="button"
          disabled={disableFields}
          onClick={handleResetPassword}
        >
          {staticText.resetPassword.action}
        </Button>
      </Section>
      {userContext.loginProvider === 'EmailPassword' && <MfaSettings />}
    </Box>
  );
};

export default UserSecurity;


UserSecurity.propTypes = {
  actions: shape({
    forgotPasswordRequest: func.isRequired,
  }).isRequired,
  inProgress: bool,
  forgotPasswordSuccess: bool.isRequired,
  session: shape({
    userContext: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
      email: string.isRequired,
    }).isRequired,
  }).isRequired,
};

UserSecurity.defaultProps = {
  inProgress: false,
};

