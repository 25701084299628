import React, { useState } from 'react';
import { arrayOf, objectOf, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { ApplicationLabel, UpsellModal } from '../../../../../../components';
import { AppSelectorButton, OrganizationLabel, OrganizationGroup } from './styles';
import { Menu, MenuItem } from '../../styles';
import FEATURES from '../../../../../../services/featureGate/features';
import application from '../../../../../../localization/Application/application';
import ApplicationLink from './components/ApplicationLink';
import { getApplicationsByOrg } from './util';

const AppSelector = ({
  applicationId,
  applications,
  organizations,
  lockedApplicationsByOrg,
  pathname,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);

  const applicationsByOrg = getApplicationsByOrg(applications, organizations);

  const appIsLocked = (appId) => {
    const orgId = applications[appId].organizationId;
    return lockedApplicationsByOrg[orgId] && lockedApplicationsByOrg[orgId].includes(appId);
  };

  const handleMenuClick = (event) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: menuOpen ? 'close menu' : 'open menu',
      text: `[app selector] ${applications[applicationId].name}`,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleUpsellModal = () => {
    const segmentReport = application.upgradeModal;
    reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`],
      segmentReport);
    setModalOpen(!modalOpen);
  };

  const totalApps = Object.keys(applicationsByOrg).reduce((acc, orgId) => {
    return acc + Object.keys(applicationsByOrg[orgId].applications).length;
  }, 0);
  if (totalApps === 1) {
    return (
      <ApplicationLabel application={applications[applicationId]} button />
    );
  }

  return (
    <div>
      <AppSelectorButton
        id="app-selector-button"
        onClick={handleMenuClick}
        aria-controls={menuOpen ? 'app-selector' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        open={menuOpen}
      >
        <ApplicationLabel application={applications[applicationId]} button />
        <ExpandMoreRoundedIcon />
      </AppSelectorButton>
      <Menu
        id="app-selector"
        open={menuOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'app-selector-button',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: '500px',
              width: '360px',
            },
          },
        }}
      >
        {Object.keys(applicationsByOrg).map((orgId) => {
          const orgName = applicationsByOrg[orgId].name;
          const apps = applicationsByOrg[orgId].applications;
          return (
            <OrganizationGroup key={`org-${orgId}`}>
              <OrganizationLabel component="p" variant="caption">
                {orgName}
              </OrganizationLabel>
              {Object.keys(apps).map((appId) => {
                const app = apps[appId];
                const locked = appIsLocked(appId);
                return (!locked &&
                  <MenuItem key={`app-${app.id}`} selected={app.id === applicationId}>
                    <ApplicationLink
                      key={`app-${app.id}`}
                      application={app}
                      pathname={pathname}
                      handleClick={handleClose}
                      setModalOpen={setModalOpen}
                    />
                  </MenuItem>
                );
              })}
            </OrganizationGroup>
          );
        })}
      </Menu>
      {modalOpen && (
        <UpsellModal
          toggleModal={toggleUpsellModal}
          feature={`${FEATURES.APPLICATIONS}-locked`}
        />
      )}
    </div>
  );
};

export default AppSelector;

AppSelector.propTypes = {
  applicationId: string.isRequired,
  applications: objectOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    logoUrl: string,
  })).isRequired,
  organizations: objectOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  lockedApplicationsByOrg: shape({
    [string]: arrayOf(string),
  }).isRequired,
  pathname: string.isRequired,
};
