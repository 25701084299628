/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, instanceOf, number, objectOf, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { LogTable, Spinner, PermissionGate, NoResults } from '../../../../../../../../components';
import { menuItems } from '../../../../../../../../localization/Application/VehicleManagement/webhooksTable';
import { Section } from '../../styles';
import { useDelay } from '../../../../../../../../hooks';
import UnsubscribeModal from '../UnsubscribeModal';
import { getLogRestrictions } from '../../../../../../utils';
import FEATURES from '../../../../../../../../services/featureGate/features';
import formatFilterValues from '../../utils/formatFilterValues';
import { initialState, PAGE_SIZE } from '../../reducers';
import vehicleDetailsText from '../../../../../../../../localization/Application/VehicleManagement/vehicleDetails';
import { FiltersBar } from './components';
import validateFilterValues from '../../utils/validateFilterValues';
import applicationText from '../../../../../../../../localization/Application/application';
import { ErrorsList } from '../../../shared/styles';

const VehicleWebhooks = ({
  actions: {
    fetchWebhooksInfoRequest,
    fetchWebhookEventsRequest,
    unsubscribeVehicleFromWebhookRequest,
    updateWebhookEventsFilterValues,
  },
  webhooksInfo,
  isFetchingWebhooksInfo,
  applicationId,
  match,
  errors,
  webhookEvents,
  featureSetId,
  rolePermissions,
}) => {
  const [currentModal, setCurrentModal] = useState(null);
  const [formError, setFormError] = useState('');
  const { afterDelay: showLoading, resetTimer } = useDelay();
  const { webhookEventsEnabled } = useFlags();

  const theme = useTheme();

  const { vehicleId } = match.params;
  const logRestrictions = getLogRestrictions(featureSetId, FEATURES.LOG_DAYS);
  const { maxDate, defaultStartDate } = logRestrictions;
  const {
    webhookEvents: {
      paginationModel: defaultPagination,
      filterValues: defaultFilterValues,
    },
  } = initialState;

  const canWriteBilling = rolePermissions.includes('write_billing');

  const handleUnsubscribe = (webhookId) => {
    unsubscribeVehicleFromWebhookRequest(vehicleId, webhookId);
    resetTimer();
  };

  const openUnsubcribeModal = (webhook) => {
    setCurrentModal(
      <PermissionGate
        dashboardPermission="write_vehicle_management"
        disabled
      >
        <UnsubscribeModal
          closeModal={() => setCurrentModal(null)}
          unsubscribeVehicleFromWebhook={handleUnsubscribe}
          webhook={webhook}
        />
      </PermissionGate>,
    );
  };

  const moreActions = {
    menuItems,
    actionMap: {
      unsubscribe: openUnsubcribeModal,
    },
  };

  const getPageInfo = (page, filters) => {
    const cursor = webhookEvents.pageToNextCursorMap[page - 1];
    if (page === 0 || cursor) {
      const formattedFilterValues = {
        ...formatFilterValues(filters),
        limit: PAGE_SIZE,
        ...(cursor && { cursor }),
      };

      fetchWebhookEventsRequest(
        {
          vehicleId,
          filterValues: formattedFilterValues,
          newPaginationModel: {
            page,
            pageSize: PAGE_SIZE,
          },
        });
    }
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    getPageInfo(newPaginationModel.page, webhookEvents.filterValues);
    const [label, text] = newPaginationModel.page > webhookEvents.paginationModel.page ?
      ['forward', '[vehicleDetails - webhookEvents - right arrow icon]'] : ['back', '[vehicleDetails - webhookEvents - left arrow icon]'];
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label, text });
    resetTimer();
  };

  const handleApplyFilters = (newFilterValues) => {
    const validated = validateFilterValues(newFilterValues, logRestrictions);
    if (validated.error) {
      setFormError(validated.error);
      return;
    }
    setFormError('');
    getPageInfo(defaultPagination.page, newFilterValues);
    updateWebhookEventsFilterValues(newFilterValues);
    resetTimer();
  };

  const handleResetAll = () => {
    const resetFilterValues = {
      ...defaultFilterValues,
      start: moment(defaultStartDate).utc(),
      end: moment(maxDate).utc(),
    };
    getPageInfo(defaultPagination.page, resetFilterValues);
    updateWebhookEventsFilterValues(resetFilterValues);
    resetTimer();
  };

  useEffect(() => {
    if (webhookEventsEnabled) {
      const { filterValues } = webhookEvents;
      const initializedFilterValues = {
        ...filterValues,
        start: filterValues.start ? filterValues.start : moment(defaultStartDate).utc(),
        end: filterValues.end ? filterValues.end : moment(maxDate).utc(),
      };
      getPageInfo(webhookEvents.paginationModel.page, initializedFilterValues);
      updateWebhookEventsFilterValues(initializedFilterValues);
    }
    fetchWebhooksInfoRequest(vehicleId);
  }, [webhookEventsEnabled]);

  const errorsToDisplay = [errors.webhooks, webhookEvents.errors.events].filter(Boolean);

  return (
    <React.Fragment>
      {currentModal}
      {errorsToDisplay.length > 0 && (
        <ErrorsList alignItems="flex-start">
          {errorsToDisplay.map((message) => {
            return (
              <li key={message}>
                <Typography variant="caption" color="error">
                  {message}
                </Typography>
              </li>
            );
          })}
        </ErrorsList>
      )}
      {
        !webhooksInfo.rows &&
        isFetchingWebhooksInfo &&
          <Section minHeight="200px">
            <Spinner size="small" additionalClassNames="vehicle-overview" delay={200} />
          </Section>
      }
      {
        webhooksInfo.rows &&
        webhooksInfo.rows.length > 0 && (
          <div style={{ marginTop: theme.spacing(3) }}>
            <LogTable
              getRowId={row => row.webhookId}
              pageInfo={webhooksInfo}
              pageSizeOptions={[10]}
              paginationModel={{
              page: 0,
              // TODO: don't limit this to 10 if it's not paginated?
              pageSize: 10,
            }}
              handlePaginationModelChange={() => {}}
              loading={showLoading && isFetchingWebhooksInfo}
              moreActions={moreActions}
              hideFooter
            />
          </div>
        )
      }
      {
        webhooksInfo.rows &&
        webhooksInfo.rows.length === 0 &&
          <Section>
            <NoResults
              text={applicationText.paintedDoor.noWebhooks}
              outlined={false}
              link={{
                path: `/apps/${applicationId}/webhooks?add-webhook=true`,
                text: applicationText.paintedDoor.createWebhook,
                trackingContext: 'Vehicle Details - Webhooks tab',
              }}
              containerProps={{ height: '400px' }}
            />
          </Section>
      }
      {webhookEventsEnabled &&
      <div style={{ marginTop: theme.spacing(3) }}>
        <Section minHeight="300px">
          <Typography variant="h2" mb={3}>{vehicleDetailsText.webhooks.logs.header}</Typography>
          <FiltersBar
            filterValues={webhookEvents.filterValues}
            logRestrictions={logRestrictions}
            handleApplyFilters={handleApplyFilters}
            handleResetAll={handleResetAll}
            setFormError={setFormError}
            canWriteBilling={canWriteBilling}
          />
          {formError &&
            <Typography variant="caption" color="error">
              {formError}
            </Typography>
          }
          {!webhookEvents.pageInfo.rows && webhookEvents.isFetching &&
            <Spinner size="small" additionalClassNames="vehicle-overview" delay={200} />
          }
          {webhookEvents.pageInfo.rows && webhookEvents.pageInfo.rows.length > 0 &&
            <div style={{ marginTop: theme.spacing(3) }}>
              <LogTable
                pageInfo={webhookEvents.pageInfo}
                pageSizeOptions={[PAGE_SIZE]}
                paginationModel={webhookEvents.paginationModel}
                handlePaginationModelChange={handlePaginationModelChange}
                loading={showLoading && webhookEvents.isFetching}
              />
            </div>
          }
          {
            webhookEvents.pageInfo.rows &&
            webhookEvents.pageInfo.rows.length === 0 &&
              <NoResults
                text={applicationText.paintedDoor.noData}
                outlined={false}
                containerProps={{ height: '400px' }}
              />
          }
        </Section>
      </div>}
    </React.Fragment>
  );
};

export default withRouter(VehicleWebhooks);

VehicleWebhooks.propTypes = {
  actions: shape({
    fetchWebhooksInfoRequest: func.isRequired,
    fetchWebhookEventsRequest: func.isRequired,
    unsubscribeVehicleFromWebhookRequest: func.isRequired,
    updateWebhookEventsFilterValues: func.isRequired,
  }).isRequired,
  webhooksInfo: shape({
    rows: array,
    columns: array,
    rowCount: number,
  }).isRequired,
  isFetchingWebhooksInfo: bool.isRequired,
  applicationId: string.isRequired,
  match: shape({
    params: shape({
      vehicleId: string.isRequired,
    }),
  }).isRequired,
  errors: shape({
    webhooks: string,
  }).isRequired,
  webhookEvents: shape({
    isFetching: bool.isRequired,
    filterValues: shape({
      start: instanceOf(moment),
      end: instanceOf(moment),
      statusCodes: objectOf(bool),
    }).isRequired,
    pageInfo: shape({
      rows: array.isRequired,
      columns: array.isRequired,
      rowCount: number.isRequired,
    }),
    paginationModel: shape({
      page: number,
      pageSize: number,
    }).isRequired,
    pageToNextCursorMap: objectOf(string),
    errors: shape({
      events: string,
    }),
  }).isRequired,
  featureSetId: string.isRequired,
  rolePermissions: arrayOf(string).isRequired,
};
