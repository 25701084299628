import { connect } from 'react-redux';

import ApplicationLink from './ApplicationLink';

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.organizations;
  return {
    lockedApplications: state.applications.lockedApplications[selectedOrganization],
  };
};

export default connect(mapStateToProps, null)(ApplicationLink);
