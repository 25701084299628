/* eslint-disable react/forbid-prop-types */
/* istanbul ignore file */
import React from 'react';
import { arrayOf, func, object, shape, string } from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import staticText from '../../../../../../../../localization/Application/connect-config';

import { Checkbox, PermissionGate, Spinner } from '../../../../../../../../components';
import BrandOptIn from '../BrandOptIn/BrandOptIn';
import EngineFilter from './components/EngineFilter';

const BrandList = ({
  applicationMakes,
  selectedMakes,
  updateSelectedMakes,
  applicationMakesError,
  renderErrorMessage,
  updateCustomizations,
  selectedNewBrandOptIn,
  brandManagementFilterOptions,
  selectedBrandManagementFilters,
  disabledBrands,
}) => {
  const theme = useTheme();

  const handleChange = (event) => {
    let updatedCheckboxList = [...selectedMakes];

    if (event.target.checked) {
      updatedCheckboxList = [...selectedMakes, event.target.name];
    } else {
      updatedCheckboxList.splice(selectedMakes.indexOf(event.target.name), 1);
    }

    updateSelectedMakes(updatedCheckboxList);
  };


  const selectOrClearAll = ({ target: { checked } }) => {
    if (checked) {
      const updatedMakes = applicationMakes
        .filter(make => !disabledBrands.includes(make.make))
        .map(make => make.make);
      updateSelectedMakes(updatedMakes);
    } else {
      updateSelectedMakes([]);
    }

    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: '[connect config - brand management] - Select all',
    });
  };

  // isAllSelected & isSomeSelected needed to determine Select All checkbox icon
  const updateSelectAllCheckbox = () => {
    const filteredApplicationMakes =
      applicationMakes.filter(make => !disabledBrands.includes(make.make)).map(make => make.make);

    const isAllSelected =
      filteredApplicationMakes.length === selectedMakes.length &&
      filteredApplicationMakes.every(make => selectedMakes.includes(make));
    const isSomeSelected = selectedMakes.length > 0;

    return {
      isAllSelected,
      isSomeSelected,
    };
  };

  const {
    isAllSelected,
    isSomeSelected,
  } = updateSelectAllCheckbox();

  return applicationMakesError ? (
    renderErrorMessage(applicationMakesError)
  ) : (
    <React.Fragment>
      <EngineFilter
        brandManagementFilterOptions={brandManagementFilterOptions}
        selectedBrandManagementFilters={selectedBrandManagementFilters}
        updateCustomizations={updateCustomizations}
      />
      <BrandOptIn
        updateCustomizations={updateCustomizations}
        selectedNewBrandOptIn={selectedNewBrandOptIn}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY={theme.spacing(2)}
      />
      <Box minHeight="360px">
        {applicationMakes.length === 0 &&
          <Box display="flex" justifyContent="center" pt={14}>
            <Spinner size="small" />
          </Box>
        }
        {
          <Checkbox
            inputName="selectAll"
            text={staticText.brandManagement.selectAll}
            handleChange={selectOrClearAll}
            checked={isAllSelected && isSomeSelected}
            partiallySelected={isSomeSelected}
          />
        }
        <Divider sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }} />
        {applicationMakes.length > 0 &&
          <Grid
            container
            spacing={theme.spacing(2)}
            sx={{
            gridTemplateColumns: 'repeat(2, 1fr)',
            textAlign: 'left',
            textWrap: 'nowrap',
           }}
          >
            {applicationMakes.map(applicationMake => (
              <Grid
                key={applicationMake.make}
                item
                xs={6}
                sx={{
                display: 'flex',
                alignItems: 'flex-start',
                fontSize: '14px',
                marginTop: '10px',
               }}
              >
                <PermissionGate
                  dashboardPermission="write_connect_configuration"
                  disabled
                >
                  <Checkbox
                    inputName={applicationMake.make}
                    text={applicationMake.displayName}
                    handleChange={handleChange}
                    checked={selectedMakes.includes(applicationMake.make)}
                    disabled={disabledBrands.includes(applicationMake.make)}
                    icon={InfoOutlined}
                    disabledIcon={disabledBrands.includes(applicationMake.make)}
                    disabledIconText={staticText.brandManagement.disabledIconText}
                  />
                </PermissionGate>
              </Grid>
            ))}
          </Grid>
        }
      </Box>
    </React.Fragment>
  );
};

export default BrandList;

BrandList.propTypes = {
  applicationMakes: arrayOf(shape({
    make: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  selectedMakes: arrayOf(string),
  updateSelectedMakes: func.isRequired,
  updateCustomizations: func.isRequired,
  applicationMakesError: string,
  renderErrorMessage: func.isRequired,
  selectedNewBrandOptIn: string.isRequired,
  brandManagementFilterOptions: object.isRequired,
  disabledBrands: arrayOf(string).isRequired,
  selectedBrandManagementFilters: object.isRequired,
};

BrandList.defaultProps = {
  selectedMakes: [],
  applicationMakesError: '',
};
