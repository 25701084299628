import React, { useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { Button, TextField, Typography, useTheme } from '@mui/material';

import { URI_LENGTH_LIMIT } from '../../../../../Configuration/components/RedirectUriList/components/RedirectUriForm/RedirectUriForm';
import staticText from '../../../../../../../../localization/Application/connect-playground';
import redirectUriValidator from '../../../../../../../../services/validators/redirectUriValidator';
import { Modal } from '../../../../../../../../components';

const AddRedirectUriModal = ({
  clientId,
  redirectUris,
  toggleModal,
  onSubmit,
}) => {
  const theme = useTheme();
  const [uriInputState, setUriInputState] = useState({
    value: '',
    isValid: false,
    inputVisited: false,
    errorMessage: '',
  });

  const handleChange = (e) => {
    const { value: uri } = e.target;
    if (uri.length > URI_LENGTH_LIMIT) {
      setUriInputState({
        value: uri,
        isValid: false,
        inputVisited: true,
        errorMessage: `The uri limit is ${URI_LENGTH_LIMIT} characters`,
      });
      return;
    }
    try {
      redirectUriValidator(uri, clientId, redirectUris);
      setUriInputState(prev => ({
        ...prev, value: uri, isValid: true, errorMessage: '',
      }));
    } catch (err) {
      setUriInputState(prev => ({
        ...prev, value: uri, isValid: false, errorMessage: err.message,
      }));
    }
  };

  const handleBlur = () => {
    setUriInputState(prev => ({
      ...prev,
      inputVisited: true,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(uriInputState.value);
    toggleModal();
  };

  const modalContent = (
    <form onSubmit={handleSubmit}>
      <Typography sx={{ marginBottom: theme.spacing(3) }}>
        {staticText.form.basic.redirectUri.modal.description}
      </Typography>
      <TextField
        id="add-redirect-uri-modal-input"
        autoFocus
        label={staticText.form.basic.redirectUri.modal.label}
        placeholder={staticText.form.basic.redirectUri.modal.placeholder}
        variant="outlined"
        error={
          !uriInputState.isValid && uriInputState.inputVisited && uriInputState.value.length > 0
        }
        helperText={
          !uriInputState.isValid && uriInputState.inputVisited && uriInputState.errorMessage
        }
        onChange={handleChange}
        onBlur={handleBlur}
        value={uriInputState.value}
        fullWidth
      />
    </form>
  );

  const modalActions = (
    <Button
      variant="contained"
      onClick={handleSubmit}
      id="add-redirect-uri-modal-submit"
      disabled={!uriInputState.isValid}
      type="submit"
    >{staticText.form.basic.redirectUri.modal.button}
    </Button>
  );

  return (
    <Modal
      toggleModal={toggleModal}
      title={staticText.form.basic.redirectUri.modal.title}
      content={modalContent}
      actions={modalActions}
      divider={false}
    />
  );
};

export default AddRedirectUriModal;

AddRedirectUriModal.propTypes = {
  clientId: string.isRequired,
  redirectUris: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  toggleModal: func.isRequired,
};
