import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled('div')(({ theme }) => ({
  width: '200px',
  padding: theme.spacing(1.5),
  marginRight: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  border: theme.border.default,
  borderRadius: theme.border.radius,
  position: 'relative',

  '&:last-of-type': {
    marginRight: '0',
  },
}));

export const Icon = styled('div')(({ theme }) => ({
  height: '32px',
  width: '32px',
  marginBottom: theme.spacing(1.5),

  img: {
    width: '100%',
    height: '100%',
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.monospace,
  textTransform: 'uppercase',
}));

export const Value = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
}));

export const EditTripButton = styled(Button)(({ theme }) => ({
  minWidth: '0',
  padding: '0',
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
  height: '18px',
  width: '18px',

  img: {
    height: '100%',
    width: '100%',
  },
}));
