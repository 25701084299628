import React from 'react';
import { bool, shape, string } from 'prop-types';
import { styled, Typography, useTheme } from '@mui/material';

export const logoStyles = theme => ({
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  height: '28px',
  width: '28px',
  minWidth: '28px',
  borderRadius: theme.border.radius,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  marginRight: theme.spacing(1),
  marginTop: '-2px',
  border: `1px solid ${theme.palette.grey[100]}`,
  boxSizing: 'border-box',

  span: {
    marginRight: '0',
    marginBottom: '-2px',
    fontSize: '1rem',
  },
});

const AppLogoWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'logo' || prop !== 'locked' || prop !== 'button',
})(({
  theme, logo, button, locked,
}) => ({
  ...logoStyles(theme),

  ...(button && {
    width: '36px',
    height: '36px',
  }),

  ...(!logo && {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
  }),

  img: {
    width: '100%',
  },

  ...(locked && {
    opacity: 0.5,
  }),
}));

export const AppName = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 1,
  width: '100%',

  'p, span': {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

const ApplicationLabel = ({ application, button, locked }) => {
  const theme = useTheme();
  return (
    <AppName>
      <AppLogoWrapper logo={Boolean(application.logoUrl)} button={button} locked={locked}>
        {application.logoUrl ?
          <img src={application.logoUrl} alt={application.name} /> :
          <span>{application.name[0].toUpperCase()}</span>}
      </AppLogoWrapper>
      <Typography
        variant={button ? 'bold' : 'body2'}
        sx={{ color: locked ? theme.palette.text.disabled : theme.palette.text.primary }}
      >{application.name}
      </Typography>
    </AppName>
  );
};

export default ApplicationLabel;

ApplicationLabel.propTypes = {
  application: shape({
    logoUrl: string,
    name: string.isRequired,
  }).isRequired,
  button: bool,
  locked: bool,
};

ApplicationLabel.defaultProps = {
  button: false,
  locked: false,
};
