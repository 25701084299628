import { Button, styled, Typography } from '@mui/material';

export const AppSelectorButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'open',
})(({ open, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.75, 1.5),
  color: theme.palette.text.primary,
  width: '100%',
  maxWidth: '260px',
  minWidth: '150px',
  boxSizing: 'border-box',

  '.MuiTypography-root': {
    marginRight: theme.spacing(1),
    maxWidth: '168px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[150],
    textDecoration: 'none',
  },

  svg: {
    transition: 'transform .25s ease',

    ...(open && {
      transform: 'rotate(180deg)',
    }),
  },
}));

export const OrganizationGroup = styled('div')(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(2),
  },
}));

export const OrganizationLabel = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  marginBottom: theme.spacing(1),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '250px',
}));
