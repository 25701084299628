import React from 'react';
import moment from 'moment-timezone';
import { arrayOf, bool, func, instanceOf, node, oneOfType, string } from 'prop-types';
import { pickersLayoutClasses } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

import { ActionList } from './components';
import { CalendarIcon, StyledDateRangePicker } from './styles';
import InlineStyledText from '../InlineStyledText';

/* istanbul ignore next */
const DateRangePicker = ({
  label,
  minDate,
  maxDate,
  onAccept,
  onChange,
  popupMessage,
  value,
  fullWidth,
  showIcon,
  customActionBar,
  hideActionBar,
}) => {
  const [start, end] = value;
  const formattedValue = [start ? moment(start).utc() : null, end ? moment(end).utc() : null];

  return (
    <div style={{ position: 'relative' }}>
      <StyledDateRangePicker
        label={label}
        minDate={minDate && moment(minDate).utc()}
        maxDate={maxDate && moment(maxDate).utc()}
        onChange={onChange}
        onAccept={onAccept}
        format="YYYY/MM/DD"
        timezone="UTC"
        slotProps={{
          layout: {
            sx: {
              [`.${pickersLayoutClasses.actionBar}`]: {
                gridColumn: 2,
                gridRow: 3,
                paddingX: 2,
                paddingBottom: 2,
              },
            },
          },
        }}
        slots={{
          field: SingleInputDateRangeField,
          actionBar: hideActionBar ? null : (props => (
            <ActionList
              {...props}
              message={<InlineStyledText text={popupMessage} />}
              customActionBar={customActionBar}
            />
          )),
        }}
        value={formattedValue}
        sx={{ width: fullWidth ? '100%' : '270px' }}
      />
      {showIcon && (
        <CalendarIcon>
          <CalendarMonthRoundedIcon color="primary" fontSize="small" />
        </CalendarIcon>
      )}
    </div>
  );
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  label: string,
  minDate: oneOfType([string, instanceOf(moment)]),
  maxDate: oneOfType([string, instanceOf(moment)]),
  onAccept: func,
  onChange: func,
  popupMessage: string,
  value: arrayOf(instanceOf(moment)).isRequired,
  fullWidth: bool,
  showIcon: bool,
  customActionBar: node,
  hideActionBar: bool,
};

DateRangePicker.defaultProps = {
  label: null,
  minDate: null,
  maxDate: null,
  onAccept: null,
  onChange: null,
  popupMessage: '',
  fullWidth: false,
  showIcon: true,
  customActionBar: null,
  hideActionBar: false,
};

