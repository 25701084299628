import React from 'react';
import { string, shape, func } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Footer, Header } from '../../components';
import { Account, TeamInvite, UserInfo } from './components';

import utils from '../utils';
import { Container } from './styles';

const Setup = ({ developerCreatedAt, developerEmailVerifiedAt, history }) => {
  const theme = useTheme();
  React.useEffect(() => {
    if (utils.forceVerification(developerCreatedAt, developerEmailVerifiedAt)) {
      history.push('/verify-email');
    }
  }, []);

  return (
    <Box sx={{ backgroundColor: theme.palette.grey[100], minHeight: '100vh' }}>
      <Header />
      <Container>
        <Switch>
          <Route path="/setup/account" component={Account} />
          <Route path="/setup/user" component={UserInfo} />
          <Route path="/setup/team-invite" component={TeamInvite} />
        </Switch>
      </Container>
      <Footer />
    </Box>
  );
};

export default Setup;

Setup.propTypes = {
  developerCreatedAt: string.isRequired,
  developerEmailVerifiedAt: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

Setup.defaultProps = {
  developerEmailVerifiedAt: null,
};
