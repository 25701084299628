import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bool, func, number, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { Toast } from '../../../../components';
import { applicationNav, generalNav, settingsNav, staticText } from '../../../../localization/Application/nav';
import { HoverText, Nav, NavItem, SidebarToggleButton, NavHeader, ExpandedMenuSubNav, ExpandedMenuSubNavButton, CollapsedMenuSubNav } from './styles';
import { capitalizeFirstLetter } from '../../utils';
import SearchBar from './components/SearchBar/SearchBar';

const getSubNavLink = (
  billingWarning,
  getNavLink,
  handleNavLinkClick,
  pathname,
  closeMenu,
) => (subNavItem) => {
  const subNavPath = getNavLink(subNavItem.path);

  const handleSubNavLinkClick = () => {
    handleNavLinkClick(subNavItem.text, subNavItem.path);
    if (closeMenu) {
      closeMenu();
    }
  };
  const isCurrent = () => {
    return subNavItem.path && pathname.includes(subNavItem.path);
  };

  return (
    <NavItem
      id={`nav-${subNavItem.text}`}
      key={subNavItem.text}
      className={isCurrent() ? 'current' : ''}
      error={billingWarning && subNavItem.text === 'billing'}
    >
      <Link
        to={subNavPath}
        onClick={handleSubNavLinkClick}
      >
        <Typography variant="body2">{capitalizeFirstLetter(subNavItem.text)}</Typography>
      </Link>
    </NavItem>
  );
};

const SidebarNav = ({
  applicationId,
  pathname,
  menuExpanded,
  toggleExpandedMenu,
  view,
  authErrorMessage,
  billingInfo,
}) => {
  const theme = useTheme();

  const subnavs = ['connect', 'team', 'personal'];
  const [subNavsOpen, setSubNavsOpen] = useState(subnavs);
  const [subNavAnchorEl, setSubNavAnchorEl] = useState(null);
  const pathParts = pathname.split('/');
  const isApplicationNav = view === 'apps';

  const {
    documentation,
    help,
    search,
    settings,
  } = generalNav;

  const navItems = isApplicationNav ? applicationNav : settingsNav;

  const getNavLink = (path) => {
    return isApplicationNav ? `/apps/${applicationId}/${path}` : `/${path}`;
  };

  const isCurrent = (text, subnav) => {
    // in the expanded menu, only highlight the parent of an
    // active link if the subnav containing the link is closed
    if (menuExpanded && subnav && subNavsOpen.includes(text)) {
      return false;
    }
    const parentText = text === 'personal' ? 'user' : text;
    return pathParts.includes(parentText.toLocaleLowerCase());
  };

  const handleNavLinkClick = (text, path) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'sidebar', style: 'inline', path, text,
    });
  };

  const handleSubNavClick = (e) => {
    e.stopPropagation();
    const subNav = e.target.innerText.toLowerCase();
    setSubNavsOpen((prev) => {
      if (prev.includes(subNav)) {
        return prev.filter(nav => nav !== subNav);
      }
      return [...prev, subNav];
    });
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: subNavsOpen.includes(subNav) ? 'close menu' : 'open menu',
      text: `[sub nav] ${e.target.innerText}`,
    });
  };

  const actionMap = {
    openHelp: () => window.CommandBar.openHelpHub(),
    openSearch: () => window.CommandBar.open(),
  };

  const billingWarning = Boolean(billingInfo.vehiclesOverLimit) ||
    Boolean(billingInfo.numOfApiViolationVehicles) ||
    Boolean(billingInfo.delinquent);

  useEffect(() => {
    if (authErrorMessage) {
      Toast(authErrorMessage, 'warn');
    }
  }, [authErrorMessage]);

  useEffect(() => {
    // if current page lives under subnav, open that subnav automatically
    subnavs.forEach((subnav) => {
      if (pathParts.includes(subnav)) {
        setSubNavsOpen(prev => [...prev, subnav === 'user' ? 'personal' : subnav]);
      }
    });
  }, [pathname]);

  const offsetBannerHeight = () => {
    const cmdAiBanner = document.querySelector('#commandbar-nudges-banner-space-container');
    const scrolledDown = window.scrollY > 0;

    if (cmdAiBanner) {
      const sideNav = document.getElementById('side-nav');
      if (scrolledDown) {
        sideNav.classList.add('ignore-banner-offset'); // Add sticky class when scrolled down
      } else {
        sideNav.classList.remove('ignore-banner-offset');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', offsetBannerHeight);
    return () => {
      window.removeEventListener('scroll', offsetBannerHeight);
    };
  }, []);

  return (
    <Nav id="side-nav" aria-label="secondary" expanded={menuExpanded}>
      <div>
        <NavHeader>
          {menuExpanded && (
            <Box marginLeft={1} marginTop="5px">
              <Link to={!pathParts.includes('applications') ? '' : '/apps'}>
                <img src="/assets/images/smartcar-logo-black.svg" alt="Smartcar Dashboard" />
              </Link>
            </Box>
          )}
          <SidebarToggleButton aria-label="nav toggle" onClick={toggleExpandedMenu}>
            {!menuExpanded && (
              <img src="/assets/icons/nav/steeringWheel.svg" className="smartcar-icon" alt="Smartcar Dashboard" />
            )}
            <img className="expand-icon" src="/assets/icons/nav/sidebar.svg" alt={`${menuExpanded ? staticText.collapse : staticText.expand} sidebar`} />
            <HoverText className="hover-text">
              {menuExpanded ? staticText.collapse : staticText.expand}
            </HoverText>
          </SidebarToggleButton>
        </NavHeader>
        <ul>
          {window.CommandBar && (
            <SearchBar openSearch={actionMap[search.action]} menuExpanded={menuExpanded} />
          )}
          {!isApplicationNav && menuExpanded && (
            <Box mb={1} ml={1} mt={2}>
              <Typography variant="body2" sx={{ fontFamily: theme.typography.bold.fontFamily }}>{staticText.settings}</Typography>
            </Box>
          )}
          {navItems.map(({ text, iconSrc, subNav }) => {
            const path = getNavLink(text);
            return (
              <React.Fragment key={text}>
                <NavItem
                  id={`nav-${text}`}
                  className={isCurrent(text, Boolean(subNav)) ? 'current' : ''}
                >
                  {menuExpanded && !subNav && (
                    <Link to={path} onClick={() => handleNavLinkClick(text, path)}>
                      <img src={iconSrc} alt="" />
                      <Typography variant="body2">{capitalizeFirstLetter(text)}</Typography>
                    </Link>
                  )}
                  {menuExpanded && subNav && (
                    <ExpandedMenuSubNavButton
                      onClick={handleSubNavClick}
                      expanded={subNavsOpen.includes(text)}
                    >
                      <Box display="flex">
                        <img src={iconSrc} alt="" />
                        <Typography variant="body2">{capitalizeFirstLetter(text)}</Typography>
                      </Box>
                      <ExpandMoreRoundedIcon sx={{ width: '24px' }} />
                    </ExpandedMenuSubNavButton>
                  )}
                  {!menuExpanded && subNav && (
                    <React.Fragment>
                      <button
                        id={`${text}-menu-button`}
                        aria-controls={subNavAnchorEl === text ? `${text}-menu` : undefined}
                        aria-haspopup="true"
                        aria-expanded={subNavAnchorEl === text ? 'true' : undefined}
                        onClick={e => setSubNavAnchorEl(e.currentTarget)}
                      >
                        <img src={iconSrc} alt={`${text} menu`} />
                        <HoverText className="hover-text">
                          {capitalizeFirstLetter(text)}
                        </HoverText>
                      </button>
                      <CollapsedMenuSubNav
                        id={`${text}-menu`}
                        anchorEl={subNavAnchorEl}
                        anchorOrigin={{
                          horizontal: 'right',
                        }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={subNavAnchorEl && subNavAnchorEl.id === `${text}-menu-button`}
                        onClose={() => setSubNavAnchorEl(null)}
                      >
                        <Typography variant="caption">{capitalizeFirstLetter(text)}
                        </Typography>
                        {subNav.map(
                          getSubNavLink(
                            billingWarning,
                            getNavLink,
                            handleNavLinkClick,
                            pathname,
                            () => setSubNavAnchorEl(null),
                          ),
                        )}
                      </CollapsedMenuSubNav>
                    </React.Fragment>
                  )}
                  {!menuExpanded && !subNav && (
                    <Link to={path} onClick={() => handleNavLinkClick(text, path)}>
                      <img src={iconSrc} alt="" />
                      <HoverText className="hover-text">
                        {capitalizeFirstLetter(text)}
                      </HoverText>
                    </Link>
                  )}
                </NavItem>
                {/* subnav list items when sidebar is expanded */}
                {menuExpanded && subNav && subNavsOpen.includes(text) && (
                  <ExpandedMenuSubNav>
                    {subNav.map(
                      getSubNavLink(
                        billingWarning,
                        getNavLink,
                        handleNavLinkClick,
                        pathname,
                      ),
                    )}
                  </ExpandedMenuSubNav>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
      <ul>
        {isApplicationNav ? (
          <React.Fragment>
            <NavItem>
              <Link
                id="nav-settings"
                to={generalNav.settings.path}
                onClick={() => handleNavLinkClick(settings.path, settings.text)}
              >
                <img src={settings.iconSrc} alt={settings.text} />
                {menuExpanded && <Typography variant="body2">{capitalizeFirstLetter(settings.text)}</Typography>}
                {!menuExpanded && (
                  <HoverText className="hover-text">
                    {capitalizeFirstLetter(settings.text)}
                  </HoverText>
                )}
              </Link>
            </NavItem>
            <NavItem>
              <a
                id="nav-documentation"
                href={documentation.path}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => handleNavLinkClick(documentation.path, documentation.text)}
              >
                <img src={documentation.iconSrc} alt={documentation.text} />
                {menuExpanded && <Typography variant="body2">{capitalizeFirstLetter(documentation.text)}</Typography>}
                {!menuExpanded && (
                  <HoverText className="hover-text">
                    {capitalizeFirstLetter(documentation.text)}
                  </HoverText>
                )}
              </a>
            </NavItem>
            {window.CommandBar && (
              <NavItem>
                <button id="nav-help" type="button" onClick={actionMap[help.action]}>
                  <img src={help.iconSrc} alt={help.text} />
                  {menuExpanded && <Typography variant="body2">{capitalizeFirstLetter(help.text)}</Typography>}
                  {!menuExpanded && (
                    <HoverText className="hover-text">{capitalizeFirstLetter(help.text)}</HoverText>
                  )}
                </button>
              </NavItem>
            )}
          </React.Fragment>
        ) : (
          !pathParts.includes('applications') && (
            <NavItem className="back-link">
              <Link to={`/apps/${applicationId}`} onClick={() => handleNavLinkClick('apps', '/apps')}>
                <img src="/assets/icons/nav/arrow_back.svg" alt={staticText.back} />
                {menuExpanded && <Typography variant="body2">{staticText.back}</Typography>}
                {!menuExpanded && (
                  <HoverText className="hover-text">{staticText.back}</HoverText>
                )}
              </Link>
            </NavItem>
          )
        )}
      </ul>
    </Nav>
  );
};

export default SidebarNav;

SidebarNav.propTypes = {
  applicationId: string.isRequired,
  pathname: string.isRequired,
  menuExpanded: bool.isRequired,
  toggleExpandedMenu: func.isRequired,
  view: string.isRequired,
  authErrorMessage: string,
  billingInfo: shape({
    vehiclesOverLimit: number.isRequired,
    numOfApiViolationVehicles: number,
  }).isRequired,
};

SidebarNav.defaultProps = {
  authErrorMessage: '',
};
