import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as updateUserActions } from '../../../../services/auth/reducers';
import UserSecurity from './UserSecurity';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    forgotPasswordRequest,
    resetErrors,
  } = updateUserActions;

  return {
    actions: bindActionCreators({
      forgotPasswordRequest,
      resetErrors,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSecurity);
