import React from 'react';
import { Box, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { bool, element, shape, string } from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import InlineLinks from '../InlineLinks';
import DeltaChip from '../DeltaChip';

import { LoadingRectangle } from '../../global-styles/components';

const Card = styled('div')(({ theme }) => ({
  border: theme.border.default,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: 0,

  '.title': {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: '0.875rem',
    display: 'flex',

    '& svg': {
      marginLeft: theme.spacing(0.5),
      height: '1rem',
      width: '1rem',
    },
  },

  '.subtitle': {
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
    color: theme.palette.grey[600],
  },
}));

const WidgetCard = ({
  deltaChipContent,
  inlineLink,
  isLoading,
  label,
  main,
  showLoading,
  subtitle,
  title,
  tooltip,
}) => {
  const theme = useTheme();
  const TooltipIcon = (tooltip && tooltip.Icon) || InfoOutlined;

  // TODO: there is no error case right now, so if the fetch for this fails
  // we just show loading state forever
  if (main === null || (isLoading && showLoading)) {
    const loadingStyleConfig = [
      {
        height: '18px',
        margin: theme.spacing(0, 0, 0.5, 0),
      },
      {
        height: '18px',
        margin: theme.spacing(0, 0, 3, 0),
      },
      {
        height: '34px',
        margin: theme.spacing(0, 0, 1.5, 0),
      },
      {
        height: '34px',
      },
    ];

    return (
      <Card>
        {loadingStyleConfig.map((style, i) => (
          <LoadingRectangle
            // eslint-disable-next-line react/no-array-index-key
            key={`loading-rectangle-${i}`}
            sx={style}
          />
        ))}
      </Card>
    );
  }

  // main and inlineLink occupy the same space
  let mainContent = null;
  if (inlineLink) {
    mainContent = (
      <Box
        fontSize={14}
        sx={{
          'a.inline-link': {
            fontFamily: theme.typography.bold.fontFamily,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Box>
          <InlineLinks text={inlineLink} />
        </Box>
      </Box>
    );
  } else if (main !== null) {
    mainContent = (
      <Typography
        fontFamily={theme.typography.bold.fontFamily}
        fontSize="28px"
        marginTop={5}
        alignSelf="center"
      >
        {main}
      </Typography>
    );
  }
  // label and deltaChipContent occupy the same space
  let labelContent = null;
  if (label) {
    labelContent = (
      <Typography
        fontSize="0.875rem"
        marginTop={1.5}
        marginBottom={1.5}
        alignSelf="center"
        lineHeight={theme.spacing(4)}
      >
        {label}
      </Typography>
    );
  } else if (deltaChipContent) {
    labelContent = (
      <Box mt={1.5} mb={1.5} alignSelf="center">
        <DeltaChip
          delta={deltaChipContent.delta}
          invertColor={deltaChipContent.invertColors}
          text={deltaChipContent.text}
        />
      </Box>
    );
  }

  return (
    <Card>
      <span className="title">
        {title}
        {tooltip && <Tooltip arrow placement="top" title={tooltip.text}><TooltipIcon /></Tooltip>}
      </span>
      {subtitle && <span className="subtitle">{subtitle}</span>}
      {mainContent}
      {labelContent}
    </Card>
  );
};

export default WidgetCard;

WidgetCard.propTypes = {
  deltaChipContent: shape({
    delta: string,
    invertColors: bool,
    text: string,
  }),
  inlineLink: string,
  isLoading: bool.isRequired,
  label: string,
  main: string,
  showLoading: bool.isRequired,
  subtitle: string,
  title: string.isRequired,
  tooltip: shape({
    text: string,
    Icon: element,
  }),
};

WidgetCard.defaultProps = {
  deltaChipContent: null,
  main: '',
  inlineLink: '',
  label: '',
  subtitle: '',
  tooltip: null,
};
