import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Appearance from './Appearance';
import { actions } from '../../reducers';


function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updateAppLogoRequest,
    clearError,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        updateAppLogoRequest,
        clearError,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Appearance);
