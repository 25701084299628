import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';

import applications, { types as applicationsTypes } from './services/applications/reducers';
import auth, { types as authTypes } from './services/auth/reducers';
import billing from './services/billing/reducers';
import connectCustomizations from './scenes/Application/components/ConnectCustomizations/reducers';
import connectInsights from './scenes/Application/components/ConnectInsights/reducers';
import connectPlayground from './scenes/Application/components/ConnectPlayground/reducers';
import errorReporter from './services/errorReporter/reducers';
import nav from './scenes/Application/components/TopbarNav/reducers';
import onboarding from './services/onboarding/reducers';
import organizations from './services/organizations/reducers';
import deprecatedOverview from './scenes/Application/components/DeprecatedOverview/reducers';
import overview from './scenes/Application/components/Overview/reducers';
import secret from './components/Credentials/components/Secret/reducers';
import simulator from './scenes/Application/components/Simulator/reducers';
import simulatorTrip from './scenes/Application/components/Simulator/components/SimulateVehicle/reducers';
import vehicleDetails from './scenes/Application/components/Vehicles/components/VehicleDetails/reducers';
import vehicles from './scenes/Application/components/Vehicles/reducers';
import webhooks from './scenes/Application/components/Webhooks/reducers';
import logs from './scenes/Application/components/Logs/reducers';

const appReducer = combineReducers({
  applications,
  auth,
  billing,
  errorReporter,
  nav,
  onboarding,
  organizations,
  secret,
  // app-specific states
  connectCustomizations,
  connectInsights,
  connectPlayground,
  deprecatedOverview,
  overview,
  simulator,
  simulatorTrip,
  vehicleDetails,
  vehicles,
  webhooks,
  logs,
});

export default function rootReducer(state, action) {
  if (action.type === authTypes.LOGOUT_SUCCESS) {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  // whenever selecting or switching apps, we want to reset all app-specific states
  if (action.type === applicationsTypes.SELECT_APPLICATION) {
    const appIndependentStates = _.pick(state, [
      'applications',
      'auth',
      'billing',
      'errorReporter',
      'nav',
      'onboarding',
      'organizations',
      // need to persist secret
      'secret',
    ]);
    return appReducer(appIndependentStates, action);
  }
  return appReducer(state, action);
}
