import React from 'react';
import { GlobalStyles } from '@mui/material';

const Styles = () => {
  return (<GlobalStyles styles={theme => ({
    '.commandbar-launcher': {
      transition: 'transform 0.25s ease',
      zIndex: `${theme.zIndex.commandbar.launcher} !important`,
    },
    '#commandbar-nudge-container': {
      zIndex: `${theme.zIndex.commandbar.nudges} !important`,
    },
    '.commandbar-checklist': {
      zIndex: `${theme.zIndex.commandbar.checklist} !important`,
    },
    '#helphub-modal': {
      zIndex: `${theme.zIndex.commandbar.helphub} !important`,
    },
    '.rc-dialog-wrap': {
      backdropFilter: 'blur(6px)',
      backgroundColor: 'rgba(221, 221, 221, 0.5)', // grey 300
    },
    // temporary fix for the commandbar style bug
    '#commandbar-nudge-container, #commandbar-nudges-banner-inline-container-top, .commandbar-checklist': {
      p: {
        marginTop: 0,
      },
    },
    // works with offsetBannerHeight to make sure the side nav when banner is in present / in view
    '#commandbar-nudges-banner-space-container ~ #root': {
      '#side-nav': {
        height: 'calc(100vh - 58px)', // offset by banner's height
        '&.ignore-banner-offset': {
          height: '100vh',
        },
      },
    },
  })}
  />);
};

export default Styles;
