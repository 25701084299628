import { styled, Typography } from '@mui/material';

export const PlaygroundContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginTop: theme.spacing(5),
  height: 'calc(100vh - 250px)', // minus header
  width: theme.width.content,
}));

export const UrlBuilderContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius2,
  border: theme.border.common,
  width: '700px',
  minWidth: '700px',
  height: 'fit-content',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const UrlViewerContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: '#000',
  borderRadius: theme.border.radius2,
  boxShadow: theme.border.boxShadow,
  padding: theme.spacing(2, 3, 3, 3),
  flexGrow: 1,
  minHeight: '300px',
  color: theme.palette.common.white,
}));

export const FormFields = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 3),

  ...theme.scrollShadows,
  ...theme.styledScrollbar,
}));

export const Footer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const PermissionCount = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: theme.typography.tableData.fontSize,
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),

  '& button': {
    height: '48px',
  },
}));

export const LaunchLink = styled('a')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.border.radius,
  padding: theme.spacing(0, 2),
  textDecoration: 'none',
  fontFamily: theme.typography.bold.fontFamily,
  display: 'flex',
  alignItems: 'center',
  height: '48px',

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

