import moment from 'moment-timezone';

import { gatedFeatureData } from '../../services/featureGate';
import FEATURES from '../../services/featureGate/features';

const ENTERPRISE_FEATURE_SET_ID = '01be4b83-49bb-4aab-8760-cc362e455b7a';
export const unallowedBrandLogos = ['Acura', 'Audi', 'Honda', 'Volkswagen'];
export const currentTermsOfServiceVersion = '20250108';

export const getOrgApps = (applications, organizationId) => {
  return Object.keys(applications).reduce((acc, appId) => {
    if (applications[appId].organizationId === organizationId) {
      acc[appId] = applications[appId];
    }
    return acc;
  }, {});
};

export const capitalizeFirstLetter = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

const DEFAULT_LOG_RANGE = 2;

/**
 * Retrieves log restrictions based on the provided feature set ID and log type.
 *
 * @param {string} featureSetId - The ID of the feature set.
 * @param {string} logType - The type of log.
 * @returns {Object} An object containing log restrictions:
 *   - {number} logDays - The number of days logs are retained.
 *   - {string} maxDate - The maximum date for logs in ISO format.
 *   - {string} minDate - The minimum date for logs in ISO format.
 *   - {string} defaultStartDate - The default start date for logs in ISO format.
 *   - {boolean} hasExportAccess - Indicates if the user has access to export logs.
 *   - {boolean} isMaxLogDays - Maximum limit stored in streaming-db.
 *   - {number} maxExportLogDays - The maximum number of days logs can be exported from Redshift.
 */
export const getLogRestrictions = (featureSetId, logType) => {
  const logDays = Number(gatedFeatureData(logType, featureSetId)) || 1;
  const isMaxLogDays = logDays === Number(gatedFeatureData(logType, ENTERPRISE_FEATURE_SET_ID));
  const maxDate = moment.utc().startOf('day').toISOString();
  const minDate = moment.utc(maxDate).subtract(logDays, 'days').toISOString();
  let defaultStartDate = moment.utc(maxDate).subtract(DEFAULT_LOG_RANGE, 'days').toISOString();

  if (moment(defaultStartDate).isBefore(minDate)) {
    defaultStartDate = minDate;
  }

  // These values currently don't apply to Connect Insight logs
  let hasExportAccess = false;
  let maxExportLogDays = 0;

  if (logType === FEATURES.LOG_DAYS) {
    hasExportAccess = !gatedFeatureData(FEATURES.LOG_EXPORT, featureSetId);
    maxExportLogDays = Number(gatedFeatureData(FEATURES.EXPORT_LOG_DAYS, featureSetId) || 0);
  }

  return {
    logDays,
    maxDate,
    minDate,
    defaultStartDate,
    hasExportAccess,
    isMaxLogDays,
    maxExportLogDays,
  };
};

export const alphabetize = (a, b) => {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA === lowerB) return 0;
  return lowerA < lowerB ? -1 : 1;
};


// Sets most recent orgId/AppId in local storage for a given dashboardUserId
// This will be used to load the most recent org/app in the dashboard
export const updateLocalStorageWithMostRecentApp = ({
  selectedOrganization,
  applicationId,
  userContext,
}) => {
  const existingRecentApp = localStorage.getItem('recentApp');

  const recentApp = {
    ...(existingRecentApp && JSON.parse(existingRecentApp)),
    [userContext.dashboardUserId]: {
      orgId: selectedOrganization,
      appId: applicationId,
    },
  };
  localStorage.setItem('recentApp', JSON.stringify(recentApp));
};
