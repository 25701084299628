import React from 'react';
import { number, string, func } from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import FEATURES from '../../../../../../services/featureGate/features';
import { gatedFeatureData } from '../../../../../../services/featureGate';

import staticText from '../../../../../../localization/Application/Overview/charts';

const DateButtonGroup = ({
  daysFilterValue, chartKey, featureSetId, setFilter,
}) => {
  const theme = useTheme();

  const maxDays = gatedFeatureData(
    FEATURES.OVERVIEW_DAYS,
    featureSetId,
  ) || 1; // default to 1 if unrecognized feature set
  const gatedDateButtonGroupConfig = staticText.dateButtonGroupConfig.filter(
    button => button.value <= maxDays,
  );

  const handleClick = intervalValue => () => {
    setFilter(
      {
        days: intervalValue,
      },
      chartKey,
    );
  };

  return (
    <ButtonGroup
      variant="filled"
      disableElevation
      sx={{
        backgroundColor: theme.palette.grey[75],
        borderRadius: theme.spacing(1),
      }}
    >
      {gatedDateButtonGroupConfig.map(button => (
        <Button
          key={button.value}
          onClick={handleClick(button.value)}
          sx={{
            margin: theme.spacing(0.5, 0.25),
            padding: '6px',
            borderRadius: `${theme.spacing(1)} !important`,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSizes.xs,
            lineHeight: 'normal',

            '&:first-child': {
              marginLeft: '4px',
            },
            '&:last-child': {
              marginRight: '4px',
            },

            ...(daysFilterValue === button.value ? {
              border: `1px solid ${theme.palette.grey[200]}`,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.redesignPalette.primary.main,
              fontFamily: theme.typography.fontWeightBold.fontFamily,
            } : {}),
          }}
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DateButtonGroup;

DateButtonGroup.propTypes = {
  daysFilterValue: number.isRequired,
  chartKey: string.isRequired,
  featureSetId: string.isRequired,
  setFilter: func.isRequired,
};
