import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { bool, func, instanceOf, number, objectOf, shape, string } from 'prop-types';
import _ from 'lodash';

import { DateRangePicker, FeatureGateBanner, FiltersForm, MenuItemWrapper } from '../../../../../../../../../../components';
import { ResetButton } from '../../../../../shared/styles';
import { webhookEventFilters, staticText } from '../../../../../../../../../../localization/Application/VehicleManagement/filtersBar';
import getFilterStatusAndTitle from '../../../../../../../Logs/utils/getFilterStatusAndTitle';
import { getWebhookDisplayNameByType } from '../../../../../../../Webhooks/utils/webhookTypes';

const FiltersBar = ({
  filterValues,
  logRestrictions,
  handleApplyFilters,
  handleResetAll,
  setFormError,
  canWriteBilling,
}) => {
  const [newFormState, setNewFormState] = useState(filterValues);
  const {
    logDays, minDate, maxDate, isMaxLogDays,
  } = logRestrictions;
  const dateRange = [newFormState.start, newFormState.end];

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters(newFormState);
    reportToSegment(
      types.TRACK,
      eventNames.formSubmitted,
      {
        label: '[vehicle details - webhook events - webhooks] filter values',
        form_content: newFormState,
      },
    );
  };

  const handleResetClick = () => {
    setFormError('');
    handleResetAll();
    setNewFormState(filterValues);
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'reset',
        text: `[vehicle details - webhook events - webhooks] ${staticText.reset}`,
      },
    );
  };

  const getHandleChange = filterKey => (newValue) => {
    if (filterKey === 'dateRange') {
      const [startDate, endDate] = newValue;
      setNewFormState({
        ...newFormState,
        start: startDate,
        end: endDate,
      });
    } else {
      setNewFormState({
        ...newFormState,
        [filterKey]: newValue,
      });
    }
    setFormError('');
  };
  const logAllowance = staticText.logDaysAllowance(logDays);
  const upgradeMessage = `${logAllowance} ${staticText.featureGated.upgrade}`;
  const ownerUpgradeText = `${logAllowance} ${staticText.featureGated.contactOwnersToUpgrade}`;

  const dateRangeMessaging = isMaxLogDays
    ?
      <Typography>{staticText.maxLogDays(logDays)}</Typography>
    :
    (
      <FeatureGateBanner
        canWriteBilling={canWriteBilling}
        upgradeText={upgradeMessage}
        ownerUpgradeText={ownerUpgradeText}
      />
    );
  const theme = useTheme();

  useEffect(() => {
    // because filterValues can be changed elsewhere
    setNewFormState(filterValues);
  }, [filterValues]);

  return (
    <form
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-end"
        marginBottom={2}
        gap={2}
      >
        <Box gap={1} display="flex" flexDirection="column">
          <Typography fontFamily={theme.typography.bold.fontFamily}>
            {staticText.dateRange}
          </Typography>
          <DateRangePicker
            onAccept={getHandleChange('dateRange')}
            value={dateRange}
            minDate={minDate}
            maxDate={maxDate}
            showIcon
            customActionBar={dateRangeMessaging}
          />
        </Box>
        {
          webhookEventFilters.map(({
            filterKey, icon, formTitle, id, minWidth, columns, formLabel,
          }) => {
            const {
              isFilterActive,
              alternativeItemWrapperTitle,
            } = getFilterStatusAndTitle(newFormState, filterKey, formLabel);

            return (
              <Box gap={1} key={filterKey} display="flex" flexDirection="column" width="240px">
                <Typography fontFamily={theme.typography.bold.fontFamily}>
                  {formTitle}
                </Typography>
                <MenuItemWrapper
                  title={alternativeItemWrapperTitle}
                  isFilterActive={isFilterActive}
                  Icon={icon}
                  id={id}
                  minWidth={minWidth}
                  filterKey={filterKey}
                >
                  <FiltersForm
                    checkboxOptions={newFormState[filterKey]}
                    updateCheckboxes={getHandleChange(filterKey)}
                    title={formTitle}
                    columns={columns}
                    getLabel={getWebhookDisplayNameByType}
                  />
                </MenuItemWrapper>
              </Box>
            );
          })
        }
        <Button
          color="primary"
          disabled={_.isEqual(newFormState, filterValues)}
          type="submit"
          variant="contained"
          sx={{ height: '42px' }}
        >
          {staticText.apply}
        </Button>
        <ResetButton
          id="reset-all-button"
          onClick={handleResetClick}
          variant="text"
          sx={{ height: '42px' }}
        >
          {staticText.reset}
        </ResetButton>
      </Box>
    </form>
  );
};

export default FiltersBar;

FiltersBar.propTypes = {
  filterValues: shape({
    start: instanceOf(moment),
    end: instanceOf(moment),
    statusCodes: objectOf(bool),
  }).isRequired,
  logRestrictions: shape({
    minDate: string.isRequired,
    maxDate: string.isRequired,
    logDays: number.isRequired,
    isMaxLogDays: bool.isRequired,
  }).isRequired,
  handleApplyFilters: func.isRequired,
  handleResetAll: func.isRequired,
  setFormError: func.isRequired,
  canWriteBilling: bool.isRequired,
};
