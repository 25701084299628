import _ from 'lodash';

import { setFetchingState, setSuccessState, setFailureState } from './util/reducers';

export const types = {
  FETCH_CONNECT_FUNNEL:
    'developer-client/Overview/FETCH_CONNECT_FUNNEL',
  FETCH_CONNECT_FUNNEL_SUCCESS:
    'developer-client/Overview/FETCH_CONNECT_FUNNEL_SUCCESS',
  FETCH_CONNECT_FUNNEL_FAILURE:
    'developer-client/Overview/FETCH_CONNECT_FUNNEL_FAILURE',

  FETCH_API_REQUESTS_OVER_TIME:
    'developer-client/Overview/FETCH_API_REQUESTS_OVER_TIME',
  FETCH_API_REQUESTS_OVER_TIME_SUCCESS:
    'developer-client/Overview/FETCH_API_REQUESTS_OVER_TIME_SUCCESS',
  FETCH_API_REQUESTS_OVER_TIME_FAILURE:
    'developer-client/Overview/FETCH_API_REQUESTS_OVER_TIME_FAILURE',

  FETCH_CONNECTED_VEHICLES_OVER_TIME:
    'developer-client/Overview/FETCH_CONNECTED_VEHICLES_OVER_TIME',
  FETCH_CONNECTED_VEHICLES_OVER_TIME_SUCCESS:
    'developer-client/Overview/FETCH_CONNECTED_VEHICLES_OVER_TIME_SUCCESS',
  FETCH_CONNECTED_VEHICLES_OVER_TIME_FAILURE:
    'developer-client/Overview/FETCH_CONNECTED_VEHICLES_OVER_TIME_FAILURE',

  FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES:
    'developer-client/Overview/FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES',
  FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_SUCCESS:
    'developer-client/Overview/FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_SUCCESS',
  FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_FAILURE:
    'developer-client/Overview/FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_FAILURE',

  FETCH_WIDGETS: 'developer-client/Overview/FETCH_WIDGETS',
  FETCH_WIDGETS_SUCCESS:
    'developer-client/Overview/FETCH_WIDGETS_SUCCESS',
  FETCH_WIDGETS_FAILURE:
    'developer-client/Overview/FETCH_WIDGETS_FAILURE',

  SET_API_REQUEST_COUNT: 'developer-client/Overview/SET_API_REQUEST_COUNT',

  SET_FILTER: 'developer-client/Overview/SET_FILTER',
};

const chartDataInitialState = {
  data: null,
  error: '',
  filter: {
    days: 1, // default to 1 day
  },
  isLoading: false,
};

export const initialState = {
  connectFunnel: {
    data: [],
    error: '',
    isLoading: false,
  },
  apiRequestsOverTimeChart: chartDataInitialState,
  connectedVehiclesOverTimeChart: chartDataInitialState,
  top5MakesByConnectedVehiclesChart: chartDataInitialState,
  widgets: {
    error: '',
    isLoading: false,
    connectedVehicles: {
      count: null,
    },
    activeVehiclesWoWChange: {
      last7Days: null,
      percentChange: null,
    },
    newConnectionsWoWChange: {
      last7Days: null,
      percentChange: null,
    },
    apiRequestCount: {
      count: null,
    },
  },
};

export const actions = {
  fetchConnectFunnel: applicationId => ({
    type: types.FETCH_CONNECT_FUNNEL,
    payload: null,
    meta: applicationId,
  }),
  fetchConnectFunnelSuccess: metrics => ({
    type: types.FETCH_CONNECT_FUNNEL_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchConnectFunnelFailure: error => ({
    type: types.FETCH_CONNECT_FUNNEL_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchApiRequestsOverTime: applicationId => ({
    type: types.FETCH_API_REQUESTS_OVER_TIME,
    payload: null,
    meta: applicationId,
  }),
  fetchApiRequestsOverTimeSuccess: metrics => ({
    type: types.FETCH_API_REQUESTS_OVER_TIME_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchApiRequestsOverTimeFailure: error => ({
    type: types.FETCH_API_REQUESTS_OVER_TIME_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchConnectedVehiclesOverTime: applicationId => ({
    type: types.FETCH_CONNECTED_VEHICLES_OVER_TIME,
    payload: null,
    meta: applicationId,
  }),
  fetchConnectedVehiclesOverTimeSuccess: metrics => ({
    type: types.FETCH_CONNECTED_VEHICLES_OVER_TIME_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchConnectedVehiclesOverTimeFailure: error => ({
    type: types.FETCH_CONNECTED_VEHICLES_OVER_TIME_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchTop5MakesByConnectedVehicles: applicationId => ({
    type: types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES,
    payload: null,
    meta: applicationId,
  }),
  fetchTop5MakesByConnectedVehiclesSuccess: metrics => ({
    type: types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchTop5MakesByConnectedVehiclesFailure: error => ({
    type: types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchWidgets: applicationId => ({
    type: types.FETCH_WIDGETS,
    payload: null,
    meta: applicationId,
  }),
  fetchWidgetsSuccess: metrics => ({
    type: types.FETCH_WIDGETS_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchWidgetsFailure: error => ({
    type: types.FETCH_WIDGETS_FAILURE,
    payload: error,
    meta: null,
  }),

  setApiRequestCount: count => ({
    type: types.SET_API_REQUEST_COUNT,
    payload: count,
    meta: null,
  }),

  setFilter: (filter, key) => ({
    type: types.SET_FILTER,
    payload: { key, filter },
    meta: null,
  }),
};

export const selectors = {
  getApiRequestsOverTimeFilter: (state) => {
    return _.get(state, ['overview', 'apiRequestsOverTimeChart', 'filter']);
  },
  getConnectedVehiclesOverTimeFilter: (state) => {
    return _.get(state, ['overview', 'connectedVehiclesOverTimeChart', 'filter']);
  },
  getTop5MakesByConnectedVehiclesFilter: (state) => {
    return _.get(state, ['overview', 'top5MakesByConnectedVehiclesChart', 'filter']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONNECT_FUNNEL:
      return setFetchingState(state, 'connectFunnel');
    case types.FETCH_CONNECT_FUNNEL_SUCCESS:
      return setSuccessState(state, 'connectFunnel', action);
    case types.FETCH_CONNECT_FUNNEL_FAILURE:
      return setFailureState(state, 'connectFunnel', action);

    case types.FETCH_API_REQUESTS_OVER_TIME:
      return setFetchingState(state, 'apiRequestsOverTimeChart');
    case types.FETCH_API_REQUESTS_OVER_TIME_SUCCESS:
      return setSuccessState(state, 'apiRequestsOverTimeChart', action);
    case types.FETCH_API_REQUESTS_OVER_TIME_FAILURE:
      return setFailureState(state, 'apiRequestsOverTimeChart', action);

    case types.FETCH_CONNECTED_VEHICLES_OVER_TIME:
      return setFetchingState(state, 'connectedVehiclesOverTimeChart');
    case types.FETCH_CONNECTED_VEHICLES_OVER_TIME_SUCCESS:
      return setSuccessState(state, 'connectedVehiclesOverTimeChart', action);
    case types.FETCH_CONNECTED_VEHICLES_OVER_TIME_FAILURE:
      return setFailureState(state, 'connectedVehiclesOverTimeChart', action);

    case types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES:
      return setFetchingState(state, 'top5MakesByConnectedVehiclesChart');
    case types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_SUCCESS:
      return setSuccessState(state, 'top5MakesByConnectedVehiclesChart', action);
    case types.FETCH_TOP_5_MAKES_BY_CONNECTED_VEHICLES_FAILURE:
      return setFailureState(state, 'top5MakesByConnectedVehiclesChart', action);

    case types.FETCH_WIDGETS:
      return {
        ...state,
        widgets: {
          ...initialState.widgets,
          isLoading: true,
          error: '',
        },
      };
    case types.FETCH_WIDGETS_SUCCESS:
      return {
        ...state,
        widgets: {
          ...state.widgets,
          isLoading: false,
          connectedVehicles: {
            count: action.payload.connectedVehicles,
          },
          activeVehiclesWoWChange: action.payload.activeVehiclesWoWChange[0],
          newConnectionsWoWChange: action.payload.newConnectionsWoWChange[0],
        },
      };
    case types.FETCH_WIDGETS_FAILURE:
      return {
        ...state,
        widgets: {
          ...state.widgets,
          isLoading: false,
          error: (
            action.payload
                && action.payload.response
                && action.payload.response.data
                && action.payload.response.data.message
          ) || 'Something went wrong fetching overview widgets',
        },
      };

    case types.SET_API_REQUEST_COUNT:
      return {
        ...state,
        widgets: {
          ...state.widgets,
          apiRequestCount: {
            count: action.payload,
          },
        },
      };

    case types.SET_FILTER:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          filter: {
            ...state[action.payload.key].filter,
            ...action.payload.filter,
          },
        },
      };

    default:
      return state;
  }
}

