import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SSO from './SSO';
import { actions } from '../../../../services/auth/reducers';

function mapStateToProps(state) {
  return {
    ...state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    authenticateSsoRequest,
    resetSsoAuthorizationUrl,
  } = actions;

  return {
    actions: bindActionCreators({
      authenticateSsoRequest,
      resetSsoAuthorizationUrl,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO);
