import React from 'react';
import { func, node, number, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';

import DateButtonGroup from '../DateButtonGroup';

const ChartWrapper = ({
  chartKey, featureSetId, setFilter, timeInterval, title, children,
}) => {
  const theme = useTheme();

  let subtitle = '';
  const daysBefore = moment.utc().subtract(timeInterval - 1, 'days').format('MMM D, YYYY');
  if (chartKey === 'top5MakesByConnectedVehiclesChart') {
    subtitle = `As of ${daysBefore}`;
  } else {
    const currentDay = moment.utc().format('MMM D, YYYY');
    if (timeInterval === 1) {
      subtitle = currentDay;
    } else {
      subtitle = `${daysBefore} - ${currentDay}`;
    }
  }

  return (
    <Box sx={{
      width: '100%',
      border: 1,
      borderColor: 'grey.200',
      borderRadius: 1,
      padding: 3,
      boxSizing: 'border-box',
    }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Typography component="h2" variant="h4" sx={{ fontSize: '.875rem' }}>
            {title}
          </Typography>
          <Typography
            component="subtitle2"
            variant="body2"
            sx={{ fontSize: '.75rem', color: theme.palette.grey[600] }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box>
          <DateButtonGroup
            daysFilterValue={timeInterval}
            chartKey={chartKey}
            setFilter={setFilter}
            featureSetId={featureSetId}
          />
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default ChartWrapper;

ChartWrapper.propTypes = {
  featureSetId: string.isRequired,
  chartKey: string.isRequired,
  setFilter: func.isRequired,
  timeInterval: number.isRequired,
  title: string.isRequired,
  children: node.isRequired,
};

