import { styled } from '@mui/material';

export const SearchButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',

  '&:hover .shortcut-key': {
    backgroundColor: theme.palette.grey[150],
  },
}));

export const ShortcutKeys = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',

  div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.body2.fontFamily,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.border.radius,
    minWidth: theme.spacing(3),
    height: theme.spacing(3),
    boxSizing: 'border-box',

    span: {
      marginTop: '1px',
      padding: '0 4px',
    },
    img: {
      marginTop: '-1px',
    },
  },
}));
