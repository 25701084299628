import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import RegionList from './components/RegionList/RegionList';
import { CustomizationGroupDescription, CustomizationGroupHeading } from '../../styles';
import staticText from '../../../../../../localization/Application/connect-config';
import { getSupportedCountriesByRegion } from '../../../../../../../config/countries';

const Countries = ({
  customizations: {
    supportedCountries,
  },
  updateCustomizations,
  defaultSupportedCountries,
}) => {
  const countriesByRegion = getSupportedCountriesByRegion(defaultSupportedCountries);
  const regionOrder = ['Americas', 'Europe'];

  const updateSelectedCountries = (countries) => {
    updateCustomizations({ supportedCountries: countries });
  };

  return (
    <div>
      <CustomizationGroupHeading variant="h2">{staticText.countries.heading}</CustomizationGroupHeading>
      <CustomizationGroupDescription>
        {staticText.countries.description}
      </CustomizationGroupDescription>
      <div>
        {
          regionOrder.map(region => (
            <RegionList
              key={region}
              region={region}
              countriesByRegion={countriesByRegion}
              countries={countriesByRegion[region]}
              selectedCountries={supportedCountries}
              updateSelectedCountries={updateSelectedCountries}
            />
          ))
        }
      </div>
    </div>
  );
};

export default Countries;

Countries.propTypes = {
  customizations: shape({
    supportedCountries: arrayOf(string),
  }).isRequired,
  defaultSupportedCountries: arrayOf(string).isRequired,
  updateCustomizations: func.isRequired,
};
