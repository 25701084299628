import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';

import SendVerificationEmail from '../SendVerificationEmail';
import SendVerificationEmailSuccess from '../SendVerificationEmailSuccess';

const VerifyEmailModalContent = ({
  actions: {
    sendVerificationEmailRequest,
    setVerificationEmailSent,
  },
  email,
  isSendingVerificationEmail,
  openingText,
  sendVerificationEmailError,
  verificationEmailSent,
}) => {
  useEffect(() => {
    setVerificationEmailSent(false);
  }, []);

  return (
    <div>
      {verificationEmailSent ? (
        <SendVerificationEmailSuccess
          sendVerificationEmailRequest={sendVerificationEmailRequest}
        />
      ) : (
        <SendVerificationEmail
          email={email}
          error={sendVerificationEmailError}
          isSendingVerificationEmail={isSendingVerificationEmail}
          openingText={openingText}
          sendVerificationEmailRequest={sendVerificationEmailRequest}
        />
      )}
    </div>
  );
};

export default VerifyEmailModalContent;

VerifyEmailModalContent.propTypes = {
  actions: shape({
    sendVerificationEmailRequest: func.isRequired,
    setVerificationEmailSent: func.isRequired,
  }).isRequired,
  isSendingVerificationEmail: bool.isRequired,
  email: string.isRequired,
  openingText: string.isRequired,
  sendVerificationEmailError: string.isRequired,
  verificationEmailSent: bool.isRequired,
};
