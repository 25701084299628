import { fork } from 'redux-saga/effects';

import applications from './services/applications/sagas';
import auth from './services/auth/sagas';
import billing from './services/billing/sagas';
import connectCustomizations from './scenes/Application/components/ConnectCustomizations/sagas';
import connectInsights from './scenes/Application/components/ConnectInsights/sagas';
import connectPlayground from './scenes/Application/components/ConnectPlayground/sagas';
import errorReporter from './services/errorReporter/sagas';
import nav from './scenes/Application/components/TopbarNav/sagas';
import onboarding, { pollWatcher } from './services/onboarding/sagas';
import secret from './components/Credentials/components/Secret/sagas';
import simulator from './scenes/Application/components/Simulator/sagas';
import organizations from './services/organizations/sagas';
import deprecatedOverview from './scenes/Application/components/DeprecatedOverview/sagas';
import overview from './scenes/Application/components/Overview/sagas';
import vehicles from './scenes/Application/components/Vehicles/sagas';
import webhooks from './scenes/Application/components/Webhooks/sagas';
import logs from './scenes/Application/components/Logs/sagas';
import vehicleDetails from './scenes/Application/components/Vehicles/components/VehicleDetails/sagas';

export default function* rootSaga() {
  yield fork(applications);
  yield fork(auth);
  yield fork(billing);
  yield fork(connectCustomizations);
  yield fork(connectInsights);
  yield fork(connectPlayground);
  yield fork(errorReporter);
  yield fork(nav);
  yield fork(onboarding);
  yield fork(pollWatcher);
  yield fork(secret);
  yield fork(simulator);
  yield fork(organizations);
  yield fork(deprecatedOverview);
  yield fork(overview);
  yield fork(vehicles);
  yield fork(webhooks);
  yield fork(logs);
  yield fork(vehicleDetails);
}
