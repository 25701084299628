import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FunnelBar, FunnelStepDescription } from './components';
import { LoadingRectangle } from '../../global-styles/components';
import { Overlay } from './styles';

import fullFunnel from '../../assets/images/full-funnel.png';
import computerDark from '../../assets/images/computer-dark.svg';

import FEATURES from '../../services/featureGate/features';
import staticText from '../../localization/Application/ConnectInsights/connectInsights';

const loadingAndEmptyBlur = {
  filter: 'blur(4px)',
  opacity: '50%',
};

const ConnectInsightsFunnel = ({
  funnel, funnelGated, isFetching, showLoading,
}) => {
  const theme = useTheme();

  const funnelEmpty = funnel.every(event => event.count === '0');
  let formattedFunnel = funnel;
  if (funnelEmpty || funnelGated) {
    formattedFunnel = funnel.map((event) => {
      return {
        ...event,
        percent: '0.00',
        previousStagePercent: '1.00',
      };
    });
  }

  return (
    <Box display="grid">
      {funnelGated && (
        <React.Fragment>
          <Overlay>
            <Box
              sx={{
                maxWidth: '400px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
                backgroundColor: theme.palette.warning.light2,
                borderRadius: 1,
                border: `2px solid ${theme.palette.warning.main}`,
              }}
            >
              <img src={computerDark} alt="Computer with a search icon in the top right corner" />
              <Typography
                variant="h4"
                sx={{
                  marginTop: theme.spacing(3),
                  fontSize: theme.spacing(2),
                }}
              >
                {staticText.upgradeContent.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: theme.spacing(2),
                  fontSize: theme.spacing(1.5),
                }}
              >
                {staticText.upgradeContent.description}
              </Typography>
              <Button
                variant="contained"
                component="div"
                sx={{
                    marginTop: theme.spacing(3),
                    backgroundColor: theme.palette.warning.primary,
                    borderRadius: theme.spacing(1),
                    fontSize: '14px',
                    width: '100%',

                    '&:hover, :focus': {
                      backgroundColor: theme.palette.warning.main,
                      borderColor: theme.palette.warning.main,
                    },
                    '& > a': {
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                    },
                  }}
              >
                <Link
                  onClick={() => {
                    reportToSegment(types.TRACK, eventNames.linkClicked, {
                      section: 'article',
                      style: 'cta primary',
                      text: `upsell (${FEATURES.CONNECT_FUNNEL})`,
                      path: staticText.upgradeContent.upgradeButton.path,
                    });
                  }}
                  to={staticText.upgradeContent.upgradeButton.path}
                >
                  {staticText.upgradeContent.upgradeButton.text}
                </Link>
              </Button>
            </Box>
          </Overlay>
          <img
            src={fullFunnel}
            alt="Conversion funnel"
            style={{
              width: '100%',
              gridRow: 1,
              gridColumn: 1,
              marginTop: theme.spacing(3),
            }}
          />
        </React.Fragment>
      )}
      {!funnelGated && (
        <React.Fragment>
          {isFetching && <LoadingRectangle sx={{ height: '287px' }} data-testid="loading-rectangle" />}
          {!isFetching && funnelEmpty && (
            <Overlay>
              <Box
                backgroundColor={theme.palette.common.white}
                borderRadius={theme.spacing(0.5)}
                padding={6}
                width="40%"
                textAlign="center"
              >
                <Typography variant="h4" color="primary">
                  {staticText.funnel.emptyState.heading}
                </Typography>
                <Typography mt={3}>
                  {staticText.funnel.emptyState.description}
                </Typography>
              </Box>
            </Overlay>
          )}
          <Box
            gridRow={1}
            gridColumn={1}
            sx={{
              ...(((isFetching && showLoading) || funnelEmpty) && loadingAndEmptyBlur),
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                gridTemplateColumns: `repeat(${formattedFunnel.length}, 1fr)`,
                columnGap: theme.spacing(3),
                rowGap: theme.spacing(1.5),
              }}
            >
              {formattedFunnel.map((event, i) => (
                <FunnelBar key={event.stage} event={event} delay={i * 100} />
              ))}
              {formattedFunnel.map(event => (
                <FunnelStepDescription key={event.stage} event={event} />
              ))}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ConnectInsightsFunnel;

ConnectInsightsFunnel.propTypes = {
  funnel: arrayOf(shape({
    count: string.isRequired,
    stage: string.isRequired,
    order: number.isRequired,
    displayName: string.isRequired,
    percent: string.isRequired,
  })).isRequired,
  funnelGated: bool.isRequired,
  isFetching: bool.isRequired,
  showLoading: bool.isRequired,
};
