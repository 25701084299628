import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, styled, Typography } from '@mui/material';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import staticText from '../../../../../../localization/Application/OrgSecurity/orgSecurity';
import { Section } from '../../../../styles';
import { openChat } from '../../../../../../services/front/front';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';
import { Feedback, Spinner } from '../../../../../../components';
import api from '../../../../../../services/api/api';
import { DeleteSSOConnectionModal } from './components';

export const StatusChip = styled(Chip)(({ theme }) => ({
  width: 'fit-content',
  border: theme.border.default,
  borderRadius: theme.border.radius2,
}));

const SSO = ({
  organization: {
    id: organizationId,
    addOns,
  },
  workOsOrganization,
  workOsConnection,
  isFetchingWorkOsOrganization,
  isFetchingWorkOsConnection,
  ssoSetupInProgress,
  actions: {
    createWorkOsOrganizationRequest,
    fetchWorkOsOrganizationRequest,
    fetchWorkOsConnectionRequest,
  },
}) => {
  // There should be 5 status states a user can experience:
  //  'enabled', 'unavailable', 'pendingDomainValidation', 'pendingSsoSetup', 'disabled'
  const [status, setStatus] = useState('');
  // Add this state to track when we're waiting to generate the admin portal URL
  const [pendingOrganizationCreation, setPendingOrganizationCreation] = useState(false);

  const [deleteSSOModalOpen, setDeleteSSOModalOpen] = useState(false);
  const [generateAdminPortalError, setGenerateAdminPortalError] = useState(null);

  const toggleDeleteSSOModal = () => {
    setDeleteSSOModalOpen(!deleteSSOModalOpen);
  };

  useEffect(() => {
    // Immediately move to the next step when the organization is created
    const generateAdminPortalUrl = async () => {
      if (!ssoSetupInProgress && pendingOrganizationCreation && workOsOrganization.id) {
        // Reset the pending flag
        setPendingOrganizationCreation(false);

        // Generate and redirect to the admin portal URL
        const { link } = await api.generateWorkOsAdminPortalUrl(
          organizationId,
          'domain_verification',
          window.location.href,
          `${window.location.origin}/private-callback?type=sso_domain_verification&success=true`,
        );

        window.location.href = link;
      }
    };

    generateAdminPortalUrl();
  }, [ssoSetupInProgress, pendingOrganizationCreation, workOsOrganization.id]);

  useEffect(() => {
    fetchWorkOsOrganizationRequest(organizationId);
    fetchWorkOsConnectionRequest(organizationId);
  }, []);

  useEffect(() => {
    if (isFetchingWorkOsOrganization || isFetchingWorkOsConnection) {
      return;
    }

    if (!addOns.includes('sso')) {
      setStatus('unavailable');
    } else if (
      workOsOrganization.id &&
      (!workOsOrganization.domains ||
        !workOsOrganization.domains.length ||
        workOsOrganization.domains[0].state !== 'verified')
    ) {
      setStatus('pendingDomainValidation');
    } else if (
      (!workOsConnection || workOsConnection.state !== 'active') &&
      workOsOrganization.domains &&
      workOsOrganization.domains.length &&
      workOsOrganization.domains[0].state === 'verified'
    ) {
      setStatus('pendingSsoSetup');
    } else if (workOsConnection && workOsConnection.state === 'active') {
      setStatus('enabled');
    } else {
      setStatus('disabled');
    }
  }, [
    addOns,
    workOsOrganization,
    isFetchingWorkOsOrganization,
    workOsConnection,
    isFetchingWorkOsConnection,
  ]);

  // If organization has SSO add-on
  // call workos.listConnection
  // if connection does not exist, we set set the status to disabled and the CTA to 'enable'
  // if connection.state === active, and the CTA to 'disable'
  // if connection.state === validating, then we set status to pending
  // and then check the organization
  // if organization domain.state!== verified, then we set cta to 'verify domain'
  // else set cta to 'setup sso'

  const handleCtaClick = async () => {
    switch (status) {
      case 'enabled':
        toggleDeleteSSOModal();
        break;
      case 'pendingDomainValidation': {
        try {
          setGenerateAdminPortalError(null);
          const { link } = await api.generateWorkOsAdminPortalUrl(
            organizationId,
            'domain_verification',
            window.location.href,
            `${window.location.origin}/private-callback?type=sso_domain_verification&success=true`,
          );
          window.location.href = link;
        } catch (err) {
          setGenerateAdminPortalError(err);
        }
        break;
      }
      case 'pendingSsoSetup': {
        try {
          setGenerateAdminPortalError(null);
          const { link } = await api.generateWorkOsAdminPortalUrl(
            organizationId,
            'sso',
            window.location.href,
            window.location.href,
          );
          window.location.href = link;
        } catch (err) {
          setGenerateAdminPortalError(err);
        }
        break;
      }
      case 'unavailable':
        openChat();
        break;
      case 'disabled': {
        setPendingOrganizationCreation(true);
        createWorkOsOrganizationRequest(organizationId);
        break;
      }
      default:
      // console.log('Unknown status');
    }
  };

  return (
    <React.Fragment>
      {deleteSSOModalOpen && (
        <DeleteSSOConnectionModal
          toggleModal={toggleDeleteSSOModal}
          organizationId={organizationId}
        />
      )}
      {status && staticText.sso.state[status].feedback &&
        <Feedback
          message={staticText.sso.state[status].feedback}
          type={staticText.sso.state[status].feedbackType}
          inlineFunction={status === 'unavailable' && openChat}
        />
      }
      <Section>
        {status ? (
          <React.Fragment>
            <Typography variant="h2" sx={{ mb: 3 }}>
              {staticText.sso.title}
            </Typography>
            <Typography variant="body2" mb={2}>
              {staticText.sso.description}
            </Typography>
            <Divider sx={{ my: 4 }} data-testid="modal-divider" />
            {status && staticText.sso.state[status] && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <StatusChip
                    label={staticText.sso.state[status].chip}
                    variant={staticText.sso.state[status].chipVariant}
                  />
                  <Button
                    variant={staticText.sso.state[status].ctaVariant}
                    color="primary"
                    type="button"
                    sx={{
                      ...(ssoSetupInProgress && spinningButtonStyles),
                      px: 2.5,
                      py: 1,
                    }}
                    onClick={handleCtaClick}
                  >
                    {staticText.sso.state[status].cta}
                  </Button>
                </Box>
                {generateAdminPortalError && (
                  <Typography variant="caption" color="error" alignSelf="end">
                    {staticText.urlGenerationError}
                  </Typography>
                )}
              </Box>
            )}
          </React.Fragment>
      ) : (
        <Spinner size="small" />
      )}
      </Section>
    </React.Fragment>
  );
};

export default SSO;

SSO.propTypes = {
  organization: shape({
    id: string,
    addOns: arrayOf(string),
  }),
  workOsOrganization: shape({
    id: string,
    domains: arrayOf(
      shape({
        id: string,
        domain: string,
      }),
    ),
  }).isRequired,
  workOsConnection: shape({
    id: string,
    state: string,
  }),
  isFetchingWorkOsOrganization: bool.isRequired,
  isFetchingWorkOsConnection: bool.isRequired,
  ssoSetupInProgress: bool.isRequired,
  actions: shape({
    createWorkOsOrganizationRequest: func.isRequired,
    fetchWorkOsOrganizationRequest: func.isRequired,
  }).isRequired,
};

SSO.defaultProps = {
  organization: {},
  workOsConnection: null,
};
