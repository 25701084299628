const staticText = {
  heading: 'Members',
  deleteOrgInviteSuccessToast: 'Successfully deleted invitation',
  deleteOrgMemberSuccessToast: 'Successfully deleted team member',
  updateOrgMemberSuccessToast: 'Successfully updated team member',
  addMembers: 'Invite members',
  ssoConnectionDisabledTooltip: 'Inviting members is disabled when single-sign on (SSO) is enabled for your organization. Users can sign up for an account with the email associated with the SSO.',
};

export const modalType = {
  sendInvites: 'sendInvites',
  editUser: 'editUser',
  deleteUser: 'deleteUser',
};

export default staticText;
