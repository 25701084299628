import { Button, Menu, styled } from '@mui/material';

export const RedirectUriMenuButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderRadius: theme.border.radius,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.common.white,

  '& span': {
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const RedirectUriMenu = styled(Menu)(({ theme }) => ({
  marginTop: '-4px',

  '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
    border: 'none',
    borderRadius: theme.border.radius,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },

  '.MuiList-root': {
    padding: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    borderRadius: theme.border.radius,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },

  '.MuiMenuItem-root': {
    padding: theme.spacing(1),
    color: theme.palette.grey[600],
    borderRadius: 0,

    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export const RedirectUriModalButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1),
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,

  '& svg': {
    marginTop: '-4px',
    marginRight: theme.spacing(1),
  },

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.grey[200],
  },
}));
