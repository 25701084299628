/* istanbul ignore next */
import dateUtils from '../../../../../utils/dateUtils';

const formatFilterValues = (filterValues, keepSelectedChartMakes) => {
  // Conditional determines if we are sending all makes in the request or just
  // the selected makes from clicking on the Overview
  // `all vehicles by make` chart (see chartConfiguration line 296 - 300)
  const hasSelectedAllMakes = Object.values(filterValues.makes).every(make => make)
   && !keepSelectedChartMakes;

  const hasSelectedAllStatusCodes = Object.values(filterValues.statusCodes)
    .every(statusCode => statusCode);

  const formattedMakes = [];
  const formattedStatusCodes = [];

  // skip logic if all makes are selected
  if (!hasSelectedAllMakes) {
    Object.keys(filterValues.makes).forEach((make) => {
      if (filterValues.makes[make]) formattedMakes.push(make);
    });
  }

  if (!hasSelectedAllStatusCodes) {
    Object.keys(filterValues.statusCodes).forEach((statusCode) => {
      if (filterValues.statusCodes[statusCode]) formattedStatusCodes.push(statusCode);
    });
  }

  const start = dateUtils.getStartOfDayUtc(filterValues.start).toISOString();
  const end = dateUtils.getEndOfDayUtc(filterValues.end).toISOString();

  const formattedFilters = {
    ...filterValues,
    /*
      If all makes or statusCodes are selected, we can send an empty array as a query param
      for makes which is the equivalent of selecting all checkboxes.
      Additionally, this speeds up db performance.
    */
    makes: hasSelectedAllMakes ? [] : formattedMakes,
    statusCodes: hasSelectedAllStatusCodes ? [] : formattedStatusCodes,
    start,
    end,
  };

  return formattedFilters;
};

export default formatFilterValues;
