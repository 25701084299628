/* istanbul ignore file */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { object, func } from 'prop-types';

import {
  FormControl,
  useTheme,
  Typography,
  Box,
} from '@mui/material';
import staticText from '../../../../../../../../../../localization/Application/connect-config';
import { MenuItemWrapper } from '../../../../../../../../../../components';

import {
  getEngineTypeFilterStatusAndTitle,
  engineTypeLabel,
  engineFilters,
} from './utils';

import EngineFilterForm from '../EngineFilterForm/EngineFilterForm';

const EngineFilter = ({
  brandManagementFilterOptions,
  selectedBrandManagementFilters,
  updateCustomizations,
}) => {
  const theme = useTheme();

  return (
    <Box marginBottom={2}>
      <Typography>
        {staticText.brandManagement.filterByEngineType}
      </Typography>
      <FormControl sx={{
        margin: theme.spacing(1, 1, 1, 0),
         width: 200,
         }}
      >
        {engineFilters.map(({
        filterKey, id, minWidth, icon,
      }) => {
        const {
          isFilterActive,
          menuItemWrapperTitle,
        } = getEngineTypeFilterStatusAndTitle(
          brandManagementFilterOptions,
          selectedBrandManagementFilters,
        );

        return (
          <MenuItemWrapper
            title={menuItemWrapperTitle}
            isFilterActive={isFilterActive}
            key={filterKey}
            Icon={icon}
            id={id}
            minWidth={minWidth}
            filterKey={filterKey}
            // disabled={}
          >
            <EngineFilterForm
              selectedBrandManagementFilters={selectedBrandManagementFilters}
              updateCustomizations={updateCustomizations}
              engineTypeLabel={engineTypeLabel}
            />
          </MenuItemWrapper>
        );
      })}
      </FormControl>
    </Box>
  );
};

export default EngineFilter;

EngineFilter.propTypes = {
  brandManagementFilterOptions: object.isRequired,
  selectedBrandManagementFilters: object.isRequired,
  updateCustomizations: func.isRequired,
};
