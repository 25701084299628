import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { LoadingRectangle } from '../../../../../../global-styles/components';
import DateButtonGroup from '../DateButtonGroup';

const ChartWrapper = ({
  chartKey,
  children,
  featureSetId,
  isLoading,
  linkOut,
  orderIndex,
  setFilter,
  showLoading,
  timeInterval,
  title,
}) => {
  const theme = useTheme();

  let subtitle = '';
  const daysBefore = moment.utc().subtract(timeInterval - 1, 'days').format('MMM D, YYYY');
  if (chartKey === 'top5MakesByConnectedVehiclesChart') {
    subtitle = `As of ${daysBefore}`;
  } else {
    const currentDay = moment.utc().format('MMM D, YYYY');
    if (timeInterval === 1) {
      subtitle = currentDay;
    } else {
      subtitle = `${daysBefore} - ${currentDay}`;
    }
  }

  return (
    <Box sx={{
      width: '100%',
      border: 1,
      borderColor: 'grey.200',
      borderRadius: 1,
      padding: 3,
      boxSizing: 'border-box',
      order: orderIndex,
      minHeight: '417px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      {isLoading && showLoading ? (
        <React.Fragment>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <LoadingRectangle
                sx={{
                  width: '290px',
                  height: '18px',
                  margin: theme.spacing(0, 0, 0.5, 0),
                  flexGrow: 0,
                }}
              />
              <LoadingRectangle
                sx={{
                  width: '290px',
                  height: '18px',
                  margin: '0px',
                  flexGrow: 0,
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LoadingRectangle
                sx={{
                  width: '136px',
                  height: '37.5',
                  flexGrow: 0,
                }}
              />
              {linkOut && (
                <LoadingRectangle
                  sx={{
                    width: '37.5px',
                    height: '37.5px',
                    marginLeft: '12px',
                    flexGrow: 0,
                  }}
                />
              )}
            </Box>
          </Box>
          <LoadingRectangle
            sx={{
              marginTop: theme.spacing(5),
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Typography component="h2" variant="h4" sx={{ fontSize: '.875rem' }}>
                {title}
              </Typography>
              <Typography
                component="subtitle2"
                variant="body2"
                sx={{ fontSize: '.75rem', color: theme.palette.grey[600] }}
              >
                {subtitle}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {setFilter && (
                <DateButtonGroup
                  daysFilterValue={timeInterval}
                  chartKey={chartKey}
                  setFilter={setFilter}
                  featureSetId={featureSetId}
                />
              )}
              {linkOut && (
                <IconButton
                  aria-label="link out"
                  component="div"
                  size="small"
                  sx={{
                    '& > a': {
                      color: 'inherit',
                      textDecoration: 'none',
                      fontSize: theme.spacing(1.75),
                    },
                    borderRadius: theme.spacing(1),
                    border: `1px solid ${theme.palette.grey[200]}`,
                    padding: '0px',
                    marginLeft: theme.spacing(1.5),
                  }}
                >
                  <Link to={linkOut}>
                    <OpenInNewRounded
                      size="small"
                      sx={{
                        display: 'block',
                        padding: theme.spacing(1),
                        width: '18px',
                        height: '18px',
                        color: theme.palette.redesignPalette.primary.main,
                      }}
                    />
                  </Link>
                </IconButton>
              )}
            </Box>
          </Box>
          {children}
        </React.Fragment>
      )}
    </Box>
  );
};

export default ChartWrapper;

ChartWrapper.propTypes = {
  featureSetId: string.isRequired,
  chartKey: string,
  isLoading: bool.isRequired,
  showLoading: bool.isRequired,
  linkOut: string,
  orderIndex: number,
  setFilter: func,
  timeInterval: number.isRequired,
  title: string.isRequired,
  children: node.isRequired,
};

ChartWrapper.defaultProps = {
  setFilter: null,
  chartKey: '',
  linkOut: '',
  orderIndex: 0,
};

