import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, useTheme, styled, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import PermissionGate from '../../../../components/PermissionGate';
import { HomeLink } from './styles';

const Icon = styled('span')(() => ({
  marginTop: '-3px',
  marginLeft: '-4px',
  lineHeight: '0',
}));

const ApplicationHeader = ({
  backLink,
  heading,
  cta,
  dashboardPermission,
  description,
}) => {
  const theme = useTheme();
  const getIcon = () => {
    if (!(cta.plusIcon || cta.disconnectIcon)) return null;

    return (
      <Icon sx={{ marginRight: '4px' }}>
        {(cta.plusIcon && <AddRoundedIcon fontSize="small" />)
          || (cta.disconnectIcon && <LinkOffIcon fontSize="small" />)
        }
      </Icon>
    );
  };

  // Render the button with or without tooltip
  const renderButton = () => {
    const button = (
      <Button
        id={`${heading.toLowerCase().replace(' ', '-')}-header-cta`}
        onClick={cta.onclick}
        variant={cta.variant || 'outlined'}
        display="flex"
        disabled={cta.disabled}
      >
        {getIcon()}
        {cta.text}
      </Button>
    );

    // Get the appropriate tooltip text based on state
    const tooltipText = cta.disabled && cta.disabledTooltip
      ? cta.disabledTooltip
      : cta.tooltip;

    // Show tooltip if either tooltip or disabledTooltip is provided
    if (tooltipText) {
      return (
        <Tooltip arrow title={tooltipText}>
          <span>{button}</span>
        </Tooltip>
      );
    }

    return button;
  };

  return (
    <React.Fragment>
      {backLink && backLink.path && (
        <HomeLink>
          <Link to={backLink.path} onClick={backLink.handleClick}>
            <Typography><ArrowBackRoundedIcon fontSize="16px" />{backLink.text}</Typography>
          </Link>
        </HomeLink>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={theme.spacing(2)}
      >
        <Typography variant="h1" minHeight={theme.spacing(6)}>{heading}</Typography>
        {cta && cta.text && cta.onclick && (
          <div>
            <PermissionGate dashboardPermission={dashboardPermission}>
              {renderButton()}
            </PermissionGate>
          </div>
        )}
      </Box>

      {description &&
        <Typography mb={3}>{description}</Typography>
      }
    </React.Fragment>
  );
};

export default ApplicationHeader;

ApplicationHeader.propTypes = {
  backLink: shape({
    path: string,
    handleClick: func,
    text: string,
  }),
  heading: string.isRequired,
  cta: shape({
    text: string.isRequired,
    onclick: func.isRequired,
    variant: string,
    plusIcon: bool,
    disconnectIcon: bool,
    disabled: bool,
    tooltip: string,
    disabledTooltip: string,
  }),
  dashboardPermission: string,
  description: string,
};

ApplicationHeader.defaultProps = {
  backLink: null,
  cta: null,
  dashboardPermission: '',
  description: '',
};
