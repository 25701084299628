import React from 'react';
import { Grid, Typography } from '@mui/material';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import moment from 'moment-timezone';

import { InfoSection, Spinner } from '../../../../../../../../components';
import { articleIcon, logsIcon } from '../../../../../../../../assets/icons';
import staticText from '../../../../../../../../localization/Application/VehicleManagement/vehicleDetails';
import dateUtils from '../../../../../../../../utils/dateUtils';
import { ErrorsList } from '../../../shared/styles';

const VehicleOverview = ({ info, loading, errors }) => {
  const {
    apiCallLimit,
    billingPeriodEndDate,
    connectedAt,
    userId,
    vehicleId,
    requestCount,
  } = info;

  const { fieldTitles } = staticText.overview;

  let requestTooltip = staticText.billingEndOfCycle;
  if (billingPeriodEndDate === '') {
    // free plans returns empty string
    requestTooltip = staticText.billingEndOfMonth;
  } else if (typeof billingPeriodEndDate === 'number') {
    // paid plans returns unix time
    requestTooltip = staticText.billingPeriodEndDate(dateUtils.format(billingPeriodEndDate, 'LL'));
  }

  const formattedInfo = {
    overview: {
      header: staticText.overview.overviewHeader,
      iconSrc: articleIcon,
      items: [
        {
          field: 'vehicleId',
          title: fieldTitles.vehicleId,
          value: vehicleId,
        },
        {
          field: 'userId',
          title: fieldTitles.userId,
          value: userId,
        },
        {
          field: 'connectedAt',
          title: fieldTitles.connectedAt,
          value: `${moment(connectedAt).utc().format('MMM D, YYYY HH:mm z')}`,
        },
      ],
    },
    requests: {
      header: staticText.overview.requestsHeader,
      iconSrc: logsIcon,
      tooltip: requestTooltip,
      items: [
        {
          field: 'billableRequests',
          title: fieldTitles.billableRequests,
          value: requestCount,
        },
        {
          field: 'allocatedRequests',
          title: fieldTitles.allocatedRequests,
          value: apiCallLimit,
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Typography variant="h2" mb={4}>{staticText.overviewHeader}</Typography>
      {loading && <Spinner size="small" additionalClassNames="vehicle-overview" />}
      {!loading &&
        <React.Fragment>
          <Grid container columnSpacing={{ xs: 8, xl: 16 }}>
            <Grid item xs={7}>
              <InfoSection {...formattedInfo.overview} />
            </Grid>
            <Grid item xs={5}>
              <InfoSection {...formattedInfo.requests} />
            </Grid>
          </Grid>
          <ErrorsList alignItems="flex-start">
            {errors.map(([errorKey, message]) => {
              return (
                <li key={errorKey}>
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                </li>
              );
            })}
          </ErrorsList>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default VehicleOverview;

VehicleOverview.propTypes = {
  info: shape({
    apiCallLimit: number,
    billingPeriodEndDate: oneOfType([string, number]),
    connectedAt: string,
    userId: string,
    requestCount: number,
    vehicleId: string,
  }).isRequired,
  loading: bool.isRequired,
  errors: arrayOf(arrayOf(string)).isRequired,
};
