import React, { useEffect } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Divider, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { ChevronDown } from 'react-feather';

import { StyledChip, StyledSelect, MenuProps } from './styles';
import staticText from '../../../../../../localization/Application/Members/inviteEditUsers';
import { canAccessAllApps, dashboardRole as constants } from '../../utils/roles';

const MultiAppSelect = ({
  availableApps,
  error,
  selectedApps,
  selectedRole,
  handleChange,
  handleAppDelete,
  toggleAll,
}) => {
  const allSelected = availableApps.length === selectedApps.length;
  const labelId = 'app-select-label';
  const forceAllApps = canAccessAllApps(selectedRole);
  const nameMap = availableApps.reduce((acc, app) => {
    acc[app.id] = app.name;
    return acc;
  }, {});
  const allAppsText = selectedRole === constants.EDITOR
    ? staticText.appAccess.allAppsAdminOnly
    : staticText.appAccess.allApplications;

  const decorateSelect = (appIds) => {
    if (allSelected) {
      return <Typography>{allAppsText}</Typography>;
    }
    return appIds.map(id => (
      <StyledChip
        key={id}
        label={nameMap[id]}
        variant="filled"
        sx={{ marginBottom: 1 }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDelete={(e) => {
          e.stopPropagation();
          handleAppDelete(id);
        }}
      />
    ));
  };

  useEffect(() => {
    if (forceAllApps) {
      toggleAll('all');
    }
  }, [forceAllApps]);

  useEffect(() => {
    if ([constants.EDITOR, constants.VIEWER].includes(selectedRole) && allSelected) {
      toggleAll('none');
    }
  }, [selectedRole]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={labelId} >{staticText.appAccess.label}</InputLabel>
      <StyledSelect
        id="app-select"
        IconComponent={ChevronDown}
        label={staticText.appAccess.label}
        labelId={labelId}
        variant="outlined"
        multiple
        MenuProps={{
          ...MenuProps,
          // prevents auto scroll to bottom if all apps are selected
          autoFocus: !allSelected,
        }}
        onChange={handleChange}
        value={selectedApps}
        renderValue={decorateSelect}
        disabled={forceAllApps}
        error={Boolean(error)}
      >
        <div id="all-apps-toggle">
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              if (!forceAllApps) {
                toggleAll();
              }
            }}
          >
            <Typography variant={allSelected ? 'bold' : 'inherit'} color={allSelected ? 'text.primary' : 'inherit'}>
              {allAppsText}
            </Typography>
          </MenuItem>
        </div>
        <Divider />
        {availableApps.map(app => (
          <MenuItem
            key={app.id}
            value={app.id}
            sx={{ height: '50px' }}
          >
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {app.name}
            </Typography>
          </MenuItem>
        ))}
      </StyledSelect>
      {error && (
        <Typography variant="caption" color="error" m="3px 14px">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default MultiAppSelect;

MultiAppSelect.propTypes = {
  availableApps: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  error: string.isRequired,
  selectedApps: arrayOf(string).isRequired,
  selectedRole: string.isRequired,
  handleChange: func.isRequired,
  handleAppDelete: func.isRequired,
  toggleAll: func.isRequired,
};
