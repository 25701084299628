import React, { useContext, useEffect } from 'react';
import { array, arrayOf, bool, func, number, shape, string } from 'prop-types';
import { reportToSegment, types } from '@smartcar/morse';
import moment from 'moment-timezone';
import { Button, Box, Typography } from '@mui/material';
import { DirectionsCarRounded, CalendarMonthRounded, TrendingUpRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import ApplicationHeader from '../ApplicationHeader';
import { Banner, ConnectInsightsFunnel, WidgetCard } from '../../../../components';
import {
  APIRequestsOverTimeChart,
  ChartWrapper,
  ConnectedVehiclesOverTimeChart,
  Top5MakesByConnectedVehiclesChart,
} from './components';

import { useDelay } from '../../../../hooks';

import staticText from '../../../../localization/Application/Overview/overview';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import { CommandAiContext } from '../../../../providers/CommandAi';
import { triggerOnboardingChecklist } from '../../../../services/commandAi/commandAi';
import { organizationRole } from '../Members/utils/roles';


const chartKeyToComponentMap = {
  apiRequestsOverTimeChart: APIRequestsOverTimeChart,
  connectedVehiclesOverTimeChart: ConnectedVehiclesOverTimeChart,
  top5MakesByConnectedVehiclesChart: Top5MakesByConnectedVehiclesChart,
};

const upgradeIconMap = {
  car: DirectionsCarRounded,
  calendar: CalendarMonthRounded,
  arrowUp: TrendingUpRounded,
};


const roundToTwoDecimals = (num) => {
  const multiplier = 10 ** 2;
  return Math.round(num * multiplier) / multiplier;
};

const formatDeltaData = ({ last7Days, previous7Days, percentChange }) => {
  const changeString = 'change from previous 7 days';
  if (percentChange === null) {
    if (last7Days === '0' && previous7Days === '0') {
      return {
        delta: 'none',
        text: `0% ${changeString}`,
      };
    }
    return {
      delta: 'up',
      text: `100% ${changeString}`,
    };
  }

  const percentChangeNumber = Number(percentChange);
  const changeText = `${roundToTwoDecimals(percentChangeNumber)}% ${changeString}`;
  if (percentChangeNumber > 0) {
    return {
      delta: 'up',
      text: changeText,
    };
  }
  if (percentChangeNumber < 0) {
    return {
      delta: 'down',
      text: changeText,
    };
  }
  return {
    delta: 'none',
    text: changeText,
  };
};

const Overview = (props) => {
  const {
    actions: {
      fetchConnectFunnel,
      fetchApiRequestsOverTime,
      fetchConnectedVehiclesOverTime,
      fetchTop5MakesByConnectedVehicles,
      fetchWidgets,
      setFilter,
      startOnboardingPolling,
    },
    apiRequestsOverTimeChart,
    connectedVehiclesOverTimeChart,
    connectFunnel,
    match,
    organization,
    showOnboarding,
    top5MakesByConnectedVehiclesChart,
    widgets,
  } = props;
  const theme = useTheme();

  const { applicationId } = match.params;
  const { isBooted } = useContext(CommandAiContext);
  const { afterDelay: showLoading, resetTimer } = useDelay(200);

  let showOnboardingChecklist = showOnboarding;
  if (organization.organizationRole !== organizationRole.FULL_ACCESS) {
    showOnboardingChecklist = false;
  }
  const funnelGated = !!gatedFeatureData(FEATURES.CONNECT_FUNNEL, organization.featureSetId);

  const getFunnel = () => {
    if (funnelGated) return;
    fetchConnectFunnel(applicationId);
  };

  const refreshOverviewData = () => {
    getFunnel();
    fetchApiRequestsOverTime(applicationId);
    fetchConnectedVehiclesOverTime(applicationId);
    fetchTop5MakesByConnectedVehicles(applicationId);
    fetchWidgets(applicationId);
    resetTimer();
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Overview');

    refreshOverviewData();
  }, []);

  useEffect(() => {
    if (isBooted && showOnboardingChecklist) {
      triggerOnboardingChecklist();
      startOnboardingPolling();
    }
  }, [isBooted, showOnboardingChecklist]);

  // refresh the data if there is a change in the date filter for any of the charts
  useEffect(() => {
    fetchApiRequestsOverTime(applicationId);
    resetTimer();
  }, [apiRequestsOverTimeChart.filter.days]);
  useEffect(() => {
    fetchConnectedVehiclesOverTime(applicationId);
    resetTimer();
  }, [connectedVehiclesOverTimeChart.filter.days]);
  useEffect(() => {
    fetchTop5MakesByConnectedVehicles(applicationId);
    resetTimer();
  }, [top5MakesByConnectedVehiclesChart.filter.days]);


  const monthToDate = `As of ${moment.utc().startOf('month').format('MMM D, YYYY')}`;
  const pastTwoWeeks = `${moment.utc().subtract(13, 'days').format('MMM D, YYYY')} - ${moment.utc().format('MMM D, YYYY')}`;
  const widgetCardsSection = (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        mb: 3,
        flexWrap: 'wrap',
      }}
    >
      <WidgetCard
        title={staticText.widgets.connectedVehicles.title}
        subtitle={monthToDate}
        main={widgets.connectedVehicles.count}
        tooltip={staticText.widgets.connectedVehicles.tooltip}
        label={staticText.widgets.connectedVehicles.label}
        isLoading={widgets.isLoading}
        showLoading={showLoading}
      />
      <WidgetCard
        title={staticText.widgets.activeVehiclesWoWChange.title}
        subtitle={pastTwoWeeks}
        main={widgets.activeVehiclesWoWChange.last7Days}
        tooltip={staticText.widgets.activeVehiclesWoWChange.tooltip}
        deltaChipContent={formatDeltaData(widgets.activeVehiclesWoWChange)}
        isLoading={widgets.isLoading}
        showLoading={showLoading}
      />
      <WidgetCard
        title={staticText.widgets.newConnectionsWoWChange.title}
        subtitle={pastTwoWeeks}
        main={widgets.newConnectionsWoWChange.last7Days}
        tooltip={staticText.widgets.newConnectionsWoWChange.tooltip}
        deltaChipContent={formatDeltaData(widgets.newConnectionsWoWChange)}
        isLoading={widgets.isLoading}
        showLoading={showLoading}
      />
    </Box>
  );


  // eslint-disable-next-line no-unused-vars
  const upgradeBanner = (
    <Banner
      severity="warning"
      showIcon={false}
      largePadding
      action={(
        <Button
          variant="contained"
          component="div"
          sx={{
              backgroundColor: theme.palette.warning.primary,

              '&:hover': {
                backgroundColor: theme.palette.warning.main,
                borderColor: theme.palette.warning.main,
              },
              '& > a': {
                color: theme.palette.text.primary,
                textDecoration: 'none',
              },
            }}
        >
          <Link to={staticText.freePlanLimitReachedBanner.upgradeButton.path}>
            {staticText.freePlanLimitReachedBanner.upgradeButton.text}
          </Link>
        </Button>
        )}
    >
      <Box>
        <Typography variant="body1" sx={{ fontSize: '0.875rem', marginBottom: theme.spacing(1) }}>
          {staticText.freePlanLimitReachedBanner.title}
        </Typography>
        <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
        >
          {staticText.freePlanLimitReachedBanner.upgradeFeatures.map((feature) => {
              const Icon = upgradeIconMap[feature.icon];
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} key={feature.label}>
                  <Icon sx={{ fontSize: '18px', marginRight: theme.spacing(0.5) }} />
                  <Typography variant="subtitle2" sx={{ color: theme.palette.common.black }}>{feature.label}</Typography>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Banner>
  );

  return (
    <Box sx={{ maxWidth: '1600px' }}>
      <ApplicationHeader heading={staticText.heading} />
      {widgetCardsSection}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {Object.keys(staticText.charts).map((chartKey, index) => {
          const ChartComponent = chartKeyToComponentMap[chartKey];
          let orderIndex = index;
          if (!funnelGated && index > 0) {
            orderIndex = index + 1;
          }

          return (
            <ChartWrapper
              key={chartKey}
              title={staticText.charts[chartKey].title}
              timeInterval={props[chartKey].filter.days}
              chartKey={chartKey}
              linkOut={`/apps/${applicationId}/${staticText.charts[chartKey].linkOut}`}
              setFilter={setFilter}
              featureSetId={organization.featureSetId}
              orderIndex={orderIndex}
              isLoading={props[chartKey].isLoading}
              showLoading={showLoading}
            >
              {props[chartKey].data && (
                <ChartComponent
                  applicationId={applicationId}
                  dataset={props[chartKey].data}
                  timeInterval={props[chartKey].filter.days}
                  showOnboarding={showOnboarding}
                />
              )}
            </ChartWrapper>
          );
        })}
        <ChartWrapper
          title={staticText.connectFunnel.title}
          timeInterval={14}
          featureSetId={organization.featureSetId}
          linkOut={`/apps/${applicationId}/${staticText.connectFunnel.linkOut}`}
          orderIndex={funnelGated ? 3 : 1}
        >
          <Box sx={{ mt: 5 }}>
            <ConnectInsightsFunnel
              funnel={connectFunnel.data}
              funnelGated={funnelGated}
              isFetching={connectFunnel.isLoading}
              showLoading={showLoading}
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Box>
  );
};

export default Overview;

Overview.propTypes = {
  actions: shape({
    fetchApiRequestsOverTime: func.isRequired,
    fetchConnectedVehiclesOverTime: func.isRequired,
    fetchTop5MakesByConnectedVehicles: func.isRequired,
    fetchWidgets: func.isRequired,
    setFilter: func.isRequired,
    startOnboardingPolling: func.isRequired,
  }).isRequired,
  apiRequestsOverTimeChart: shape({
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }).isRequired,
  connectedVehiclesOverTimeChart: shape({
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }).isRequired,
  connectFunnel: shape({
    data: arrayOf(shape({
      count: string.isRequired,
      stage: string.isRequired,
      order: number.isRequired,
      displayName: string.isRequired,
      percent: number.isRequired,
    })).isRequired,
    error: string,
    isLoading: bool.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
  organization: {
    featureSetId: string.isRequired,
  }.isRequired,
  showOnboarding: bool.isRequired,
  top5MakesByConnectedVehiclesChart: {
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }.isRequired,
  widgets: shape({
    connectedVehicles: shape({
      count: number,
    }).isRequired,
    activeVehiclesWoWChange: shape({
      last7Days: string,
      percentChange: string,
    }).isRequired,
    newConnectionsWoWChange: shape({
      last7Days: string,
      percentChange: string,
    }).isRequired,
    apiRequestCount: shape({
      count: number,
    }).isRequired,
  }).isRequired,
};
