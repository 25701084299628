/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { Button, Box, Typography } from '@mui/material';
import { DirectionsCarRounded, CalendarMonthRounded, TrendingUpRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import ApplicationHeader from '../ApplicationHeader';
import { Banner } from '../../../../components';
import {
  APIRequestsOverTimeChart,
  ChartWrapper,
  ConnectedVehiclesOverTimeChart,
  Top5MakesByConnectedVehiclesChart,
} from './components';

import staticText from '../../../../localization/Application/Overview/overview';

// import mockData from './test/mockData';

const chartKeyToComponentMap = {
  apiRequestsOverTimeChart: APIRequestsOverTimeChart,
  connectedVehiclesOverTimeChart: ConnectedVehiclesOverTimeChart,
  top5MakesByConnectedVehiclesChart: Top5MakesByConnectedVehiclesChart,
};

const upgradeIconMap = {
  car: DirectionsCarRounded,
  calendar: CalendarMonthRounded,
  arrowUp: TrendingUpRounded,
};

const Overview = (props) => {
  const theme = useTheme();
  // const props = {
  //   apiRequestsOverTimeChart: {
  //     data: mockData.apiRequestsOvertimeChart.one,
  //     error: '',
  //     filter: {
  //       days: 1, // default to 1 day
  //     },
  //     isLoading: false,
  //   },
  //   connectedVehiclesOverTimeChart: {
  //     data: mockData.connectedVehiclesOverTimeChart.one,
  //     error: '',
  //     filter: {
  //       days: 1, // default to 1 day
  //     },
  //     isLoading: false,
  //   },
  //   top5MakesByConnectedVehiclesChart: {
  //     data: mockData.top5MakesByConnectedVehiclesChart.one,
  //     error: '',
  //     filter: {
  //       days: 1, // default to 1 day
  //     },
  //     isLoading: false,
  //   },
  // };

  const { applicationId } = props.match.params;

  return (
    <Box sx={{ maxWidth: '1600px' }}>
      <ApplicationHeader heading={staticText.heading} />
      <Banner
        severity="warning"
        showIcon={false}
        largePadding
        action={(
          <Button
            variant="contained"
            component="div"
            sx={{
              backgroundColor: theme.palette.warning.primary,

              '&:hover': {
                backgroundColor: theme.palette.warning.main,
                borderColor: theme.palette.warning.main,
              },
              '& > a': {
                color: theme.palette.text.primary,
                textDecoration: 'none',
              },
            }}
          >
            <Link to={staticText.freePlanLimitReachedBanner.upgradeButton.path}>
              {staticText.freePlanLimitReachedBanner.upgradeButton.text}
            </Link>
          </Button>
        )}
      >
        <Box>
          <Typography variant="body1" sx={{ fontSize: '0.875rem', marginBottom: theme.spacing(1) }}>
            {staticText.freePlanLimitReachedBanner.title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {staticText.freePlanLimitReachedBanner.upgradeFeatures.map((feature) => {
              const Icon = upgradeIconMap[feature.icon];
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} key={feature.label}>
                  <Icon sx={{ fontSize: '18px', marginRight: theme.spacing(0.5) }} />
                  <Typography variant="subtitle2" sx={{ color: theme.palette.common.black }}>{feature.label}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Banner>
      {Object.keys(staticText.charts).map((chartKey) => {
        const ChartComponent = chartKeyToComponentMap[chartKey];
        return (
          <ChartWrapper
            key={chartKey}
            title={staticText.charts[chartKey].title}
            timeInterval={props[chartKey].filter.days}
            chartKey={chartKey}
            setFilter={props.actions.setFilter}
            featureSetId={props.organization.featureSetId}
          >
            <ChartComponent
              applicationId={applicationId}
              dataset={props[chartKey].data}
              timeInterval={props[chartKey].filter.days}
              showOnboarding={props.showOnboarding}
            />
          </ChartWrapper>
        );
      })}
    </Box>
  );
};

export default Overview;

Overview.propTypes = {
  actions: shape({
    fetchApiRequestsOverTime: func.isRequired,
    fetchConnectedVehiclesOverTime: func.isRequired,
    fetchTop5MakesByConnectedVehicles: func.isRequired,
    fetchWidgets: func.isRequired,
    setFilter: func.isRequired,
    startOnboardingPolling: func.isRequired,
  }).isRequired,
  apiRequestsOverTimeChart: shape({
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }).isRequired,
  connectedVehiclesOverTimeChart: shape({
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
  organization: {
    featureSetId: string.isRequired,
  }.isRequired,
  showOnboarding: bool.isRequired,
  top5MakesByConnectedVehiclesChart: {
    data: array.isRequired,
    error: string,
    filter: shape({
      days: number.isRequired,
    }).isRequired,
    isLoading: bool.isRequired,
  }.isRequired,
};
