import React from 'react';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { string, func } from 'prop-types';
import { Button, useTheme } from '@mui/material';

/**
 * InlineFunction component renders text with an inline clickable function.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.text - Text content that may contain function buttons
 *                             Format: "Regular text |+|function@@Button Text|+| more text"
 *                             The |+| delimiter separates text sections
 *                             The function@@ prefix indicates a function button
 * @param {Function} props.onClickFunction - Callback function that executes the button is clicked
 * @returns {React.ReactElement}
 * @example
 * // Basic usage
 * <InlineFunction
 *   text="Click |+|function@@here|+| to perform action"
 *   onClickFunction={() => console.log('Button clicked')}
 * />
 */
const InlineFunction = ({ text, onClickFunction }) => {
  const theme = useTheme();
  const textArr = text.split('|+|');
  if (textArr.length === 1) {
    return <span>{text}</span>;
  }

  return textArr.map((section) => {
    if (!section) return null;
    if (section.includes('@@')) {
      const [descriptor, buttonText] = section.split('@@');

      // Handle function calls - use format: function@@text
      if (descriptor === 'function' && typeof onClickFunction === 'function') {
        return (
          <Button
            key={`${buttonText}`}
            onClick={() => {
              onClickFunction();
              reportToSegment(types.TRACK, eventNames.linkClicked, {
                text: buttonText,
                action: 'functionClick',
                style: 'inline-function',
                section: 'article',
              });
            }}
            variant="text"
            color="primary"
            type="button"
            sx={{
              padding: 0,
              '&:hover': {
                color: theme.palette.teal.light,
                backgroundColor: 'transparent',
              },
            }}
          >
            {buttonText}
          </Button>
        );
      }
    }

    return <span key={section}>{section}</span>;
  });
};

export default InlineFunction;

InlineFunction.propTypes = {
  text: string.isRequired,
  onClickFunction: func,
};

InlineFunction.defaultProps = {
  onClickFunction: null,
};
