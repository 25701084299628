import React from 'react';
import { func, string } from 'prop-types';
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import staticText from '../../../../../../../../localization/Application/connect-config';

const BrandOptInSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.primary.main,
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

const BrandOptIn = ({
  selectedNewBrandOptIn, updateCustomizations,
}) => {
  const NewlyAddedBrandsOptions = staticText.brandManagement.displayNewlyAddedBrands;

  const updateBrandOptIn = () => {
    updateCustomizations({ newBrandOptIn: !selectedNewBrandOptIn });
  };

  return (
    <Box>
      <FormGroup >
        <FormControlLabel
          control={
            <BrandOptInSwitch
              sx={{ m: 1 }}
              // disabled={}
              checked={selectedNewBrandOptIn}
              onChange={updateBrandOptIn}
            />
          }
          label={NewlyAddedBrandsOptions}
        />
      </FormGroup>
    </Box>
  );
};

export default BrandOptIn;

BrandOptIn.propTypes = {
  selectedNewBrandOptIn: string.isRequired,
  updateCustomizations: func.isRequired,
};

BrandOptIn.defaultProps = {};
