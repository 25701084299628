/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { arrayOf, bool, func, object, shape, string, array } from 'prop-types';

import staticText from '../../../../../../localization/Application/connect-config';
import { Dialog, InlineLinks } from '../../../../../../components';

import { BrandList } from './components';
import { CustomizationGroupDescription, CustomizationGroupHeading } from '../../styles';
import { useDelay } from '../../../../../../hooks';

const BrandManagement = ({
  customizations: {
    supportedMakes,
    brandManagementFilters,
    newBrandOptIn,
  },
  updateCustomizations,
  renderErrorMessage,
  defaultSupportedBrands,
  fetchingConnectConfigErrors,
  suggestionDialogOpen,
  setSuggestionDialogOpen,
  brandManagementFilterOptions,
  disabledBrands,
  availableBrands,
}) => {
  const { afterDelay: showLoading } = useDelay();

  const updateSelectedMakes = (makes) => {
    updateCustomizations({ supportedMakes: makes });
    if (makes.length === 1) {
      setSuggestionDialogOpen(true);
    }
  };

  return (
    <div>
      <CustomizationGroupHeading variant="h2">{staticText.brandManagement.heading}</CustomizationGroupHeading>
      <CustomizationGroupDescription>
        {staticText.brandManagement.description}
      </CustomizationGroupDescription>
      <div>
        <BrandList
          availableBrands={availableBrands}
          disabledBrands={disabledBrands}
          applicationMakes={defaultSupportedBrands}
          selectedMakes={supportedMakes || []}
          updateSelectedMakes={updateSelectedMakes}
          selectedBrandManagementFilters={brandManagementFilters}
          brandManagementFilterOptions={brandManagementFilterOptions}
          connectConfigError={fetchingConnectConfigErrors.connectConfig}
          applicationMakesError={fetchingConnectConfigErrors.applicationMakes}
          renderErrorMessage={renderErrorMessage}
          showLoading={showLoading}
          updateCustomizations={updateCustomizations}
          selectedNewBrandOptIn={newBrandOptIn}
          brandManagementFilters={brandManagementFilters}
        />
      </div>
      <Dialog
        open={suggestionDialogOpen}
        setDialogOpen={setSuggestionDialogOpen}
        heading={staticText.brandManagement.singleBrandSuggestion.heading}
        text={<InlineLinks text={staticText.brandManagement.singleBrandSuggestion.text} />}
        cancelText={staticText.brandManagement.singleBrandSuggestion.close}
      />
    </div>
  );
};

export default BrandManagement;

BrandManagement.propTypes = {
  customizations: shape({
    supportedMakes: arrayOf(string),
    brandManagementFilters: arrayOf(array).isRequired,
    newBrandOptIn: bool.isRequired,
    supportedMakesSortOrder: string.isRequired,
  }).isRequired,
  defaultSupportedBrands: arrayOf(
    shape({
      make: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  brandManagementFilterOptions: object.isRequired,
  updateCustomizations: func.isRequired,
  renderErrorMessage: func.isRequired,
  fetchingConnectConfigErrors: shape({
    applicationMakes: string,
    makesByCountry: string,
  }).isRequired,
  suggestionDialogOpen: bool.isRequired,
  setSuggestionDialogOpen: func.isRequired,
  disabledBrands: arrayOf(string).isRequired,
  availableBrands: arrayOf(string).isRequired,
};
