/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { array, arrayOf, bool, func, number, objectOf, shape, string } from 'prop-types';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';
import { GridRow } from '@mui/x-data-grid-pro';

import BrandLogo from '../BrandLogo';
import ConnectEventCard from '../ConnectEventCard';
import CopyButton from '../CopyButton';
import { MoreActions, OpenDrawerIcon } from './components';
import { getWebhookDisplayNameByType } from '../../scenes/Application/components/Webhooks/utils/webhookTypes';

import {
  DataGridPro,
  IdCellText,
  VehicleCell,
  ErrorCellText,
} from './styles';
import { checkmarkIconBlue, cancelGrey } from '../../assets/icons';

const LogTable = ({
  pageInfo,
  getRowId,
  pageSizeOptions,
  paginationModel,
  handlePaginationModelChange,
  handleRowClick,
  loading,
  moreActions,
  openDrawer,
  rowCount,
  rowHeight,
  hideHeader,
  stylingOptions,
  hideFooter,
  customRowRender,
}) => {
  const theme = useTheme();
  const { rows, columns: rawColumns, rowCount: rawRowCount } = pageInfo;

  const renderRow = (params) => {
    if (customRowRender) {
      return customRowRender(params);
    }
    return <GridRow {...params} />;
  };

  const getRenderVehicleCell = () => (params) => {
    const { make, model, year } = params.value;
    return (
      <VehicleCell>
        <BrandLogo make={make} margin="0" />
        <IdCellText style={{ marginLeft: '1rem' }} >{`${year} ${make} ${model}`}</IdCellText>
      </VehicleCell>
    );
  };

  const getRenderIdCell = () => (params) => {
    const [showButtons, setShowButtons] = useState(false);

    return (
      <div
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        style={{ maxWidth: '100%', display: 'flex', alignItems: 'center' }}
      >
        <IdCellText>{params.value}</IdCellText>
        {showButtons && <CopyButton content={params.value} />}
      </div>
    );
  };

  const getRenderSubscribedWebhooks = () => (params) => {
    return (
      <div>
        { params.value > 0 ? <img src={checkmarkIconBlue} alt="vehicle subscribed icon" /> : <img src={cancelGrey} alt="vehicle unsubscribed icon" />}
      </div>
    );
  };

  const getRenderMoreActionsCell = () => (params) => {
    if (!moreActions) return null;

    const { row } = params;
    // if getRowId is not provided, then id MUST exist
    const rowId = getRowId ? getRowId(row) : row.id;
    return (
      <MoreActions
        actionMap={moreActions.actionMap}
        menuItems={moreActions.menuItems}
        row={row}
        rowId={rowId}
      />
    );
  };

  const getRenderOpenDrawer = () => (params) => {
    if (!openDrawer) return null;

    return (
      // only include handleClick if the entire row is not clickable
      <OpenDrawerIcon {...(!handleRowClick && { handleClick: () => openDrawer(params.row) })} />
    );
  };

  const getRenderConnectedAtCell = () => (params) => {
    return moment(params.row.connectedAt).utc().format('YYYY/MM/DD');
  };

  const getRenderErrorCell = () => (params) => {
    const value = String(params.value);
    const isSuccess = value === '200';

    return (
      <div
        style={{
          maxWidth: '210px',
          display: 'flex',
          alignItems: 'center',
          padding: '6px',
          color: isSuccess ?
            `${theme.palette.success.dark}` : `${theme.palette.error.main}`,
          }}
      >
        <ErrorCellText>
          {value} {isSuccess ? 'OK' : 'ERROR'}
        </ErrorCellText>
      </div>
    );
  };

  const getRenderConnectEvent = () => params => <ConnectEventCard row={params.row} />;

  const renderCellMapping = {
    vehicleId: getRenderIdCell(),
    userId: getRenderIdCell(),
    requestId: getRenderIdCell(),
    responseStatusCode: getRenderErrorCell(),
    deliveryStatusCode: getRenderErrorCell(),
    vehicle: getRenderVehicleCell(),
    moreActions: getRenderMoreActionsCell(),
    openDrawer: getRenderOpenDrawer(),
    connectedAt: getRenderConnectedAtCell(),
    eventString: getRenderConnectEvent(),
    requestRoute: params => params.value.displayName,
    webhooks: getRenderSubscribedWebhooks(),
    webhookType: params => getWebhookDisplayNameByType(params.value),
  };

  const decorateColumns = (cols) => {
    return cols.map((column) => {
      const columnCopy = { ...column };
      if (renderCellMapping[column.field]) {
        columnCopy.renderCell = renderCellMapping[column.field];
      }

      return columnCopy;
    });
  };

  const columns = decorateColumns(rawColumns);
  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      pageSizeOptions={pageSizeOptions}
      getRowId={getRowId}
      rowCount={rowCount || rawRowCount}
      autoHeight
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      getRowHeight={params => (params.id && params.id.includes('banner') ? 86 : rowHeight || 61)}
      disableColumnSelector
      loading={loading}
      disableRowSelectionOnClick
      slots={{
        ...(hideHeader && { columnHeaders: () => null }),
        row: renderRow,
      }}
      stylingOptions={stylingOptions}
      {...(handleRowClick && {
        onRowClick: params => handleRowClick(params.row),
      })}
      hideFooter={hideFooter && hideFooter}
    />
  );
};

export default LogTable;

LogTable.propTypes = {
  pageInfo: shape({
    rows: array.isRequired,
    columns: array.isRequired,
    rowCount: number.isRequired,
  }).isRequired,
  getRowId: func,
  pageSizeOptions: arrayOf(number).isRequired,
  paginationModel: shape({
    page: number,
    pageSize: number,
  }).isRequired,
  handlePaginationModelChange: func.isRequired,
  handleRowClick: func,
  loading: bool.isRequired,
  moreActions: shape({
    actionMap: objectOf(func).isRequired,
    menuItems: arrayOf(shape({
      action: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  }),
  openDrawer: func,
  rowCount: number,
  rowHeight: number,
  hideHeader: bool,
  stylingOptions: shape({
    rowHover: string,
  }),
  hideFooter: bool,
  customRowRender: func,
};

LogTable.defaultProps = {
  getRowId: null,
  handleRowClick: null,
  hideHeader: false,
  moreActions: null,
  openDrawer: null,
  rowCount: null,
  rowHeight: null,
  stylingOptions: null,
  hideFooter: false,
  customRowRender: null,
};
