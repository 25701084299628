import React from 'react';
import { string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ArrowLink from '../ArrowLink';

// If you want a link to not refresh the page, use LinkComponent={Link}
// with Link (from react-router-dom) being the prop passed as an argument
// An example can be seen below:
//
// import { Link } from 'react-router-dom';
// <InlineLinks text={staticText.freePlan.link} linkComponent={Link} />
const InlineLinks = ({ text, linkComponent: LinkComponent = ArrowLink }) => {
  const splitOnLinks = text.split('|+|');
  // If there is no link, return the text
  if (splitOnLinks.length === 1) {
    return <span>{text}</span>;
  }

  return splitOnLinks.map((section) => {
    if (!section) return null;
    if (section.includes('@@')) {
      const [linkText, linkUrl] = section.split('@@');
      if (LinkComponent === RouterLink) {
        return (
          <RouterLink key={linkText} to={linkUrl}>
            {linkText}
          </RouterLink>
        );
      }
      return (
        <LinkComponent key={linkText} link={linkUrl} text={linkText} />
      );
    }
    return <span key={section}>{section}</span>;
  });
};

InlineLinks.propTypes = {
  text: string.isRequired,
  linkComponent: React.ComponentType,
};

InlineLinks.defaultProps = {
  linkComponent: ArrowLink,
};

export default InlineLinks;
