import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

import { FormFields, FieldSet, Wrapper, Content } from '../../styles';
import { InputField } from '../../../../components';
import staticText from '../../../../localization/Setup/userInfo';
import authFormState from '../../../Authentication/components/authFormUtils';

const UserInfo = ({
  actions: {
    updateUserInfo,
    resetErrors,
  },
  session: {
    userContext,
  },
  inProgress,
  updateUserInfoError,
}) => {
  const {
    setErroredFields,
    handleFocus,
    handleChange,
    values,
    getErrorMessage,
    getGeneralError,
  } = authFormState();
  const [disableFields, setDisableFields] = useState(false);
  const [maxDropdownHeight, setMaxDropdownHeight] = useState(200);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Setup - User Info');
    resetErrors();
  }, []);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields(updateUserInfoError);
  }, [inProgress, updateUserInfoError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updates = {
      company: values.company,
      industry: values.industry === 'Other' ? values['industry-input'] : values.industry,
      jobTitle: values.jobTitle === 'Other' ? values['jobTitle-input'] : values.jobTitle,
      username: userContext.email,
    };

    updateUserInfo(updates);
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'signup - user info',
      form_content: { values },
    });
  };

  const handleMenuOpen = (e) => {
    const selectBottom = e.target.getBoundingClientRect().bottom;
    const screenHeight = window.innerHeight;
    const maxHeight = screenHeight - selectBottom - 20;

    setMaxDropdownHeight(maxHeight);
  };

  return (
    <Content>
      <Wrapper>
        <Typography variant="h1" sx={{ textTransform: 'none' }} mb={2}>{staticText.title}</Typography>
        <Typography mb={1}>{staticText.subtitle}</Typography>
        <form onSubmit={handleSubmit}>
          <Typography id="error-container" color="error" variant="caption">{getGeneralError()}</Typography>
          <FormFields>
            {staticText.fields.map(field => (field.type === 'select' ?
              (
                <FieldSet key={field.name}>
                  <FormControl>
                    <InputLabel id={`${field.name}-input`}>{field.label}</InputLabel>
                    <Select
                      labelId={`${field.name}-input`}
                      id={field.name}
                      value={values[field.name] || ''}
                      label={field.label}
                      onChange={event => handleChange({
                        key: field.name,
                        value: event.target.value,
                      })}
                      onOpen={handleMenuOpen}
                      disabled={disableFields}
                      required={field.required}
                      MenuProps={{
                        sx: theme => ({
                          ...theme.muiSelectMenuProps,
                          '.MuiMenu-paper': {
                            maxHeight: `${maxDropdownHeight}px`,
                          },
                        }),
                      }}
                    >
                      {field.options.map(option => (
                        <MenuItem value={option} key={option}>{option}</MenuItem>
                    ))}
                    </Select>
                    {values[field.name] !== 'Other' && (
                      <FormHelperText error>
                        {getErrorMessage(field.name)}
                      </FormHelperText>)
                    }
                  </FormControl>
                  {field.showCustomInput && values[field.name] === 'Other' && (
                    <InputField
                      key={field.name}
                      inputName={`${field.name}-input`}
                      inputValues={values[`${field.name}-input`] || ''}
                      inputPlaceholder={`Please enter your ${field.label.toLowerCase()}...`}
                      handleChange={handleChange}
                      handleFocus={handleFocus}
                      errorMessage={getErrorMessage(field.name)}
                      inputDisabled={disableFields}
                      required={field.required}
                    />
                  )}
                </FieldSet>
              ) : (
                <InputField
                  key={field.name}
                  inputName={field.name}
                  inputType={field.type || 'text'}
                  inputValues={values[field.name]}
                  inputPlaceholder={field.label}
                  handleChange={handleChange}
                  handleFocus={handleFocus}
                  errorMessage={getErrorMessage(field.name)}
                  inputDisabled={disableFields}
                  required={field.required}
                />
              )
            ))}
          </FormFields>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              id="submit-button"
              variant="contained"
              size="large"
              type="submit"
              disabled={disableFields}
            >{staticText.button}
            </Button>
          </Box>
        </form>
      </Wrapper>

    </Content>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  actions: shape({
    updateUserInfo: func.isRequired,
    resetErrors: func.isRequired,
  }).isRequired,
  session: shape({
    userContext: shape({
      email: string.isRequired,
    }).isRequired,
  }).isRequired,
  inProgress: bool,
  updateUserInfoError: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
};

UserInfo.defaultProps = {
  updateUserInfoError: [],
  inProgress: false,
};
