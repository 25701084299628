import React from 'react';
import { Box, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { bool, element, shape, string } from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import InlineLinks from '../InlineLinks';
import DeltaChip from '../DeltaChip';

const Card = styled('div')(({ theme }) => ({
  border: theme.border.default,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  '.title': {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: '1.125rem',
    display: 'flex',

    '& svg': {
      marginLeft: theme.spacing(0.5),
      height: '1.25rem',
      width: '1.25rem',
    },
  },

  '.subtitle': {
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.grey[600],
  },
}));

const WidgetCard = ({
  title, subtitle, tooltip, inlineLink, deltaChipContent,
}) => {
  const theme = useTheme();
  const TooltipIcon = (tooltip && tooltip.Icon) || InfoOutlined;
  return (
    <Card>
      <span className="title">
        {title}
        {tooltip && <Tooltip arrow placement="top" title={tooltip.text}><TooltipIcon /></Tooltip>}
      </span>
      {subtitle && <span className="subtitle">{subtitle}</span>}
      {deltaChipContent && (
        <Box mt={4.5} display="flex" alignItems="center" gap={1.2}>
          <Typography fontFamily={theme.typography.bold.fontFamily} fontSize="1.75rem">
            {deltaChipContent.total}
          </Typography>
          <DeltaChip
            delta={deltaChipContent.delta}
            invertColor={deltaChipContent.invertColors}
            text={deltaChipContent.text}
          />
        </Box>
      )}
      {inlineLink && (
        <Box
          mt={4}
          fontSize={14}
          sx={{
            'a.inline-link': {
              fontFamily: theme.typography.bold.fontFamily,
            },
          }}
        >
          <InlineLinks text={inlineLink} />
        </Box>
      )}
    </Card>
  );
};

export default WidgetCard;

WidgetCard.propTypes = {
  title: string.isRequired,
  subtitle: string,
  deltaChipContent: shape({
    delta: string,
    invertColors: bool,
    text: string,
  }),
  tooltip: shape({
    text: string,
    Icon: element,
  }),
  inlineLink: string,
};

WidgetCard.defaultProps = {
  subtitle: '',
  deltaChipContent: null,
  tooltip: null,
  inlineLink: '',
};
