const staticText = {
  dateButtonGroupConfig: [{
    label: '30d',
    value: 30,
  },
  {
    label: '7d',
    value: 7,
  },
  {
    label: '1d',
    value: 1,
  }],
  apiRequestsOverTimeChart: {
    xAxisLabel: timeInterval => (timeInterval > 1 ? 'Date' : 'Time'),
    yAxisLabel: 'API requests',
    successLabel: 'Success',
    errorLabel: 'Error',
    total: 'Total',
    emptyStateTitle: 'No requests found',
    emptyStateSubtitleWhileOnboarding: 'Make your first API request to view this chart',
    emptyStateSubtitleAfterOnboarding: 'Try adjusting the timeframe to view API request data',
  },
  connectedVehiclesOverTimeChart: {
    xAxisLabel: timeInterval => (timeInterval > 1 ? 'Date' : 'Time'),
    yAxisLabel: 'Connected vehicles',
    emptyStateTitle: 'No vehicles found',
    emptyStateSubtitle: 'Connect a vehicle to view this chart',
  },
  top5MakesByConnectedVehiclesChart: {
    xAxisLabel: 'Connected vehicles',
    yAxisLabel: 'Makes',
    connectedVehiclesLabel: 'Connected vehicles',
    activeVehiclesLabel: 'Active vehicles',
    tooltipTitle: 'Top 5 makes',
    emptyStateTitle: 'No vehicles found',
    emptyStateSubtitle: 'Connect a vehicle to view this chart',
    emptyStateButton: 'Connect a vehicle',
  },
};

export default staticText;
