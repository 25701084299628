import React from 'react';
import { func, shape, string } from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

import { Modal, VerifyEmailModalContent } from '../../../../../../components';

import staticText from '../../../../../../localization/Application/Members/deleteUserModal';
import verifyText from '../../../../../../localization/Application/Billing/verifyEmailModal';

const DeleteUserModal = ({
  deleteOrgMember,
  closeModal,
  user,
  developerEmailVerifiedAt,
}) => {
  const handleDelete = () => {
    deleteOrgMember(user);
    closeModal();
  };

  const content = developerEmailVerifiedAt ? (
    <div>
      <Typography sx={{ mb: 3 }}>{staticText.body(user.name, user.email)}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={closeModal}
          id="cancel-delete"
        >
          {staticText.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleDelete}
          sx={{ ml: 2 }}
          id="confirm-delete"
        >
          {staticText.delete}
        </Button>
      </Box>
    </div>
  ) : (
    <VerifyEmailModalContent openingText={staticText.emailVerificationRequired} />
  );

  return (
    <Modal
      title={developerEmailVerifiedAt ? staticText.title : verifyText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default DeleteUserModal;

DeleteUserModal.propTypes = {
  developerEmailVerifiedAt: string,
  deleteOrgMember: func.isRequired,
  user: shape({
    id: string,
    email: string,
    name: string,
  }).isRequired,
  closeModal: func.isRequired,
};

DeleteUserModal.defaultProps = {
  developerEmailVerifiedAt: '',
};
