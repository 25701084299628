import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ColorBox } from '../styles';

const CustomLegend = ({ legendItems }) => {
  const theme = useTheme();
  return (
    <Box display="flex" gap={3} mt={1} justifyContent="flex-end">
      {legendItems.map((legendItem) => {
      return (
        <Box key={legendItem.label} display="flex" alignItems="center">
          <ColorBox color={legendItem.color} />
          <Typography fontSize={theme.typography.fontSizes.xs}>{legendItem.label}</Typography>
        </Box>
      );
    })}
    </Box>
  );
};

export default CustomLegend;

CustomLegend.propTypes = {
  legendItems: arrayOf(shape({
    label: string.isRequired,
    color: string.isRequired,
  })).isRequired,
};
