import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BrandManagement from './BrandManagement';
import { actions } from '../../reducers';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updatePreviewSettings,
    disableAllBrands,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        updatePreviewSettings,
        disableAllBrands,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandManagement);
