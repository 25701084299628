import { lowerCase } from 'lodash';

export const setFetchingState = (state, key) => ({
  ...state,
  [key]: {
    ...state[key],
    data: [],
    isLoading: true,
    error: '',
  },
});

export const setSuccessState = (state, key, action) => ({
  ...state,
  [key]: {
    ...state[key],
    data: action.payload,
    isLoading: false,
  },
});

export const setFailureState = (state, key, action) => ({
  ...state,
  [key]: {
    ...state[key],
    isLoading: false,
    error: (
      action.payload
      && action.payload.response
      && action.payload.response.data
      && action.payload.response.data.message
    ) || `Something went wrong fetching ${lowerCase(key)}`,
  },
});

