import React from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { ApplicationLabel } from '../../../../../../../../components';

/*
  Regex swaps the current app id for the listed app id in the pathname,
  keeping the rest of the URL
*/
export const buildPathname = (pathname, id) => {
  let path = pathname.replace(/\/apps\/[^/?#]*/, `/apps/${id}`);
  ['simulator', 'vehicles'].forEach((suffix) => {
    if (path.includes(suffix)) {
      path = `${path.split(suffix)[0]}${suffix}`;
    }
  });
  return path;
};

const ApplicationLink = ({
  application,
  pathname,
  handleClick,
  lockedApplications,
  setModalOpen,
}) => {
  const { id, name } = application;
  const lockedApp = lockedApplications.includes(id);
  const path = buildPathname(pathname, id);

  // If user clicks on a locked application, open upsell modal
  const onClick = (e) => {
    if (lockedApp) {
      e.preventDefault();
      if (handleClick) handleClick();
      setModalOpen(true);
    } else {
      reportToSegment(types.TRACK, eventNames.linkClicked, {
        section: 'topbar',
        style: 'dropdown',
        text: name,
        path,
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link to={lockedApp ? '' : path} onClick={onClick} disabled={lockedApp}>
      <ApplicationLabel application={application} locked={lockedApp} />
    </Link>
  );
};

ApplicationLink.propTypes = {
  application: shape({
    id: string.isRequired,
    name: string.isRequired,
    logoUrl: string,
  }).isRequired,
  pathname: string.isRequired,
  handleClick: func,
  setModalOpen: func.isRequired,
  lockedApplications: arrayOf(string).isRequired,
};

ApplicationLink.defaultProps = {
  handleClick: null,
};

export default ApplicationLink;
