import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

export const StyledSelect = styled(Select)(() => ({
  '.MuiSelect-select#role-select': {
    minHeight: '65px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // override and allow word wrap
    whiteSpace: 'normal',
  },
}));

// styles the Menu and Paper components in the RoleSelect dropdown
export const MenuProps = {
  disableScrollLock: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      minWidth: '200px',
    },
  },
};
