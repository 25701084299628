import { IconButton, styled, Typography } from '@mui/material';
import { logoStyles } from '../../../../../../components/ApplicationLabel/ApplicationLabel';

export const UserMenuButton = styled(IconButton)(({ theme }) => ({
  ...logoStyles(theme),

  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette.common.black,
  border: `1px solid ${theme.palette.common.black}`,
  marginRight: 0,

  '&:hover': {
    backgroundColor: theme.palette.common.black,
  },
}));

export const Name = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  marginBottom: theme.spacing(0.5),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const Email = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
