import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../components';
import api from '../../services/api/api';

const PrivateCallback = ({
  organization: {
    id: organizationId,
  },
  location,
  history,
}) => {
  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Extract query parameters
        const urlParams = new URLSearchParams(location.search);

        // Handle different callback types based on query parameters
        const callbackType = urlParams.get('type');

        // Only process specific callback types, otherwise let GoogleOAuth handle it
        switch (callbackType) {
          case 'sso_domain_verification':
            // Handle SSO domain verification
            if (urlParams.get('success') === 'true') {
              const { link } = await api.generateWorkOsAdminPortalUrl(
                organizationId,
                'sso',
                `${window.location.origin}/team/security`,
                `${window.location.origin}/team/security`,
              );
              window.location.href = link;
            } else {
              history.replace('/team/security');
            }
            break;

          default:
            // Default behavior - redirect to home if no recognized callback type
            history.replace('/');
            break;
        }
      } catch (error) {
        console.error('Error handling callback:', error);
        history.replace('/');
      }
    };

    handleCallback();
  }, [location]);

  // Show spinner while processing callback
  return <Spinner size="page" />;
};

export default PrivateCallback;

PrivateCallback.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
