
import { styled } from '@mui/material/styles';

export const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  '& > .MuiTypography-h3': {
    marginBottom: theme.spacing(3),
  },
  minWidth: '305px',
  boxShadow: theme.border.boxShadow2,
  borderRadius: theme.border.radius,
}));

export const CheckboxContainer = styled('div')(() => ({
}));
