import React from 'react';
import { shape, string } from 'prop-types';
import { Box, Icon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CardContent } from '../../styles';

import { usersIcon } from '../../../../assets/icons';

const FunnelStepDescription = ({ event }) => {
  const { percent, displayName, count } = event;
  const theme = useTheme();

  return (
    <CardContent>
      <Typography variant="body1" fontSize={theme.spacing(1.5)} flexGrow={1}>
        {displayName}
      </Typography>
      <Box
        mt="2px"
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        <Icon>
          <img src={usersIcon} alt="people" />
        </Icon>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          marginTop="6px"
        >
          {Number(count).toLocaleString()}
        </Typography>
      </Box>
      <Box sx={{ mt: theme.spacing(1) }}>
        <Typography variant="body1" component="span" fontSize={theme.spacing(1.5)}>
          Conversion:
        </Typography>
        <Typography variant="h4" component="span" fontSize={theme.spacing(1.5)}>
          {` ${Math.round(percent * 100)}%`}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default FunnelStepDescription;

FunnelStepDescription.propTypes = {
  event: shape({
    count: string.isRequired,
    displayName: string.isRequired,
    percent: string.isRequired,
  }).isRequired,
};

