import React from 'react';
import { bool, node, oneOf } from 'prop-types';
import { Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Banner = (props) => {
  const theme = useTheme();
  return (
    <Alert
      severity={props.severity}
      variant={props.variant}
      action={props.action}
      icon={props.showIcon}
      sx={{
        ...(props.largePadding && {
          padding: theme.spacing(3),
        }),
      }}
    >
      {props.children}
    </Alert>
  );
};

export default Banner;

Banner.propTypes = {
  action: node,
  children: node.isRequired,
  largePadding: bool,
  severity: oneOf(['error', 'info', 'success', 'warning']),
  showIcon: bool,
  variant: oneOf(['filled', 'outlined']),
};

Banner.defaultProps = {
  action: null,
  largePadding: false,
  severity: 'info',
  showIcon: true,
  variant: 'outlined',
};
