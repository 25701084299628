import React from 'react';
import { string, func, bool, object } from 'prop-types';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import './Checkbox.scss';

const Checkbox = ({
  inputName,
  text,
  labelText,
  checked,
  handleChange,
  wrapperClassName,
  disabled,
  icon,
  disabledIcon,
  disabledIconText,
  disabledCheckboxText,
  partiallySelected,
}) => {
  const theme = useTheme();

  return (
    <div className={wrapperClassName}>
      <label className="checkbox-label">
        <input
          className={`checkbox ${partiallySelected && 'partiallySelected'}`}
          type="checkbox"
          name={inputName}
          value={text}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="checkbox-custom" />
        <Box display="flex" marginLeft="40px" sx={{ cursor: 'pointer' }}>
          {icon && (
            <Box marginRight={1} marginTop="4px">
              <img src={icon} alt="" />
            </Box>
          )}
          <Typography marginTop="3px" className={`${(disabledIcon || disabledCheckboxText) && 'disabled-text'}`}>
            {labelText || text}
          </Typography>
          {
            disabledIcon &&
            <Tooltip
              className="disabled-tooltip"
              placement="top"
              title={disabledIconText}
            >
              <InfoOutlined
                htmlColor={theme.palette.grey[600]}
              />
            </Tooltip>
          }
        </Box>
      </label>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  text: string.isRequired,
  inputName: string,
  // eslint-disable-next-line react/forbid-prop-types
  labelText: object,
  handleChange: func.isRequired,
  wrapperClassName: string,
  checked: bool,
  disabled: bool,
  icon: string,
  disabledIcon: bool,
  disabledIconText: string,
  disabledCheckboxText: bool,
  partiallySelected: bool,
};

Checkbox.defaultProps = {
  inputName: '',
  labelText: '',
  wrapperClassName: '',
  checked: false,
  disabled: false,
  icon: null,
  disabledIcon: false,
  disabledIconText: '',
  disabledCheckboxText: false,
  partiallySelected: false,
};
