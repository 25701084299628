import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { displayNameFormatter } from '../../utils/textFormatters';
import { InputField, Modal, SelectIcon } from '../../../../../../components';
import ValidateName from '../../../../../../components/ValidateNameModal/components/ValidateName';
import staticText from '../../../../../../localization/Application/Billing/downgradePlanModal';
import billingStaticText from '../../../../../../localization/Application/Billing/billing';
import { gatedFeatureData, getActiveFeatureSetId } from '../../../../../../services/featureGate';
import FEATURES from '../../../../../../services/featureGate/features';

const DowngradePlanModal = (props) => {
  const {
    organization,
    currentPlan,
    isLoading,
    plan,
    toggleModal,
    updateBillingInfo,
    userContext,
  } = props;

  const [downgradeReason, setDowngradeReason] = useState('');
  const [comments, setComments] = useState('');

  const segmentProperties = {
    label: 'billing modal',
    title: staticText.downgradeModalTitle,
  };
  reportToSegment(types.TRACK, eventNames.modalOpened, segmentProperties);

  const toggleModalWithTracking = () => {
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
    toggleModal();
  };

  const handleSubmit = () => {
    updateBillingInfo(plan);
  };

  const segmentEventInfo = {
    reason: downgradeReason,
    comments,
    orgId: organization.id,
    userId: userContext.dashboardUserId,
    previousFeatureSetId: organization.featureSetId,
    previousPlanName: currentPlan,
    newFeatureSetId: getActiveFeatureSetId(plan),
    newPlanName: plan,
  };

  const applicationLimit = gatedFeatureData(FEATURES.APPLICATIONS, getActiveFeatureSetId(plan));

  let businessCTA;
  if (currentPlan === billingStaticText.businessPlanName &&
    plan === billingStaticText.buildPlanName) {
    businessCTA = <div>{staticText.businessToBuildCTA}</div>;
  } else if (currentPlan === billingStaticText.businessPlanName) {
    businessCTA = <div>{staticText.businessToFreeCTA}</div>;
  }

  const description = (
    <div>
      {businessCTA}
      <div className="m-b-sm">
        {staticText.downgradeModalDescription.span1(userContext.email)}
        <b>{displayNameFormatter(plan)}</b>
        {staticText.downgradeModalDescription.span2}
      </div>
      <div>
        <b>{staticText.warning.span1}</b>
        {staticText.warning.span2(applicationLimit)}
      </div>
    </div>
  );

  const additionalFields = (
    <React.Fragment>
      <div>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="downgrade-reason">
            {staticText.downgradeReason.label}
          </InputLabel>
          <Select
            labelId="downgrade-reason"
            label={staticText.downgradeReason.label}
            id="downgrade-reason-input"
            name="downgrade-reason"
            value={downgradeReason}
            onChange={e => setDowngradeReason(e.target.value)}
            IconComponent={SelectIcon}
            MenuProps={{ sx: theme => theme.muiSelectMenuProps }}
          >
            {staticText.downgradeReason.options.map(option => (
              <MenuItem value={option} key={option}>{option}</MenuItem>
          ))}
          </Select>
        </FormControl>
      </div>
      <InputField
        inputName="downgrade-comments"
        inputValues={comments}
        inputPlaceholder={staticText.downgradeReason.comments}
        handleChange={({ value }) => setComments(value)}
      />
    </React.Fragment>
  );

  const content = (
    <div>
      <ValidateName
        description={description}
        isLoading={isLoading}
        nameToMatch={userContext.email}
        nameType={staticText.inputKey}
        submitAction={handleSubmit}
        title={staticText.downgradeModalTitle}
        additionalFields={additionalFields}
        segmentEventInfo={segmentEventInfo}
      />
    </div>
  );

  return (
    <Modal
      title={staticText.downgradeModalTitle}
      content={content}
      toggleModal={toggleModalWithTracking}
    />
  );
};

export default DowngradePlanModal;

DowngradePlanModal.propTypes = {
  organization: shape({
    id: string.isRequired,
    featureSetId: string.isRequired,
  }).isRequired,
  currentPlan: string.isRequired,
  isLoading: bool.isRequired,
  plan: string.isRequired,
  toggleModal: func.isRequired,
  updateBillingInfo: func.isRequired,
  userContext: shape({
    email: string.isRequired,
    dashboardUserId: string.isRequired,
  }).isRequired,
};
