import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Button, Divider, Typography } from '@mui/material';

import { Email, Name, UserMenuButton } from './styles';
import { Menu, MenuItem } from '../../styles';

import { ArrowLink } from '../../../../../../components';
import { userMenu as staticText } from '../../../../../../localization/Application/nav';

const AccountMenu = ({
  userContext: {
    firstName,
    lastName,
    email,
  },
  logoutRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'user menu',
      text: firstName[0],
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = (e, path, text) => {
    handleClose();
    reportToSegment(types.TRACK, eventNames.linkClicked, { text, path });
  };

  const handleCookieSettingsClick = () => {
    if (window.Osano) {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
    handleClose();
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: staticText.cookieSettings,
      text: staticText.cookieSettings,
    });
  };

  const handleLogoutClick = (text) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text,
    });
    logoutRequest(true);
  };

  const userDisplayName = `${firstName} ${lastName}`;

  return (
    <div>
      <UserMenuButton
        id="user-menu-button"
        onClick={handleMenuClick}
        aria-controls={menuOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        open={menuOpen}
      >
        <span>{userDisplayName[0]}</span>
      </UserMenuButton>
      <Menu
        id="user-menu"
        open={menuOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        slotProps={{
          paper: {
            style: {
              width: '240px',
            },
          },
        }}
      >
        <Name component="p" variant="bold">{userDisplayName}</Name>
        <Email component="p" variant="caption">{email}</Email>
        <Divider sx={{ marginBottom: 1 }} />
        <MenuItem>
          <Link
            to={staticText.userProfile.link}
            onClick={e => handleLinkClick(
              e, staticText.userProfile.link, staticText.userProfile.linkText,
            )}
          >
            <img src={staticText.userProfile.iconSrc} alt="" className="icon" />
            <Typography variant="body2">{staticText.userProfile.linkText}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={staticText.security.link}
            onClick={e => handleLinkClick(
              e, staticText.security.link, staticText.security.linkText,
            )}
          >
            <img src={staticText.security.iconSrc} alt="" className="icon" />
            <Typography variant="body2">{staticText.security.linkText}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Button onClick={handleCookieSettingsClick}>
            <img src={staticText.cookieSettings.iconSrc} alt="" className="icon" />
            <Typography variant="body2">{staticText.cookieSettings.buttonText}</Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button onClick={handleLogoutClick}>
            <img src={staticText.logout.iconSrc} alt="" className="icon" />
            <Typography variant="body2">{staticText.logout.buttonText}</Typography>
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ArrowLink
            toggleMenu={handleClose}
            link={staticText.privacy.link}
            text={staticText.privacy.linkText}
            variant="body2"
            icon="launch"
          />
        </MenuItem>
        <MenuItem>
          <ArrowLink
            toggleMenu={handleClose}
            link={staticText.terms.link}
            text={staticText.terms.linkText}
            variant="body2"
            icon="launch"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountMenu;

AccountMenu.propTypes = {
  userContext: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
  }).isRequired,
  logoutRequest: func.isRequired,
};
