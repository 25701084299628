/* istanbul ignore file */

import React from 'react';
import { func, string } from 'prop-types';
import { Button as MuiButton, ButtonGroup as MuiButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import getFilterConfiguration from '../../../../utils/getFilterConfiguration';
import staticText from '../../../../../../../../localization/Application/DeprecatedOverview/dateToggle';

const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const Button = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  fontFamily: theme.typography.fontFamily,
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${theme.palette.grey[200]}`,
  color: theme.palette.grey[600],
  '&:hover, &:focus': {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));

const DateToggle = ({
  refreshChartData,
  interval,
  setFilter,
}) => {
  const handleClick = (e) => {
    if (e.target.dataset.interval !== interval) {
      setFilter(getFilterConfiguration(e.target.dataset.interval), 'timeframe');
      refreshChartData();

      reportToSegment(types.TRACK, eventNames.buttonClicked, {
        label: 'filter',
        text: e.target.textContent,
      });
    }
  };
  return (
    <ButtonGroup color="primary" disableElevation disableRipple disableFocusRipple>
      <Button
        active={interval === 'day'}
        data-interval="day"
        onClick={handleClick}
        id="overview-date-toggle-day"
      >
        {staticText.daily}
      </Button>
      <Button
        active={interval === 'month'}
        data-interval="month"
        onClick={handleClick}
        id="overview-date-toggle-month"
      >
        {staticText.monthly}
      </Button>
    </ButtonGroup>
  );
};

export default DateToggle;

DateToggle.propTypes = {
  refreshChartData: func.isRequired,
  interval: string.isRequired,
  setFilter: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};
