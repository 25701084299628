import { connect } from 'react-redux';

import PrivateCallback from './PrivateCallback';
import { selectors as orgSelectors } from '../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    organization: orgSelectors.getCurrentOrganization(state),
  };
}


export default connect(mapStateToProps)(PrivateCallback);
