import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DeleteSSOConnectionModal from './DeleteSSOConnectionModal';
import { selectors as orgSelectors } from '../../../../../../../../services/organizations/reducers';
import { actions as authActions } from '../../../../../../../../services/auth/reducers';

function mapStateToProps(state) {
  return {
    ...state.auth,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    deleteWorkOsConnectionRequest,
  } = authActions;


  return {
    actions: bindActionCreators(
      {
        deleteWorkOsConnectionRequest,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSSOConnectionModal);
