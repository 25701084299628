import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Box, Typography } from '@mui/material';

import staticText from '../../../../../../../../localization/Application/OrgSecurity/deleteSSOConnectionModal';
import { Modal } from '../../../../../../../../components';
import ValidateName from '../../../../../../../../components/ValidateNameModal/components/ValidateName';

const DeleteSSOConnectionModal = ({
  organization,
  isDeletingWorkOsConnection,
  deleteWorkOsConnectionError,
  actions: {
    deleteWorkOsConnectionRequest,
  },
  toggleModal,
}) => {
  const segmentProperties = {
    label: 'Delete SSO connection',
    title: staticText.modalTitle,
  };

  useEffect(() => {
    reportToSegment(types.TRACK, eventNames.modalOpened, segmentProperties);
  }, []);

  const toggleModalWithTracking = () => {
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
    toggleModal();
  };

  const handleSubmit = () => {
    deleteWorkOsConnectionRequest(organization.id);
  };

  const description = (
    <div>
      {staticText.description}
    </div>
  );

  const content = (
    <Box display="flex" flexDirection="column">
      <ValidateName
        description={description}
        isLoading={isDeletingWorkOsConnection}
        nameToMatch={organization.name}
        nameType="organization"
        toggleModal={toggleModalWithTracking}
        submitAction={handleSubmit}
        title={staticText.modalTitle}
      />
      {deleteWorkOsConnectionError &&
        <Typography variant="caption" color="error" mt={2} alignSelf="end">
          {deleteWorkOsConnectionError}
        </Typography>
      }
    </Box>
  );

  return (
    <Modal
      title={staticText.modalTitle}
      content={content}
      toggleModal={toggleModalWithTracking}
    />
  );
};

export default DeleteSSOConnectionModal;

DeleteSSOConnectionModal.propTypes = {
  actions: shape({
    deleteWorkOsConnectionRequest: func.isRequired,
  }).isRequired,
  organization: shape({
    id: string.isRequired,
    name: string.isRequired,
    featureSetId: string.isRequired,
  }).isRequired,
  isDeletingWorkOsConnection: bool.isRequired,
  deleteWorkOsConnectionError: string,
  toggleModal: func.isRequired,
};

DeleteSSOConnectionModal.defaultProps = {
  deleteWorkOsConnectionError: '',
};

