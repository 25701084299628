import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Select as MuiSelect,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Select = styled(MuiSelect, {
  shouldForwardProp: prop => prop !== 'autoWidth',
})(({ theme, autoWidth }) => ({
  width: '236px',
  marginRight: theme.spacing(1),

  '> .MuiSelect-outlined': {
    paddingRight: `${theme.spacing(4.5)} !important`,
  },

  ...(autoWidth && { width: 'auto', maxWidth: '305px' }),
}));

export const AlignmentFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

export const AlignmentFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontFamily: theme.typography.bold.monospace,
  textTransform: 'uppercase',
  paddingBottom: '0px',

  '&.Mui-focused': {
    color: theme.palette.grey[600],
  },
}));

export const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: '0px',
  marginRight: theme.spacing(1),
}));

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  border: 'none',

  td: {
    padding: theme.spacing(1.5, 3),
  },
}));


export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: `${theme.spacing(1)} !important`,
}));

export const Chip = styled(Typography, {
  shouldForwardProp: prop => prop !== 'method',
})(({ theme, method }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: theme.spacing(1.5),
  color: theme.palette.common.white,
  borderRadius: theme.spacing(0.5),
  width: theme.spacing(6),
  paddingTop: '4px',
  paddingBottom: '2px',
  textAlign: 'center',
  marginTop: theme.spacing(1),

  ...(method === 'GET' ? {
    backgroundColor: '#69C572',
  } : {
    backgroundColor: '#2F80ED',
  }),

  '&:first-of-type': {
    marginTop: '0px',
  },
}));

export const EndpointRow = styled(TableRow)(() => ({
  verticalAlign: 'top',
}));

export const EndpointItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  ':nth-of-type(2)': {
    marginTop: theme.spacing(1),
  },
}));

export const LatencyContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ theme, disabled }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  ...(disabled && {
    color: theme.palette.text.disabled,
  }),
}));

export const EmptyStateImage = styled('img')(({ theme }) => ({
  margin: `${theme.spacing(20)} auto`,
  height: '180px',
  opacity: '50%',
  display: 'block',
}));
