import React, { useEffect } from 'react';
import { arrayOf, func, objectOf, shape, string } from 'prop-types';
import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';
import ApplicationLink from '../TopbarNav/components/AppSelector/components/ApplicationLink';

const staticText = {
  error: {
    title: 'Application not found',
    description:
      'Oh no! We couldn’t find the application you were looking for. Please choose from your existing applications below.',
    lockedApp: {
      title: 'Application locked',
      description: 'The application you are looking for has been locked. To regain access, please click on the locked application below and upgrade your account. Or choose from your available applications.',
    },
  },
};

const ApplicationNotFound = ({
  applications,
  lockedApplications,
  toggleModal,
  pathname,
}) => {
  const theme = useTheme();
  const attemptedAppId = pathname.split('/')[2];
  const lockedApp = Boolean(lockedApplications.includes(attemptedAppId));

  useEffect(() => {
    reportToSegment(types.PAGE, lockedApp ? 'Application Locked' : 'Application Not Found');
  }, []);

  return (
    <Box width="800px" margin="100px auto">
      <ApplicationHeader
        heading={lockedApp ? staticText.error.lockedApp.title : staticText.error.title}
      />
      <Section>
        <Typography mb={2} mt={2}>
          {lockedApp ? staticText.error.lockedApp.description : staticText.error.description}
        </Typography>
        <List>
          {Object.keys(applications).map((appId) => {
            const app = applications[appId];
            return (
              <ListItem
                key={`app-${app.id}`}
                sx={{
                  padding: theme.spacing(1),
                  borderRadius: theme.border.radius2,
                  '&:hover': {
                    backgroundColor: theme.palette.grey[200],
                  },
                  '&.MuiListItem-root:first-child, &.MuiListItem-root:last-child': {
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                  },
                  a: {
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                    width: '100%',
                  },
                }}
              >
                <ApplicationLink
                  key={`app-${app.id}`}
                  application={app}
                  pathname={pathname}
                  setModalOpen={toggleModal}
                />
              </ListItem>
            );
          })}
        </List>
      </Section>
    </Box>
  );
};

export default ApplicationNotFound;

ApplicationNotFound.propTypes = {
  applications: objectOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  lockedApplications: arrayOf(string),
  toggleModal: func.isRequired,
  pathname: string.isRequired,
};

ApplicationNotFound.defaultProps = {
  lockedApplications: [],
};
