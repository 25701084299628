import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VehicleWebhooks from './VehicleWebhooks';
import { actions } from '../../reducers';
import { selectors as orgSelectors } from '../../../../../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  return {
    applicationId: state.applications.selectedApplication,
    webhooksInfo: state.vehicleDetails.webhooksInfo,
    isFetchingWebhooksInfo: state.vehicleDetails.isFetchingWebhooksInfo,
    errors: {
      webhooks: state.vehicleDetails.vehicleLogsErrors.webhooks,
    },
    webhookEvents: state.vehicleDetails.webhookEvents,
    featureSetId: currentOrganization.featureSetId,
    rolePermissions: currentOrganization.rolePermissions,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchWebhooksInfoRequest,
    fetchWebhookEventsRequest,
    unsubscribeVehicleFromWebhookRequest,
    updateWebhookEventsFilterValues,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchWebhooksInfoRequest,
        fetchWebhookEventsRequest,
        unsubscribeVehicleFromWebhookRequest,
        updateWebhookEventsFilterValues,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleWebhooks);
