import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as connectSelectors, actions, types } from './reducers';
import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import api from '../../../../services/api/api';
import { Toast } from '../../../../components';
import alphabetizeMakes from './utils/alphabetizeMakes';
import staticText from '../../../../localization/Application/connect-config';
import { supportedCountries } from '../../../../../config/countries';
import removeEndpointsFromCompatibilityMatrix from './utils/removeEndpointsFromCompatibilityMatrix';

export function* fetchSupportedCountries() {
  try {
    // temporarily using a hardcode list of supported countries instead of the list from api
    // const { data } = yield call(api.fetchSupportedCountries);

    yield put(actions.fetchSupportedCountriesSuccess(supportedCountries));
  } catch (error) {
    yield put(actions.fetchSupportedCountriesFailure(error));
  }
}

export function* fetchConnectConfig() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchConnectConfig,
      applicationId,
    );

    // Filter out endpoints since the endpoints filter is deprecated
    if (data !== null) {
      data.brandManagementFilters =
      [...new Set(data.brandManagementFilters.flat().filter(item => ['PHEV', 'BEV', 'ICE', 'HEV'].includes(item)))];
    }

    yield put(actions.fetchConnectConfigSuccess(data));
  } catch (error) {
    yield put(actions.fetchConnectConfigFailure(error));
  }
}

export function* fetchMakesByCountryRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { payload: country } = action;

    const { data } = yield call(
      api.fetchAllowedMakesByCountry,
      applicationId,
      country,
    );

    yield put(actions.fetchMakesByCountrySuccess(data));
  } catch (error) {
    yield put(actions.fetchMakesByCountryFailure(error));
  }
}

export function* fetchApplicationMakes() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchApplicationMakes,
      applicationId,
    );

    data.sort(alphabetizeMakes);

    yield put(actions.fetchApplicationMakesSuccess(data));
  } catch (error) {
    yield put(actions.fetchApplicationMakesFailure(error));
  }
}

export function* updateOrCreateConnectConfig(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { payload } = action;

    const { data } = yield call(
      api.updateOrCreateConnectConfig,
      applicationId,
      payload,
    );

    yield put(actions.updateConnectConfigSuccess(data));
    yield call(Toast, staticText.successMessage, 'success');
  } catch (error) {
    yield put(actions.updateConnectConfigFailure(error));
  }
}

export function* updateAppLogo(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { payload } = action;

    const { data } = yield call(
      api.updateAppLogo,
      applicationId,
      payload.data,
    );

    // Delete previous logo from S3 at time of subsequent upload if it was not
    // saved to the app config. This prevents us from deleting the originally saved logo.
    const connectConfig = yield select(connectSelectors.getConnectConfig) || {};
    const previousLogoUrl = yield select(connectSelectors.getUploadedLogoUrl) || '';
    if (
      previousLogoUrl &&
      connectConfig.logoUrl &&
      previousLogoUrl !== connectConfig.logoUrl
    ) {
      yield call(api.deleteAppLogo, applicationId, previousLogoUrl);
    }

    yield put(actions.updateAppLogoSuccess(data));
  } catch (error) {
    yield put(actions.updateAppLogoFailure(error));
  }
}

export function* deleteAppLogo(action) {
  const applicationId = yield select(applicationSelectors.getSelectedApplication);
  const { payload } = action;
  yield call(api.deleteAppLogo, applicationId, payload);
}

export function* fetchBrandManagementCapabilitiesRequest() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    let { data } = yield call(api.fetchBrandManagementCapabilities, applicationId);

    data = removeEndpointsFromCompatibilityMatrix(data);
    yield put(actions.fetchBrandManagementCapabilitiesSuccess(data.brandManagementCapabilities));
  } catch (error) {
    yield put(actions.fetchBrandManagementCapabilitiesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_SUPPORTED_COUNTRIES_REQUEST, fetchSupportedCountries);
  yield takeLatest(types.FETCH_CONNECT_CONFIG_REQUEST, fetchConnectConfig);
  yield takeLatest(types.UPDATE_CONNECT_CONFIG_REQUEST, updateOrCreateConnectConfig);
  yield takeLatest(types.UPDATE_APP_LOGO_REQUEST, updateAppLogo);
  yield takeLatest(types.DELETE_APP_LOGO, deleteAppLogo);
  yield takeLatest(types.FETCH_MAKES_BY_COUNTRY_REQUEST, fetchMakesByCountryRequest);
  yield takeLatest(types.FETCH_APPLICATION_MAKES_REQUEST, fetchApplicationMakes);
  yield takeLatest(
    types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_REQUEST, fetchBrandManagementCapabilitiesRequest,
  );
}
