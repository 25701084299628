import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  border: theme.border.default,
  padding: theme.spacing(8),
  marginBottom: theme.spacing(4),
  minHeight: '200px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  'a#create-link': {
    display: 'inline-block',
    marginTop: theme.spacing(3),
    padding: '13px 64px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.bold.fontFamily,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.border.radius,
    textDecoration: 'none',
    transition: '.25s',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const DetailsList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(3.75),

  '> li': {
    marginBottom: theme.spacing(0.5),
  },
}));
