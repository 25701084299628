import { styled } from '@mui/material/styles';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button as MuiButton, Link as MuiLink, Typography } from '@mui/material';

export const Container = styled('div')(({ theme, outlined, height }) => ({
  height: height || 'calc(100vh - 332px)',
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.border.radius,
  border: outlined ? theme.border.default : 'none',
}));

export const Image = styled('img')(({ theme }) => ({
  width: '288px',
  marginBottom: theme.spacing(6),
}));

export const Text = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(8),
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: '1.125rem',
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 10),
  fontSize: '1.125rem',
  marginBottom: theme.spacing(2),
}));

export const Link = styled(MuiLink)(({ theme }) => ({
  padding: theme.spacing(1.5),
  minWidth: '250px',
  textAlign: 'center',
  fontSize: '1.125rem',
  fontFamily: theme.typography.bold.fontFamily,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.border.radius,
  marginBottom: theme.spacing(2),

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const RedirectLink = styled(ReactRouterLink)(({ theme }) => ({
  padding: theme.spacing(1.5),
  minWidth: '250px',
  textAlign: 'center',
  fontSize: '1.125rem',
  fontFamily: theme.typography.bold.fontFamily,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.border.radius,
  marginBottom: theme.spacing(2),
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.secondary.main,
  },
}));
