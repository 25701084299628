import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

import ConnectInsights from './ConnectInsights';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  return {
    applicationId: state.applications.selectedApplication,
    featureSetId: currentOrganization.featureSetId,
    rolePermissions: currentOrganization.rolePermissions,
    ...state.connectInsights,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchConnectEventsRequest,
    fetchConnectFunnelRequest,
    updateFilterValues,
  } = actions;
  return {
    actions: bindActionCreators(
      {
        fetchConnectEventsRequest,
        fetchConnectFunnelRequest,
        updateFilterValues,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectInsights);
