import _ from 'lodash';
import dateUtils from '../../../../../utils/dateUtils';

// GLOBAL DEFAULTS
const axesFontAndColor = {
  font: {
    family: "'ibm-plex-sans-medium', 'Arial Narrow', sans-serif",
  },
  color: '#282828',
};
const layout = {
  padding: {
    left: 18,
    right: 34,
  },
};
const yAxis = {
  type: 'linear',
  display: true,
  position: 'left',
  min: 0,
  suggestedMax: 3,
  ticks: {
    maxTicksLimit: 8,
    padding: 8,
    precision: 0,
  },
};
const xAxisTitleStyling = {
  display: true,
  padding: {
    top: 16,
  },
  ...axesFontAndColor,
};
const yAxisTitleStyling = {
  display: true,
  padding: {
    right: 16,
  },
  ...axesFontAndColor,
};
const getTooltip = labelFn => ({
  displayColors: false,
  callbacks: {
    label: labelFn,
    title: () => null,
  },
  padding: 16,
  xAlign: 'center',
  yAlign: 'bottom',
});
const lineChartDataOptions = {
  fill: false,
  backgroundColor: '#00819D', // data point color
  borderColor: '#00819D', // line color
  tension: 0.3,
};
const getGradient = (canvas) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 350);
  gradient.addColorStop(1, 'rgba(48, 170, 197, .5)');
  gradient.addColorStop(0, 'rgba(48, 170, 197, 1)');

  return gradient;
};
const timeFormatter = {
  month: 'MMM',
  day: 'D',
};
const getCurrentDate = () => new Date().getDate();
const labelGenerator = (otherData) => {
  return (context) => {
    if (context.label === 'OTHER' && otherData) {
      const tooltipArray = ['Others'];
      Object.keys(otherData).forEach((label) => {
        tooltipArray.push(`${label}: ${otherData[label]}`);
      });
      return tooltipArray;
    }
    return `${context.formattedValue}`;
  };
};

// API REQUESTS/ACTIVE VEHICLES CHARTS
export const getUsageChartData = (type, label, metrics) => {
  return (canvas) => {
    let options;
    switch (type) {
      case 'BAR':
        options = {
          backgroundColor: getGradient(canvas),
        };
        break;
      default:
      case 'LINE':
        options = lineChartDataOptions;
    }

    let chartLabels = [];
    let chartData = [];
    if (
      !metrics ||
      !metrics.data ||
      !Array.isArray(metrics.data) ||
      metrics.data.length === 0
    ) {
      chartData = Array(getCurrentDate()).fill(0);
      // eslint-disable-next-line no-shadow
      chartLabels = Array.from(Array(getCurrentDate()), (_, i) => i + 1);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const element of metrics.data) {
        chartLabels.push(
          _.upperCase(
            dateUtils.timestampToDate(
              element.timestamp,
              timeFormatter[metrics.timeframe.interval],
            ),
          ),
        );
        chartData.push(element.value);
      }
    }

    return {
      labels: chartLabels,
      datasets: [
        {
          label,
          // fake data (in the format [0, 0, 0]) to create animation while rendering the real data
          data: chartData,
          ...options,
        },
      ],
    };
  };
};

export const getDefaultUsageOptions = (timeframe, yLabel) => ({
  layout,
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        ...xAxisTitleStyling,
        text: timeframe ? _.startCase(timeframe.interval) : 'Day',
      },
    },
    y: {
      ...yAxis,
      title: {
        ...yAxisTitleStyling,
        text: `Number of ${yLabel}`,
      },
      grid: {
        display: true,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: getTooltip((context) => {
      return `${context.formattedValue} ${context.dataset.label}`;
    }),
  },
});

// RESPONSE STATUS SUMMARY DOUGHNUT
export const getResponseStatusData = (responses) => {
  return {
    labels: responses.labels || [''],
    datasets: [
      {
        data: responses.data || [1],
        backgroundColor: [
          '#49CBB4',
          '#F9D148',
          '#529EDF',
          '#DD3C3C',
          '#9060DF',
          '#F56CAE',
          '#B1B1B1',
        ],
        radius: '120',
      },
    ],
  };
};

export const getResponseStatusOptions = (
  otherResponseStatusData,
  updateStatusCodesAndMakesFromOverviewChart,
) => {
  return {
    onClick: (e, activeElements, chart) => {
      const chartLabel = chart.data.labels[activeElements[0].index];
      // *is needed for the following PR (deep linking charts)
      // eslint-disable-next-line no-unused-vars
      const selectedStatuses = chartLabel === 'OTHER'
        ? Object.keys(otherResponseStatusData).map(label => label.slice(0, 3))
        : [chartLabel.slice(0, 3)];

      updateStatusCodesAndMakesFromOverviewChart({
        statusCodes: selectedStatuses,
        makes: null,
        // only should be set to true in getDefaultMakesOptions function below
        keepSelectedChartMakes: false,
      });
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: getTooltip(labelGenerator(otherResponseStatusData)),
    },
  };
};

// MAKES BAR CHART
export const getMakesData = (makes) => {
  return {
    labels: makes.labels,
    datasets: [
      {
        label: 'makes',
        data: makes.data,
        backgroundColor: [
          '#49CBB4',
          '#F9D148',
          '#529EDF',
          '#DD3C3C',
          '#9060DF',
          '#F56CAE',
          '#FFB784',
          '#27AE60',
          '#F49865',
          '#B1B1B1',
        ],
      },
    ],
  };
};

export const getDefaultMakesOptions = (
  otherMakesData,
  updateStatusCodesAndMakesFromOverviewChart,
) => {
  return ({
    layout,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          ...xAxisTitleStyling,
          text: 'Make',
        },
      },
      y: {
        ...yAxis,
        title: {
          ...yAxisTitleStyling,
          text: 'Number of vehicles',
        },
        grid: {
          display: true,
          drawBorder: false,
        },
      },
    },
    onClick: (e, activeElements, chart) => {
      const makeLabel = chart.data.labels[activeElements[0].index];
      const selectedMakes = makeLabel === 'OTHER' ? Object.keys(otherMakesData) : [makeLabel];

      updateStatusCodesAndMakesFromOverviewChart({
        statusCodes: null,
        makes: selectedMakes,
        keepSelectedChartMakes: true,
      });
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: getTooltip(labelGenerator(otherMakesData)),
    },
  });
};
