const staticText = {
  heading: 'Webhooks',
  addWebhookButton: 'Add webhook',
  buttonText: 'Create your first webhook',
  emptyState: {
    heading: 'Manage automations & notifications across your application',
    details: [
      {
        key: 'event-based',
        text: 'Listen for events as they happen to a vehicle',
      },
      {
        key: 'schedule-events',
        text: 'Receive vehicle data on a cadence of your choosing',
      },
      {
        key: 'automate',
        text: 'Automate based on events or a schedule',
      },
    ],
  },
  resourceCards: [
    {
      title: 'Webhooks Documentation',
      text: 'Learn how to register, verify, disable, and test webhooks.',
      link: 'https://smartcar.com/docs/tutorials/webhooks-scheduled',
      linkText: 'See the guides',
    },
    {
      title: 'Webhooks API Reference',
      text:
        'See which endpoints to use when registering and deleting webhooks.',
      link: 'https://smartcar.com/docs/api-reference/webhooks/callback-verification',
      linkText: 'See the endpoints',
    },
  ],
  featureGated: {
    description: 'Upgrade to add webhooks & more',
    contactOwnersToUpgrade: 'Contact a team owner to upgrade to add webhooks & more',
    contactOwnersToRegainAccess: 'Contact a team owner to upgrade to regain access to your webhooks',
    buttonText: 'Upgrade to use this webhook',
    regainAccessDescription: '|+|Upgrade your plan@@/team/billing|+| to regain access to your webhooks',
  },
  errorConfig: {
    title: 'Unable to add new webhook',
    errorMessage: 'Your application can have a maximum of ten webhooks associated with it. Please delete one of your webhooks in order to add a new one.',
    buttonText: 'OK',
  },
  webhooksTable: {
    headers: {
      status: 'Status',
      name: 'Webhook',
      actions: 'Actions',
    },
  },
};

export const messages = {
  webhookDeleted: 'Webhook deleted successfully!',
  webhookUpdated: 'Webhook updated successfully!',
};

export const errors = {
  webhookDeletion: 'Failed to delete webhook',
  webhookUpdate: 'Failed to update webhook',
};

export default staticText;
